import React from 'react'

import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FullScreenSpinner = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default FullScreenSpinner
