import { EmployeeRole } from '@dentalux/common'

export const LABEL_MARGIN = 51

export const COLORS = {
  primary: '#24A1A1',
  background: '#FAFCFF',
  font: '#232F34',
  labelFont: '#505762',
  headerFont: '#4D4D4D',
}

export const AUTOCOMPLETE_DEFAULT_PROPS = {
  size: 'small' as const,
  blurOnSelect: 'touch' as const,
  fullWidth: true,
  handleHomeEndKeys: true,
  selectOnFocus: true,
  disableClearable: true,
  clearOnBlur: true,
  autoHighlight: true,
}

export const MULTIPLE_ROLES_BUNDLE = [EmployeeRole.DENTIST, EmployeeRole.HYGIENIST]
