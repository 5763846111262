import { colors } from '@dentalux/ui-library-core'

import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'

const focusShadow = `0px 0px 0px 2px ${colors.grey['0']}, 0px 1px 2px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 3px rgba(0, 0, 0, 0.15);`

const RedesignedSelect = withStyles((theme) => ({
  root: {
    '&&': {
      color: colors.aqua[800],
      border: `1px solid ${colors.aqua[800]}`,
      backgroundColor: colors.grey[0],
      borderRadius: '100px',
      padding: '6px 28px 6px 16px',
      textTransform: 'uppercase',
      minHeight: 'auto',
      '&:focus': {
        backgroundColor: colors.grey[0],
        borderRadius: '100px',
        border: `1px solid ${colors.aqua[800]}`,
      },
      '&:hover': {
        backgroundColor: '#EBF2F1',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.3), 0px 0px 2px rgba(0, 0, 0, 0.15);',
      },
      '&:focus-visible': {
        backgroundColor: '#E0ECEA',
        boxShadow: focusShadow,
      },
      '&:active': {
        boxShadow: 'none',
      },
      '& .MuiInput-root:before, & .MuiInput-root:after': {
        display: 'none',
      },
      '& .MuiTypography-root': {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '12px',
      },
    },
  },
}))(Select)

export default RedesignedSelect
