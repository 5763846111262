import { useMemo } from 'react'

import { PaginatedResponse } from '@dentalux/common'

import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { PatientDetails } from '../@types/PatientDetailsResult'
import { PatientsParams } from '../@types/PatientsParams'
import { MIN_STRING_LENGTH_REQUIREMENT } from '../components/AppBar/components/Search/Search.helpers'
import withQueryParams from '../helpers/withQueryParams'
import api from '../services/api'

const usePatients = (params: PatientsParams, searchLength = MIN_STRING_LENGTH_REQUIREMENT, disabled = false) => {
  const service = api.patient.getPatient
  const patientParams = useMemo(
    () => ({
      page: 0,
      pageSize: 10,
      ...params,
    }),
    [params]
  )

  const enabled = patientParams.query?.length >= searchLength && !disabled

  const patients = useQuery<PaginatedResponse<PatientDetails>, AxiosError>({
    enabled,
    queryFn: withQueryParams(service),
    queryKey: ['patients-search', patientParams],
    keepPreviousData: true,
  })

  return patients
}

export default usePatients
