import { differenceInMinutes, getTime } from 'date-fns'

import { CallTask, CallTaskStates } from '../../../@types/CallTask'

const prioritizeCalls = (input: CallTask[]): CallTask[] => {
  const priorityMap = {
    [CallTaskStates.Open_Ringing]: 5,
    [CallTaskStates.Open_InProgress]: 4,
    [CallTaskStates.Open]: 3,
    [CallTaskStates.Open_UnsuccessfulCallback]: 2,
    [CallTaskStates.Done]: 1,
    [CallTaskStates.Done_AvailyBooking]: 1,
    [CallTaskStates.Ignored]: 0,
  }

  const prioritizedCallTasks: CallTask[] = input.map((callTask: CallTask) => ({
    ...callTask,
    priority: priorityMap[callTask.currentState],
  }))

  const sortCalls = [...prioritizedCallTasks]
    // Sort calls by `latestIncomingCallAt` param from newest to oldest
    .sort((x: CallTask, y: CallTask) => {
      const currentDate = new Date(x.latestIncomingCallAt)
      const prevDate = new Date(y.latestIncomingCallAt)

      return getTime(prevDate) - getTime(currentDate)
    })
    // Sort calls from oldest to newest if lastes call is less than MINUTES
    .sort((x: CallTask, y: CallTask) => {
      const MINUTES = 15

      const currentDate = new Date(x.latestIncomingCallAt)
      const prevDate = new Date(y.latestIncomingCallAt)
      const currentTime = getTime(currentDate)
      const prevTime = getTime(prevDate)
      const isNew = differenceInMinutes(new Date(), currentDate) < MINUTES

      return isNew ? currentTime - prevTime : prevTime - currentTime
    })
    // Sort by calls priority
    .sort((x: CallTask, y: CallTask) => {
      if (y.priority && x.priority) return y.priority - x.priority
      return 0
    })

  return sortCalls
}

export default prioritizeCalls
