import React from 'react'

import { PatientDetails } from '../../@types/PatientDetailsResult'

import MinimizedPatient from './components/MinimizedPatient'
import useStyles from './MinimizedPatients.styles'

type Props = {
  patients: PatientDetails[]
  onMaximize: (patientReferenceId: string) => void
  onClose: (patientReferenceId: string) => void
}

const MinimizedPatients: React.FC<Props> = ({ patients, onMaximize, onClose }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {patients.map((patient: PatientDetails) => (
        <MinimizedPatient key={patient.referenceId} patient={patient} onMaximize={onMaximize} onClose={onClose} />
      ))}
    </div>
  )
}

export default MinimizedPatients
