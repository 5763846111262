import { PropsWithChildren } from 'react'

import {
  createTheme,
  MuiButton,
  MuiCheckbox,
  MuiDialog,
  MuiFilledInput,
  MuiSwitch,
  MuiTabs,
  MuiIconButton,
  palette,
} from '@dentalux/ui-library-core'

import { ThemeProvider } from '@mui/material'

export const uiLibraryTheme = createTheme({
  palette,
  components: {
    MuiButton,
    MuiCheckbox,
    MuiSwitch,
    MuiTabs,
    MuiDialog,
    MuiFilledInput,
    MuiIconButton,
  },
})

export const UiLibraryThemeProvider = ({ children }: PropsWithChildren) => (
  <ThemeProvider theme={uiLibraryTheme}>{children}</ThemeProvider>
)
