import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'calc(100% - 16px)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    boxSizing: 'border-box',
  },
}))
