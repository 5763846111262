import React, { useMemo } from 'react'

import { Employee, EmployeeLevel, EmployeeRole } from '@dentalux/common'
import { useCredentials } from '@dentalux/security'

import { useQuery, QueryObserverResult, QueryObserverLoadingResult } from '@tanstack/react-query'

import { ClinicType } from '../@types/Clinic'
import { CalmasterPractitioner, EmployeeStatus } from '../@types/Practitioner'
import parseArrayQueryParam from '../helpers/parseArrayQueryParam'
import { resizePractitionerAvatars } from '../helpers/practitioners'
import { sortPractitionersByName } from '../helpers/sortPractitionersByName'
import withQueryParams from '../helpers/withQueryParams'
import useRenderForCurrentClinic from '../hooks/useRenderForCurrentClinic'
import api from '../services/api'

import { useCurrentClinicProvider } from './CurrentClinicProvider'

type Props = {
  children: React.ReactNode
}

const initialState = {
  status: 'loading',
  data: [],
  error: null,
  isFetching: true,
  failureCount: 0,
  isStale: false,
} as unknown as QueryObserverLoadingResult<CalmasterPractitioner[]> & {
  data: Employee[]
}

export const Context = React.createContext<
  Omit<QueryObserverResult<CalmasterPractitioner[], any>, 'data'> & {
    data: Employee[]
  }
>(initialState)

const PractitionersProvider = ({ children }: Props) => {
  const { authentication } = useCredentials()
  const { selectedClinic } = useCurrentClinicProvider()
  const { canRender } = useRenderForCurrentClinic([ClinicType.DENTIST])

  const query = useMemo(
    () => ({
      clinicReferenceId: selectedClinic?.referenceId,
      status: parseArrayQueryParam([
        EmployeeStatus.ACTIVE,
        EmployeeStatus.WILL_BE_ACTIVE,
        EmployeeStatus.DISABLED,
        EmployeeStatus.INACTIVE,
        EmployeeStatus.USED_TO_BE_ACTIVE,
      ]),
    }),
    [selectedClinic?.referenceId]
  )

  const result: QueryObserverResult<CalmasterPractitioner[]> = useQuery({
    queryKey: ['practitioners', query],
    queryFn: withQueryParams<CalmasterPractitioner[]>(api.calmaster.getPractitioners),
    enabled: Boolean(authentication && selectedClinic?.referenceId),
  })

  const resultWithResizedAvatars: QueryObserverResult<CalmasterPractitioner[]> = useMemo(() => {
    if (!result.data) {
      return { ...result, data: undefined }
    }

    const practitioners = resizePractitionerAvatars(result.data)

    return { ...result, data: practitioners }
  }, [result])

  const rolesToShow = useMemo(
    () => (canRender ? [EmployeeRole.DENTIST, EmployeeRole.HYGIENIST] : [EmployeeRole.DOCTOR]),
    [canRender]
  )

  const employees: Array<Employee> = useMemo(
    () =>
      (resultWithResizedAvatars.data ?? [])
        .map((data) => ({
          ...data,
          displayName: data.userName,
          positions: data.practitionerPositions
            ?.filter(({ role }) => rolesToShow.includes(role as unknown as EmployeeRole))
            ?.map(({ level, role }) => ({
              role: role as unknown as EmployeeRole,
              level: level as unknown as EmployeeLevel,
            })),
        }))
        .filter(({ positions }) => positions?.some(({ role }) => rolesToShow.includes(role)))
        .sort(sortPractitionersByName),
    [resultWithResizedAvatars.data]
  )

  const value = useMemo(
    () => ({
      ...result,
      data: employees,
    }),
    [employees, result]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const usePractitionersContext = () => {
  const context = React.useContext(Context)

  if (context === undefined) {
    throw new Error('usePractitionersContext must be used within a PractitionersProvider')
  }

  return context
}

export default PractitionersProvider
