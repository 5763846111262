import moment from 'moment'

const MIN_STRING_LENGTH_REQUIREMENT = 4
const MIN_NUMBER_LENGTH_REQUIREMENT = 1

const isValueNumber = (value: string) => value !== '' && !Number.isNaN(Number(value))

const getStringLengthRequirement = (value = ''): number => {
  return isValueNumber(value) ? MIN_NUMBER_LENGTH_REQUIREMENT : MIN_STRING_LENGTH_REQUIREMENT
}

const doesSearchWordMeetLengthRequirement = (value: string) => value.length >= getStringLengthRequirement(value)

const formatBirthday = (birthday?: string) =>
  birthday && moment(birthday).isValid() ? moment(birthday).format('DD.MM.YYYY') : ''

export {
  doesSearchWordMeetLengthRequirement,
  getStringLengthRequirement,
  MIN_STRING_LENGTH_REQUIREMENT,
  MIN_NUMBER_LENGTH_REQUIREMENT,
  formatBirthday,
}
