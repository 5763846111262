export enum CalmasterAppointmentsSortField {
  startTime = 'startTime',
  endTime = 'endTime',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface AppointmentsParams {
  page?: number
  size?: number
  sortBy?: CalmasterAppointmentsSortField
  sortDirection?: SortDirection
  showCancelled?: boolean
}

export interface FilterParams {
  startAfter?: string
  startBefore?: string
  endAfter?: string
  endBefore?: string
  clinicReferenceIds?: string[]
  practitionerReferenceIds?: string[]
}
