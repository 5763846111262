import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[800],
    fontSize: 12,
    lineHeight: 1.3,
  },
  arrow: {
    color: theme.palette.grey[800],
  },
}))(Tooltip)

export default StyledTooltip
