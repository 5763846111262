import { PropsWithChildren, Suspense } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { IconButton, Paper, Typography } from '@material-ui/core'

import RefreshIcon from '@mui/icons-material/Refresh'
import { CircularProgress } from '@mui/material'
import { QueryErrorResetBoundary } from '@tanstack/react-query'

import useStyles from './SuspendedComponent.styles'

const FallbackComponnet = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <CircularProgress />
    </Paper>
  )
}

const SuspendedComponent = <T,>({ children }: PropsWithChildren<T>) => {
  const { t } = useTranslation()

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <div>
              <Typography>
                {t('errors.sever_error')}
                <IconButton onClick={resetErrorBoundary}>
                  <RefreshIcon />
                </IconButton>
              </Typography>
              <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
            </div>
          )}
          onReset={reset}
        >
          <Suspense fallback={<FallbackComponnet />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

export default SuspendedComponent
