const colors = {
  primary: '#24A1A1',
  primary55lighter: '#E4F9F9',

  // COMMON
  white: '#FFFFFF',
  black: '#000000',

  // GREYS
  dark: '#0C0C0D',
  gray3DA: '#98A1AA',
  darkLight: '#8699A3',
  darkGrey: '#585A5A',
  lightGrey: '#AEAEAE',
  gray7: '#F8F8F8',
  lightLighter: '#DCDCDC',
  background: '#F4F4F4',

  alert: '#FD5660',
  blue: '#00A3FF',

  // Action state
  whiteAction: '#F5F6F7',
  yellowAction: '#FCF4DB',
  redAction: '#FADDDD',
  greenAction: '#E4FAED',
  deepSpaceAction: '#D7E5EA',

  // Icon color
  redIcon: '#FF647C',
  yellowIcon: '#C99B0F',
  grayIcon: '#636E79',
  greenIcon: '#51AA76',
  deepSpaceIcon: '#436F7F',

  // Attention highlight
  attention: '#DA1E00',
  attentionAlt: '#AD1801',
}

export const eventColors = [
  {
    dark: '#467F35',
    light: '#EFF9EC',
    main: '#63AF4C',
  },
  {
    dark: '#1A55AF',
    light: '#F3EEF7',
    main: '#CCA4EC',
  },
  {
    dark: '#AD7816',
    light: '#F5F9EC',
  },
  {
    dark: '#986410',
    light: '#FAEFDD',
    main: '#e4be81',
  },
  {
    dark: '#1A55AF',
    light: '#DEF3FF',
    main: '#91CEF1',
  },
  {
    dark: '#bF3C88',
    light: '#FBEAF4',
    main: '#D85EA5',
  },
  {
    dark: '#6D1F93',
    light: '#F5EAFB',
  },
  {
    dark: '#e8323D',
    light: '#FBF0EA',
    main: '#EA5E66',
  },
  {
    dark: '#931F3A',
    light: '#FBEAEE',
  },
  {
    dark: '#B26824',
    light: '#FFF9E8',
    main: '#D69558',
  },
  {
    dark: '#6a862d',
    light: '#f5f9ec',
  },
  {
    dark: '#FA5F1C',
    light: '#FFECE6',
  },
  {
    dark: '#327B76',
    light: '#ECF9F8',
  },
  {
    dark: '#494B4D',
    light: '#EEF6FB',
    main: '#7BC5E6',
  },
]

export default colors
