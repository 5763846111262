import { colors } from '@dentalux/ui-library-core'

import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    width: 230,
    borderRadius: 6,
    boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
    color: colors.grey[300],
    marginTop: 10,
    overflow: 'hidden',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    height: 42,
    color: colors.grey[700],
  },
  icon: {
    marginRight: 9,
  },
  helpdesk: {
    '&&': {
      width: 18,
      marginTop: 4,
      marginRight: 12,
    },
  },
  translateIcon: {
    width: 24,
  },
  logout: {
    marginRight: 18,
    '& rect, & path': {
      fill: colors.grey[700],
    },
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  translationsMenuItem: {
    '&&': {
      opacity: 1,
      pointerEvents: 'all',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      cursor: 'auto',
    },
  },
  translationsMenuItemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  activeLanguageBtn: {},
  dropdownIcon: {
    marginRight: -4,
    fontSize: 16,
  },
  languageBtn: {
    '& .MuiButtonBase-root': {
      color: colors.grey[500],
      minWidth: 30,
    },

    '& $activeLanguageBtn': {
      color: colors.grey[800],
    },
  },
}))
