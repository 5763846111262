// This function is responsible for transforming the an array of strings
// in to the format accepted by the API (values separated by comma). When
// the array is empty, we return `undefined` allowing `axios` to remove
// this param from the request.
const parseArrayQueryParam = (array: string[] | undefined): string | undefined => {
  if (array === undefined) return undefined

  if (array.length === 0) return undefined

  return array.join(',')
}

export default parseArrayQueryParam
