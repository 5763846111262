import { Role, User } from '../@types/Auth'
import { Clinic } from '../@types/Clinic'

import '../setupEnv'

const toBoolean = (v = '') => {
  if (!v) return false

  return v === 'false' ? false : !!v
}

const isRoleAllowedToEditPatientChemistry = (role?: Role | null) =>
  role?.name?.includes('MANAGER') ||
  role?.name?.includes('DENTIST') ||
  role?.name === 'HYGIENIST' ||
  role?.name === 'RECEPTIONIST' ||
  role?.name === 'INTERN_SENIOR'

export const switchClinics = (user: User): boolean => true

export const viewBookings = (user: User): boolean => user.tennant !== 'Patient21'

export const viewAppointments = (user: User): boolean => true

export const viewWaitingRoom = (user: User): boolean => true

export const viewPatientSearch = (): boolean => toBoolean(process.env.REACT_APP_PATIENT_SEARCH_ENABLED)

export const showNewCalendar = (): boolean => toBoolean(process.env.REACT_APP_SHOW_NEW_CALENDAR)

export const incomingCalls = (): boolean => toBoolean(process.env.REACT_APP_INCOMING_CALLS_ENABLED)

export const hasHQStatus = (user: User) => !!user.role?.name?.includes('HQ')

export const isHQAdmin = (user: User) => user.role?.name === 'HQ_ADMIN' || user.role?.name === 'HQ_SUPER_ADMIN'

export const canEditChemistryScore = (user: User, currentClinicReferenceId?: string) => {
  const mainRoleAccess = isHQAdmin(user) || isRoleAllowedToEditPatientChemistry(user.role)

  /**
   * If the employee has multiple roles, we need to check them and also check are the roles
   * in the current clinic, just in case the employee has access to multiple clinics with different roles
   */
  if (user?.roles.length > 1 && currentClinicReferenceId) {
    const clinicRoleAccess = user.roles.reduce((acc, { clinics, role }) => {
      const clinic = clinics.find(({ referenceId }) => referenceId === currentClinicReferenceId)

      if (clinic) {
        return [...acc, role]
      }

      return acc
    }, [] as Role[])

    // If there is no role from the list of roles that have access to edit chemistry score, we return the evaluation of the main role
    return Boolean(clinicRoleAccess.find((role) => isRoleAllowedToEditPatientChemistry(role))) || mainRoleAccess
  }

  return mainRoleAccess
}

export const anonymousEnabled = (): boolean => toBoolean(process.env.REACT_APP_ANONYMOUS_ENABLED)

export const enableAnamnesisPractitionerView = (): boolean =>
  toBoolean(process.env.REACT_APP_ENABLE_ANAMNESIS_PRACTITIONER_VIEW)

export const enableAppointmentBundleCreation = (): boolean =>
  toBoolean(process.env.REACT_APP_ENABLE_APPOINTMENT_BUNDLE_CREATION)

export const enableAppMenu = (): boolean => toBoolean(process.env.REACT_APP_ENABLE_APP_MENU)

export const enableSmartVoiceLink = (): boolean => toBoolean(process.env.REACT_APP_ENABLE_SMART_VOICE)

export const hideCovidQuestionnaire = (): boolean => toBoolean(process.env.REACT_APP_HIDE_COVID)

export const enableDetailsField = (): boolean => toBoolean(process.env.REACT_APP_ENABLE_DETAILS_FIELD)

export const viewTodoList = (clinic?: Clinic, user?: User): boolean =>
  !!clinic?.featureTodoEnabled &&
  // hide todo list button for users with higher permissions
  !!user?.role?.applicationPermissions?.find(
    ({ application, view }) => application === 'CALMASTER' && view === 'TODO_LIST'
  )

export const viewAnamnesis = (user?: User): boolean =>
  !!user?.role?.applicationPermissions?.find(
    ({ application, view }) => application === 'CALMASTER' && view === 'PATIENT_ANAMNESIS_VIEW'
  )

export const viewHQComponent = (user?: User): boolean =>
  !!user?.role?.applicationPermissions?.find(
    ({ application, view }) => application === 'CALMASTER' && view === 'HQ_COMPONENT'
  )

export const showAnalytics = (user?: User) =>
  !!user?.role?.applicationPermissions?.find(
    ({ application, view }) => application === 'INSIGHT' && view === 'PERSONNEL_REPORT'
  )

export const enableTodoListTab = (): boolean => toBoolean(window.env.REACT_APP_ENABLE_TODO_LIST_TAB)
