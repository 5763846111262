import { useServerSentEvent } from '@dentalux/ui-library-core'

import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { compareDesc } from 'date-fns'

import { Conversation, ConversationMessage } from '../@types/Conversation'
import { ServerSentResourceType } from '../@types/ServerSentEvent'
import { convertTimeStampWithoutZone, validateTimeStamps } from '../helpers/date'

type UseConversationSubscriptionParams = { queryKey: QueryKey; disabled: boolean }

type UpdaterProps = Conversation[] | undefined

const removeZoneFromConversation = (conversation: Conversation): Conversation => {
  const mappedTimeStamp = convertTimeStampWithoutZone(conversation.timeStamp)
  const mappedMessages = conversation.latestMessages.map((message: ConversationMessage) => ({
    ...message,
    timeStamp: convertTimeStampWithoutZone(message.timeStamp),
  }))

  return { ...conversation, timeStamp: mappedTimeStamp, latestMessages: mappedMessages }
}

const sortConversations = (conversations: Conversation[]) => {
  return [...conversations].sort((conversationA, conversationB) => {
    const timeA = new Date(conversationA.timeStamp)
    const timeB = new Date(conversationB.timeStamp)

    return compareDesc(timeA, timeB)
  })
}

const useConversationSubscription = ({ queryKey, disabled }: UseConversationSubscriptionParams): void => {
  const client = useQueryClient()

  const handleUpdate = (conversation: Conversation) => {
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (
        validateTimeStamps(conversation.timeStamp) === undefined ||
        conversation.latestMessages.some((message) => message.timeStamp === undefined)
      )
        return
      if (!previousData) return
      return sortConversations(
        previousData.map((previousConversation) =>
          previousConversation.referenceId === conversation.referenceId
            ? removeZoneFromConversation(conversation)
            : previousConversation
        )
      )
    })
  }

  const handleCreate = (conversation: Conversation) => {
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (
        validateTimeStamps(conversation.timeStamp) === undefined &&
        conversation.latestMessages.some((message) => message.timeStamp === undefined)
      )
        return
      if (!previousData) return
      return sortConversations([removeZoneFromConversation(conversation), ...previousData])
    })
  }

  const handleDelete = (conversation: Conversation) => {
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (!previousData) return
      return previousData.filter(({ referenceId }) => referenceId === conversation.referenceId)
    })
  }

  useServerSentEvent(ServerSentResourceType.CONVERSATION, 'CREATED', handleCreate, disabled)
  useServerSentEvent(ServerSentResourceType.CONVERSATION, 'UPDATED', handleUpdate, disabled)
  useServerSentEvent(ServerSentResourceType.CONVERSATION, 'DELETED', handleDelete, disabled)
}

export default useConversationSubscription
