import React from 'react'

import { createRoot } from 'react-dom/client'

import smoothscroll from 'smoothscroll-polyfill'

import App from './App'
import { worker } from './mocks/browser'
import * as serviceWorker from './serviceWorker'

smoothscroll.polyfill()

const container = document.getElementById('root')
const root = createRoot(container!)

if (process.env.REACT_APP_ENV === 'development') {
  worker.start()
} else {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

root.render(<App />)
