import React, { lazy, useEffect } from 'react'

import { Switch, useParams } from 'react-router-dom'

import { ProtectedRoute } from '@dentalux/security'

import { ParamTypes } from './@types/RouteParams'
import SuspendedComponent from './components/SuspendedComponent'
import CalendarDimensionsProvider from './providers/CalendarDimensionsProvider'

const CalendarContainer = lazy(() => import('./components/MainCalendar/Container'))
const PatientSearch = lazy(() => import('./components/PatientSearch/PatientSearch'))
const PatientDetailsPage = lazy(() => import('./pages/PatientDetails'))

export const routes = {
  home: '/',
  calendar: '/calendar/:view?/:date?/:sideNav?/:clinicReferenceId?',
  appointment: '/calendar/:view?/:appointmentReferenceId?',
  search: '/calendar/:view?/:date?/:sideNav?/:clinicReferenceId?/search',
  patient: '/calendar/:view?/:date?/:sideNav?/:clinicReferenceId?/patient/:patientReferenceId',
  appointmentWithClinic: '/calendar/:view?/:date?/:sideNav?/:clinicReferenceId?/:appointmentReferenceId?',
}

const AppRouter = () => {
  const { view, date, sideNav } = useParams<ParamTypes>()
  const viewString = view && `${view} calendar`
  const dateString = date && `, ${date}`
  const sideNavString = sideNav && `, ${sideNav} selected from reception`
  useEffect(() => {
    document.title = `Calmaster - ${viewString}${dateString}${sideNavString}`
  }, [viewString, dateString, sideNavString])

  return (
    <Switch>
      <ProtectedRoute exact path={routes.search}>
        <SuspendedComponent>
          <PatientSearch pageNumber={0} />
        </SuspendedComponent>
      </ProtectedRoute>

      <ProtectedRoute exact path={routes.patient}>
        <SuspendedComponent>
          <PatientDetailsPage />
        </SuspendedComponent>
      </ProtectedRoute>

      <ProtectedRoute exact path={routes.appointment}>
        <SuspendedComponent>
          <CalendarDimensionsProvider>
            <CalendarContainer />
          </CalendarDimensionsProvider>
        </SuspendedComponent>
      </ProtectedRoute>

      <ProtectedRoute exact path={routes.calendar}>
        <SuspendedComponent>
          <CalendarDimensionsProvider>
            <CalendarContainer />
          </CalendarDimensionsProvider>
        </SuspendedComponent>
      </ProtectedRoute>

      <ProtectedRoute exact path={routes.appointmentWithClinic}>
        <SuspendedComponent>
          <CalendarDimensionsProvider>
            <CalendarContainer />
          </CalendarDimensionsProvider>
        </SuspendedComponent>
      </ProtectedRoute>

      <ProtectedRoute exact path="*">
        <SuspendedComponent>
          <CalendarDimensionsProvider>
            <CalendarContainer />
          </CalendarDimensionsProvider>
        </SuspendedComponent>
      </ProtectedRoute>
    </Switch>
  )
}

export default AppRouter
