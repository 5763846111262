import { useEffect, EffectCallback } from 'react'

function useDidMount(callback: EffectCallback, dismountCallback?: EffectCallback): void {
  useEffect(() => {
    if (typeof callback === 'function') {
      callback()
    }

    return () => {
      if (typeof dismountCallback === 'function') dismountCallback()
    }
    // Ignoring the dependencies to make it run only when the component mounts.
  }, []) //eslint-disable-line
}

export { useDidMount }
