import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import chunk from 'lodash/chunk'

import '../../setupEnv'

import {
  AnamnesisCodeCreatorApplication,
  AnamnesisCodeLength,
  AnamnesisCodeMedium,
  AnamnesisCodeReason,
  AnamnesisDetails,
} from '../../@types/Anamnesis'
import { AnamnesisSummary } from '../../@types/Patient'
import parseArrayQueryParam from '../../helpers/parseArrayQueryParam'
import errors from '../../i18n/errors/ana'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_ANA_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http, errors)

export const getAnamnesisDocumentUrlsForPatient = async (
  patientReferenceId: string
): Promise<{ covidQuestionnaireDocumentUrl: string | null; factoringAgreementDocumentUrl: string | null }> => {
  const { data } = await http.get(`/api/internal/patients/${patientReferenceId}/document-urls`)

  return data
}

export const postCode = async (referenceId: string): Promise<string> => {
  const { data } = await http.post(`api/internal/patients/${referenceId}/codes`, {
    type: AnamnesisCodeLength.SHORT,
    sentMedium: AnamnesisCodeMedium.CLINIC,
    sentReason: AnamnesisCodeReason.CLINIC_TRIGGERED,
    creatorApplication: AnamnesisCodeCreatorApplication.CALMASTER_FRONTEND,
  })

  return data
}

export type SendLinkViaSmsParams = {
  referenceId: string
  language: 'de' | 'en'
}

export const sendLinkViaSMS = async ({ referenceId, language }: SendLinkViaSmsParams): Promise<string> => {
  const { data } = await http.post(`api/internal/patients/${referenceId}/codes`, {
    type: AnamnesisCodeLength.SMS,
    language: language.toUpperCase(),
    sentMedium: AnamnesisCodeMedium.SMS,
    sentReason: AnamnesisCodeReason.CLINIC_TRIGGERED,
    creatorApplication: AnamnesisCodeCreatorApplication.CALMASTER_FRONTEND,
  })

  return data
}

export const patchAnamnesisHistory = async ({
  referenceId,
  payload,
}: {
  referenceId: string
  payload: { valid: boolean }
}) => {
  const { data } = await http.patch(`/api/anamnesis-histories/${referenceId}`, payload)

  return data
}

export const patchFactoringAgreement = async ({
  referenceId,
  payload,
}: {
  referenceId: string
  payload: { valid: boolean }
}) => {
  const { data } = await http.patch(`/api/factoring-agreements/${referenceId}`, payload)

  return data
}

export const patchCovidQuestionnaire = async ({
  referenceId,
  payload,
}: {
  referenceId: string
  payload: { valid: boolean }
}) => {
  const { data } = await http.patch(`/api/covid-questionnaires/${referenceId}`, payload)

  return data
}

export const getPatientAnamnesis = async (referenceId: string): Promise<AnamnesisDetails> => {
  const { data } = await http.get(`/api/internal/patients/${referenceId}/anamnesis-summaries`)

  return data
}

export const getPatientMedications = async (referenceId: string): Promise<AnamnesisSummary> => {
  const { data } = await http.get(`/api/internal/patients/${referenceId}/anamnesis/medications`)

  return data
}

export const getPatientsAnamnesis = async (referenceIds: string[]): Promise<AnamnesisDetails[]> => {
  const chunkSize = 100
  const patientChunks = chunk(referenceIds, chunkSize)

  const promises = patientChunks.map((chunk) =>
    http
      .get(`/api/internal/patients/anamnesis-summaries`, {
        params: {
          patientReferenceIds: parseArrayQueryParam(chunk),
        },
      })
      .then((response) => response.data)
  )

  const results = await Promise.all(promises)

  return results.flat()
}
