import { QueryFunctionContext } from '@tanstack/react-query'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryFn<T> = (...params: any[]) => T | Promise<T>

const withQueryParams =
  <T>(queryFn: QueryFn<T>) =>
  (queryContext: QueryFunctionContext) => {
    const { queryKey, signal } = queryContext

    if (Array.isArray(queryKey)) {
      const [, ...params] = queryKey

      return queryFn(...params, signal)
    }

    return queryFn(queryKey, signal)
  }

export default withQueryParams
