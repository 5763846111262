import { makeStyles } from '@material-ui/core'

export default makeStyles(
  () => ({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      paddingRight: 72,
      zIndex: 1200,
    },
  }),
  { name: 'MinimizedPatients' }
)
