const supportsNotification = 'Notification' in window

export const setup = (): void => {
  if (!supportsNotification) return

  if (Notification.permission === 'default') {
    Notification.requestPermission()
  }
}

export const notify = (message: string, requireInteraction = false, icon = '/logo.png'): void => {
  if (!supportsNotification) return

  if (document.hidden && Notification.permission === 'granted') {
    const notification = new Notification(message, {
      body: 'Calmaster',
      icon,
      requireInteraction,
    })

    notification.onclick = () => window.focus()
  }
}
