import { CorePatient } from './Patient'

export enum CallTaskStates {
  Done = 'Done',
  Done_AvailyBooking = 'Done_AvailyBooking',
  Open = 'Open',
  Ignored = 'Ignored',
  Open_UnsuccessfulCallback = 'Open_UnsuccessfulCallback',
  Open_Ringing = 'Open_Ringing',
  Open_InProgress = 'Open_InProgress',
}

export enum CallTaskActionTypes {
  CallBack = 'CallBack',
  Ignore = 'Ignore',
  Ignored = 'Ignored',
  UnsuccessfulCallBack = 'UnsuccessfulCallBack',
}

export type IgnoreAction = {
  data: {
    comment: string
    type: CallTaskActionTypes.Ignore
  }
  taskReferenceId: string
}

export type CallTaskAction = {
  comment?: string
  timeStamp: string
  type: CallTaskActionTypes | string
}

type CallTaskCaller = {
  phoneNumber: string
  patients: CorePatient[] | null
}

export interface CallTask {
  action: CallTaskAction
  callbackRequiredAt: string
  caller: CallTaskCaller
  clinicReferenceId: string
  currentState: CallTaskStates
  incomingCallSize: number
  start: string
  priority?: number
  referenceId: string
  latestIncomingCallAt: string
  hasAnsweringMachineRecordings: boolean
  solvedAt: string | null
}

export type CallTaskSubscription = Record<'callTaskUpdated' | 'callTaskCreated' | 'callTaskDeleted', CallTask>
