import React, { useMemo } from 'react'

import deepEqual from 'deep-equal'
import { useDebounce } from 'use-debounce/lib'

import usePatients from '../../../../hooks/usePatients'
import { useCurrentClinicProvider } from '../../../../providers/CurrentClinicProvider'

import { getStringLengthRequirement } from './PatientSearchInput.helpers'

interface UseSearchUserProps {
  query?: string
  isSelected: boolean
}

export const useSearchUser = ({ query = '', isSelected }: UseSearchUserProps) => {
  const { selectedClinic } = useCurrentClinicProvider()

  const patientsParams = useMemo(
    () => ({
      query,
      page: 0,
      pageSize: 10,
      clinicReferenceIds: selectedClinic?.referenceId,
      searchLength: getStringLengthRequirement(query),
    }),
    [selectedClinic?.referenceId, query]
  )

  const [patientsParamsDebounced] = useDebounce(patientsParams, 500, { equalityFn: deepEqual })

  const { data, isFetching } = usePatients(patientsParamsDebounced, patientsParamsDebounced.searchLength, isSelected)

  return {
    data: data?.content ?? [],
    isFetching,
  }
}
