import React, { useState, useContext, useMemo } from 'react'

export const Context = React.createContext<{ showAnalytics: boolean; setShowAnalytics: (value: boolean) => void }>({
  showAnalytics: false,
  setShowAnalytics: () => {},
})

export const useAnalyticsToggleContext = () => useContext(Context)

const AnalyticsToggleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showAnalytics, setShowAnalytics] = useState<boolean>(false)

  const value = useMemo(
    () => ({
      showAnalytics,
      setShowAnalytics,
    }),
    [showAnalytics, setShowAnalytics]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default AnalyticsToggleProvider
