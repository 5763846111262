import React, { PropsWithChildren, useEffect, useMemo } from 'react'

import { Language, useFilterEmployees, useLocalStorage, usePrevious } from '@dentalux/common'

import {
  INITIAL_WAITING_LIST_EMPLOYEES_STORAGE_KEY,
  INITIAL_WAITING_LIST_EMPLOYEES_SEARCH_STORAGE_KEY,
  SHOW_WAITING_LIST_INACTIVE_EMPLOYEES_STORAGE_KEY,
} from '../helpers/constants'

import { useCurrentClinicProvider } from './CurrentClinicProvider'
import { useLanguageContext } from './LanguageProvider'
import { usePractitionersContext } from './PractitionersProvider'

export const Context = React.createContext<ReturnType<typeof useFilterEmployees>>({
  isRoleSelected: () => false,
  isAllEmployeesByRoleSelected: () => false,
  selectedEmployeesEntities: {},
  selectedEmployeeReferenceIds: [],
  selectedEmployees: [],
  toggleAllByRole: () => {},
  toggleEmployee: () => {},
  toggleAll: () => {},
  clearAll: () => {},
  isAllSelected: true,
  setSearchedEmployeeString: () => {},
  searchedEmployeeString: '',
  filteredEmployees: [],
  filteredSelectedEmployeeReferenceIds: [],
  employeesByRoles: {},
  showDeactivatedEmployees: false,
  setShowDeactivatedEmployees: () => {},
  filteredSelectedEmployees: [],
  setShowWorkingEmployees: () => {},
  showWorkingEmployees: false,
})

export const UpcomingEmployeesFiltersProvider = ({ children }: PropsWithChildren) => {
  const { data: initialEmployees } = usePractitionersContext()
  const { currentLanguage } = useLanguageContext()
  const { selectedClinic } = useCurrentClinicProvider()

  const [initialSelectedIds, setInitialSelectedIds] = useLocalStorage<Record<string, Array<string>>>(
    INITIAL_WAITING_LIST_EMPLOYEES_STORAGE_KEY,
    undefined
  )

  const [initialSearchStrings, setInitialSearchStrings] = useLocalStorage<Record<string, string>>(
    INITIAL_WAITING_LIST_EMPLOYEES_SEARCH_STORAGE_KEY,
    undefined
  )

  const [initialWorkingEmployees, setInitialWorkingEmployees] = useLocalStorage<Record<string, boolean>>(
    INITIAL_WAITING_LIST_EMPLOYEES_STORAGE_KEY,
    undefined
  )

  const [initialInactiveEmployees, setInitialInactiveEmployees] = useLocalStorage<Record<string, boolean>>(
    SHOW_WAITING_LIST_INACTIVE_EMPLOYEES_STORAGE_KEY,
    undefined
  )

  const initialSelectedEmployeeIds = initialSelectedIds?.[selectedClinic?.referenceId ?? '']
  const initialSearchedEmployeeString = initialSearchStrings?.[selectedClinic?.referenceId ?? '']
  const initialShowWorkingEmployees = initialWorkingEmployees?.[selectedClinic?.referenceId ?? '']
  const initialDeactivatedEmployees = initialInactiveEmployees?.[selectedClinic?.referenceId ?? '']

  const employeesFilter = useFilterEmployees({
    initialEmployees,
    language: currentLanguage as Language,
    initialDeactivatedEmployees,
    initialSelectedEmployeeIds,
    initialShowWorkingEmployees,
    initialSearchedEmployeeString,
  })

  const previousSearchString = usePrevious(employeesFilter.searchedEmployeeString)
  const previousShowWorkingEmployees = usePrevious(initialShowWorkingEmployees)
  const previousInactiveWorkingEmployees = usePrevious(initialDeactivatedEmployees)

  const searchedEmployeeString = useMemo(
    () => employeesFilter.searchedEmployeeString,
    [employeesFilter.searchedEmployeeString]
  )

  const filteredSelectedEmployeeReferenceIds = useMemo(
    () => employeesFilter.filteredSelectedEmployeeReferenceIds,
    [employeesFilter.filteredSelectedEmployeeReferenceIds]
  )

  const showWorkingEmployees = useMemo(
    () => employeesFilter.showWorkingEmployees,
    [employeesFilter.showWorkingEmployees]
  )

  const showInactiveEmployees = useMemo(
    () => employeesFilter.showDeactivatedEmployees,
    [employeesFilter.showDeactivatedEmployees]
  )

  // Selected employees
  useEffect(() => {
    if (selectedClinic?.referenceId && employeesFilter?.filteredSelectedEmployeeReferenceIds) {
      const currentData = initialSelectedIds ?? {}

      const data = {
        ...currentData,
        [selectedClinic.referenceId]: employeesFilter?.filteredSelectedEmployeeReferenceIds,
      }

      setInitialSelectedIds(data)
    }
  }, [filteredSelectedEmployeeReferenceIds])

  // Working employees
  useEffect(() => {
    if (selectedClinic?.referenceId) {
      const currentData = initialWorkingEmployees ?? {}

      const data = {
        ...currentData,
        [selectedClinic.referenceId]: showWorkingEmployees ?? false,
      }

      setInitialWorkingEmployees(data)
    }
  }, [showWorkingEmployees])

  useEffect(() => {
    if (initialShowWorkingEmployees && typeof previousShowWorkingEmployees === 'undefined') {
      employeesFilter.setShowWorkingEmployees(initialShowWorkingEmployees)
    }
  }, [initialShowWorkingEmployees])

  // Inactive employees
  useEffect(() => {
    if (selectedClinic?.referenceId) {
      const currentData = initialInactiveEmployees ?? {}

      const data = {
        ...currentData,
        [selectedClinic.referenceId]: showInactiveEmployees ?? false,
      }

      setInitialInactiveEmployees(data)
    }
  }, [showInactiveEmployees])

  useEffect(() => {
    if (initialDeactivatedEmployees && typeof previousInactiveWorkingEmployees === 'undefined') {
      employeesFilter.setShowDeactivatedEmployees(initialDeactivatedEmployees)
    }
  }, [initialDeactivatedEmployees])

  // Employee search
  useEffect(() => {
    if (selectedClinic?.referenceId && initialSearchedEmployeeString) {
      employeesFilter.setSearchedEmployeeString(initialSearchedEmployeeString)
    }
  }, [initialSearchedEmployeeString])

  useEffect(() => {
    if (selectedClinic?.referenceId && typeof previousSearchString !== 'undefined') {
      const currentData = initialSearchStrings ?? {}

      const data = {
        ...currentData,
        [selectedClinic.referenceId]: searchedEmployeeString ?? '',
      }

      setInitialSearchStrings(data)
    }
  }, [searchedEmployeeString])

  return <Context.Provider value={employeesFilter}>{children}</Context.Provider>
}

export const useUpcomingEmployeesFiltersContext = () => {
  const context = React.useContext(Context)

  if (context === undefined)
    throw new Error('useUpcomingEmployeesFiltersContext must be used within a UpcomingEmployeesFiltersProvider')

  return context
}
