import React from 'react'

import { createSvgIcon } from '@material-ui/core'

export default createSvgIcon(
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <defs>
      <rect x="0.5" y="0.5" width="32" height="32" />
    </defs>
    <path
      d="M6.66667 4C5.19333 4 4 5.19333 4 6.66667C4 8.14 5.19333 9.33333 6.66667 9.33333C8.14 9.33333 9.33333 8.14 9.33333 6.66667C9.33333 5.19333 8.14 4 6.66667 4ZM16 4C14.5267 4 13.3333 5.19333 13.3333 6.66667C13.3333 8.14 14.5267 9.33333 16 9.33333C17.4733 9.33333 18.6667 8.14 18.6667 6.66667C18.6667 5.19333 17.4733 4 16 4ZM25.3333 4C23.86 4 22.6667 5.19333 22.6667 6.66667C22.6667 8.14 23.86 9.33333 25.3333 9.33333C26.8067 9.33333 28 8.14 28 6.66667C28 5.19333 26.8067 4 25.3333 4ZM6.66667 13.3333C5.19333 13.3333 4 14.5267 4 16C4 17.4733 5.19333 18.6667 6.66667 18.6667C8.14 18.6667 9.33333 17.4733 9.33333 16C9.33333 14.5267 8.14 13.3333 6.66667 13.3333ZM16 13.3333C14.5267 13.3333 13.3333 14.5267 13.3333 16C13.3333 17.4733 14.5267 18.6667 16 18.6667C17.4733 18.6667 18.6667 17.4733 18.6667 16C18.6667 14.5267 17.4733 13.3333 16 13.3333ZM25.3333 13.3333C23.86 13.3333 22.6667 14.5267 22.6667 16C22.6667 17.4733 23.86 18.6667 25.3333 18.6667C26.8067 18.6667 28 17.4733 28 16C28 14.5267 26.8067 13.3333 25.3333 13.3333ZM6.66667 22.6667C5.19333 22.6667 4 23.86 4 25.3333C4 26.8067 5.19333 28 6.66667 28C8.14 28 9.33333 26.8067 9.33333 25.3333C9.33333 23.86 8.14 22.6667 6.66667 22.6667ZM16 22.6667C14.5267 22.6667 13.3333 23.86 13.3333 25.3333C13.3333 26.8067 14.5267 28 16 28C17.4733 28 18.6667 26.8067 18.6667 25.3333C18.6667 23.86 17.4733 22.6667 16 22.6667ZM25.3333 22.6667C23.86 22.6667 22.6667 23.86 22.6667 25.3333C22.6667 26.8067 23.86 28 25.3333 28C26.8067 28 28 26.8067 28 25.3333C28 23.86 26.8067 22.6667 25.3333 22.6667Z"
      fill="currentColor"
    />
  </svg>,
  'MenuGridIcon'
)
