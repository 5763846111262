import { useMemo } from 'react'

import { QueryKey, useQuery, QueryObserverResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { CalmasterAppointment } from '../@types/Appointment'
import { AppointmentsParams, CalmasterAppointmentsSortField, SortDirection } from '../@types/AppointmentsParams'
import { minutesToMs } from '../helpers/time'
import withQueryParams from '../helpers/withQueryParams'
import { useCancelledAppointmentsToggle } from '../providers/CancelledAppointmentsToggleProvider'
import api from '../services/api'

import useAppointmentParams from './useAppointmentParams'

interface UseAppointmentsProps {
  params: Record<string, unknown>
  startAfter: string
  startBefore: string
  endBefore: string
  patientReferenceIds: string[]
  debounce: number
  disabled: boolean
  key?: QueryKey
}

interface UseAppointmentsResult {
  appointments: QueryObserverResult<CalmasterAppointment[], AxiosResponse>
  queryKey: QueryKey
}

const useAppointments = ({
  params = {},
  startAfter,
  startBefore,
  endBefore,
  patientReferenceIds,
  debounce,
  disabled,
  key,
}: Partial<UseAppointmentsProps> = {}): UseAppointmentsResult => {
  const service = api.calmaster.getAppointments
  const filterParams = useAppointmentParams({ startAfter, startBefore, endBefore, patientReferenceIds }, debounce)

  const { showCancelledAppointments: showCancelled } = useCancelledAppointmentsToggle()

  const hasClinic = filterParams?.clinicReferenceIds?.length
  const hasPatient = filterParams?.patientReferenceIds?.length

  const appointmentsParams: AppointmentsParams = useMemo(
    () => ({
      size: 700,
      sortBy: CalmasterAppointmentsSortField.startTime,
      sortDirection: SortDirection.ASC,
      ...params,
    }),
    [params]
  )

  const queryParams = useMemo(
    () => ({
      showCancelled,
      ...filterParams,
      ...appointmentsParams,
    }),
    [appointmentsParams, filterParams, showCancelled]
  )

  const queryKey = useMemo(() => key ?? ['appointments', queryParams], [queryParams]) as QueryKey
  const hasPractitioners = queryParams?.practitionerReferenceIds?.length

  const isDisabled = disabled || (hasPatient ? false : !hasClinic || !hasPractitioners)

  const query = useQuery<CalmasterAppointment[], AxiosResponse, CalmasterAppointment[]>({
    enabled: !isDisabled,
    queryFn: withQueryParams(service),
    queryKey,
    refetchInterval: minutesToMs(30),
    keepPreviousData: true,
  })

  return { appointments: query, queryKey }
}

export default useAppointments
