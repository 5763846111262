import React, { useMemo, useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { getNameInitials, Language } from '@dentalux/common'
import { QuestionBubble } from '@dentalux/icons/build'
import { useCredentials, useLogout } from '@dentalux/security'
import { Avatar } from '@dentalux/ui-library-core'
import { MenuItem, MenuItemProps } from '@dentalux/ui-library-core'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { IconButton } from '@mui/material'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'

import { ReactComponent as DeutschIcon } from '../../../../assets/icons/language-deutsch.svg'
import { ReactComponent as EnglishIcon } from '../../../../assets/icons/language-english.svg'
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logout-icon.svg'
import { insertIf } from '../../../../helpers/array'
import { HELPDESK_EXTERNAL_URL } from '../../../../helpers/constants'
import getEnvironmentName from '../../../../helpers/getEnvironmentName'
import { useBitComponentsContext } from '../../../../providers/BitComponentsProvider'
import { useLanguageContext } from '../../../../providers/LanguageProvider'

import { useStyles } from './UserDropdown.styles'

export const UserDropdown = () => {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()
  const { t } = useTranslation()
  const logout = useLogout()
  const { setShowBitComponents, showBitComponents } = useBitComponentsContext()
  const { authentication } = useCredentials()
  const { currentLanguage, changeLanguage } = useLanguageContext()

  const { imgUrl, name } = authentication

  const initials = getNameInitials(name)
  const currentEnv = getEnvironmentName()

  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const toggleBitComponents = useCallback(
    () => setShowBitComponents(!showBitComponents),
    [setShowBitComponents, showBitComponents]
  )

  const handleLanguageChange = useCallback(
    (language: 'de' | 'en') => () => {
      changeLanguage(language)
    },
    [changeLanguage]
  )

  const openHelpdesk = () => {
    window.open(HELPDESK_EXTERNAL_URL, '_blank')?.focus()
  }

  interface SingleListItem extends MenuItemProps {
    title: string
    onClick: () => void
  }

  const elements: Array<SingleListItem> = useMemo(
    () => [
      ...insertIf<SingleListItem>(currentEnv !== 'production', [
        {
          title: 'Toggle Bit components',
          onClick: toggleBitComponents,
          variant: 'checkbox' as const,
          selected: showBitComponents,
        },
      ]),
      {
        title: t(Language.DEUTSCH),
        selected: currentLanguage === Language.DEUTSCH,
        icon: <DeutschIcon />,
        onClick: handleLanguageChange(Language.DEUTSCH),
      },
      {
        title: t(Language.ENGLISH),
        selected: currentLanguage === Language.ENGLISH,
        icon: <EnglishIcon />,
        onClick: handleLanguageChange(Language.ENGLISH),
      },
      {
        title: t('helpdesk'),
        onClick: openHelpdesk,
        icon: <QuestionBubble />,
      },
      {
        title: t('sign_out'),
        onClick: logout,
        icon: <LogoutIcon />,
      },
    ],
    [currentEnv, currentLanguage, handleLanguageChange, logout, showBitComponents, t, toggleBitComponents]
  )

  return (
    <div>
      <IconButton ref={anchorRef} onClick={handleToggle} disableRipple aria-label="user-menu">
        <Avatar size="m" src={imgUrl}>
          {initials}
        </Avatar>
      </IconButton>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper className={classes.menu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.menuList} autoFocusItem={open} id="menu-list-grow">
                  {elements.map(({ onClick, title, selected, variant, icon }) => (
                    <MenuItem
                      key={title}
                      selected={selected}
                      variant={variant ?? 'checkmark'}
                      onClick={onClick}
                      icon={icon}
                    >
                      {title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
