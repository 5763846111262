import { useMemo, useRef, useState } from 'react'

import { MenuItem } from '@dentalux/ui-library-core'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'

import { ReactComponent as GridMenuIcon } from '../../../../assets/icons/grid-menu.svg'

import { useStyles } from './AppGridMenu.styles'

export const AppGridMenu = () => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)

  const handleClose = (event: globalThis.MouseEvent | globalThis.TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleClick = () => {
    if (window?.env.REACT_APP_SHIFT_PLANNER) window.open(window.env.REACT_APP_SHIFT_PLANNER, '_blank')?.focus()
  }

  const elements = useMemo(
    () => [
      {
        title: 'Calmaster',
        selected: true,
      },
      {
        title: 'Shift Planner',
        onClick: handleClick,
      },
    ],
    []
  )

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleToggle} disableRipple size="lg" aria-label="grid-menu">
        <GridMenuIcon className={classes.icon} />
      </IconButton>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper className={classes.menu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.menuList} autoFocusItem={open} id="menu-list-grow">
                  {elements.map(({ title, onClick, selected }) => (
                    <MenuItem variant="checkmark" selected={selected} key={title} onClick={onClick}>
                      {title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
