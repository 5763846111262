import React, { useState, useEffect, PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useCredentials } from '@dentalux/security'

import { Button, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import AddIcon from '@mui/icons-material/Add'
import * as Sentry from '@sentry/react'

import { AnalyticsLabel, AnalyticsCategory } from '../@types/Analytics'
import getEnvironmentName from '../helpers/getEnvironmentName'
import { useBitComponentsContext } from '../providers/BitComponentsProvider'
import { CallTasksProvider } from '../providers/CallTasksProvider'
import { ConversationsProvider } from '../providers/ConversationsProvider'
import { useCurrentClinicProvider } from '../providers/CurrentClinicProvider'
import { useEmployeesFiltersContext } from '../providers/EmployeesFiltersProvider'
import { useFeatureFlags } from '../providers/FeatureFlags'
import {
  usePatientDetailsModalState,
  usePatientDetailsModalDispatch,
  removeMinimizedPatient,
  maximizePatient,
} from '../providers/PatientDetailsModalProvider'
import { useSideNavigationContext } from '../providers/SideNavigationProvider'
import { sendPageView, sendAnalyticsEvent } from '../services/analytics'
import * as notifications from '../services/notifications'

import AppBar from './AppBar'
import AppContent from './AppContent'
import { useStyles } from './AppShell.styles'
import { MainHeader } from './MainHeader'
import MinimizedPatients from './MinimizedPatients'
import SideNav from './SideNav'

const AppShell = ({ children }: PropsWithChildren) => {
  const isDesktop = window.matchMedia('(min-width:600px)').matches
  const featureFlags = useFeatureFlags()
  const location = useLocation()
  const environment = getEnvironmentName()
  const classes = useStyles()
  const { t } = useTranslation()
  const { authentication } = useCredentials()

  const { selectedClinic } = useCurrentClinicProvider()
  const { filteredSelectedEmployees } = useEmployeesFiltersContext()
  const { calendarRef } = useBitComponentsContext()
  const { open: showSidenav } = useSideNavigationContext()

  const { minimizedPatients } = usePatientDetailsModalState()
  const patientModalDispatch = usePatientDetailsModalDispatch()

  const [showDrawer, setShowDrawer] = useState<boolean>(false)

  const canCreateAppointment = !!filteredSelectedEmployees.length

  const handleToggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const handleCloseMinimizedPatient = (patientReferenceId: string) => {
    patientModalDispatch(removeMinimizedPatient(patientReferenceId))
  }

  const handleMaximizePatient = (patientReferenceId: string) => {
    sendAnalyticsEvent(AnalyticsLabel.maximize_window, AnalyticsCategory.patient_detail_view)
    patientModalDispatch(maximizePatient(patientReferenceId))
  }

  const handleCreateAppointment = () => {
    calendarRef?.toggleAppointmentDialog(true)
  }

  useEffect(() => sendPageView(location), [location])

  useEffect(() => {
    notifications.setup()
  }, [environment])

  useEffect(() => {
    if (authentication && selectedClinic) {
      const { email, name, referenceId, tennant } = authentication
      const { referenceId: clinicReferenceId } = selectedClinic

      Sentry.setUser({
        email,
        name,
        referenceId,
        tennant,
        clinicReferenceId,
      })
    }
  }, [authentication, selectedClinic])

  return (
    <Grid>
      <Grid item xs={12}>
        <MainHeader />

        {featureFlags.viewWaitingRoom && (
          <ConversationsProvider>
            <CallTasksProvider>
              <SideNav isOpen={showSidenav} />
            </CallTasksProvider>
          </ConversationsProvider>
        )}

        <AppBar shiftContent={showSidenav} onToggleDrawer={handleToggleDrawer} />

        <AppContent shiftContent={(!showDrawer && !featureFlags.viewWaitingRoom) || !showSidenav}>
          {children}
        </AppContent>

        {canCreateAppointment && !location?.search && (
          <div className={classes.appointmentButtonWrapper}>
            <Tooltip title={t('appointment_add_new_title') || ''}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={handleCreateAppointment}
                className={classes.appointmentButton}
                startIcon={<AddIcon />}
              >
                {t('appointment_create_title')}
              </Button>
            </Tooltip>
          </div>
        )}

        {isDesktop && (
          <MinimizedPatients
            patients={minimizedPatients}
            onMaximize={handleMaximizePatient}
            onClose={handleCloseMinimizedPatient}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default AppShell
