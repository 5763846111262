import React, { PropsWithChildren } from 'react'

import { KeyPrefix, Namespace, useTranslation, UseTranslationResponse } from 'react-i18next'

import { SnackbarKey, useSnackbar, VariantType } from 'notistack'
import { SnackbarProvider as NotistackProvider, SnackbarProviderProps, SnackbarMessage } from 'notistack'

import { Field } from '../@types/ServerError'
import Snackbar from '../components/Snackbar'

let snackbarRef: any
let translationsRef: UseTranslationResponse<Namespace<string>, KeyPrefix<Namespace<string>>>

// Required to work with snackbar messages outside components
const SnackbarProviderConfigurator = () => {
  snackbarRef = useSnackbar()
  translationsRef = useTranslation()
  return null
}

export const SnackbarProvider = ({ children, ...props }: PropsWithChildren<SnackbarProviderProps>) => (
  <NotistackProvider {...props}>
    <SnackbarProviderConfigurator />
    {children}
  </NotistackProvider>
)

interface AddSnackbarMessageProps {
  message: string
  variant: VariantType
  sentryId?: string
  customMessage?: string | undefined
  status?: number
  fields?: Array<Field>
  centered?: boolean
}

export const addSnackbarMessage = ({
  message = '',
  variant = 'default',
  fields,
  sentryId,
  customMessage,
  status,
  centered,
}: AddSnackbarMessageProps) => {
  const translation = customMessage && translationsRef?.t(customMessage)

  const handleSnackbarClose = (id: SnackbarKey) => snackbarRef?.closeSnackbar(id)

  snackbarRef?.enqueueSnackbar(message, {
    variant,
    preventDuplicate: true,
    ...(centered && {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    }),
    content: (key: SnackbarKey, message: SnackbarMessage) => (
      <Snackbar
        message={translation ?? message}
        variant={variant}
        details={translation && message}
        sentryId={sentryId}
        id={key}
        status={status}
        onClose={handleSnackbarClose}
        fields={fields}
      />
    ),
  })
}
