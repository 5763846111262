import React, { useCallback } from 'react'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import clsx from 'clsx'

import '../../../../setupEnv'

import CircleOk from '../../../../assets/icons/CircleOk'
import MenuGridIcon from '../../../../assets/icons/MenuGridIcon'

import useStyles from './AppMenu.styles'

interface Props {
  classes?: {
    avatar?: string
    button?: string
  }
}

const AppMenu: React.FC<Props> = ({ classes: forwardedClasses }) => {
  const classes = useStyles()
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const [open, setOpen] = React.useState(false)

  const openShiftPlanner = useCallback(() => {
    window.open(window.env.REACT_APP_SHIFT_PLANNER, '_self')
  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
        className={clsx(forwardedClasses?.button, classes.userButton)}
      >
        <MenuGridIcon className={classes.menuIcon} />
      </Button>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={classes.menu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.menuList} autoFocusItem={open} id="menu-list-grow">
                  <MenuItem className={classes.menuItemActive}>
                    <span>Calmaster</span> <CircleOk className={classes.okIcon} />
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={openShiftPlanner}>
                    Shift planner
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default AppMenu
