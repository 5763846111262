import { colors } from '@dentalux/ui-library-core'

import { makeStyles, Theme } from '@material-ui/core'

import { listItemClasses, menuItemClasses } from '@mui/material'

export const useStyles = makeStyles((theme: Theme) => ({
  patientName: {
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.grey[800],
    marginRight: '16px',
  },
  patientDetails: {
    fontWeight: 400,
    fontFamily: 'Lato',
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.grey[600],
    marginRight: '16px',
  },
  patientNameHighlighted: {
    color: colors.aqua[800],
  },
  menuItem: {
    padding: '10px 16px',
    background: theme.palette.common.white,

    [`&:hover, &.${menuItemClasses.focusVisible}`]: {
      background: colors.aqua[50],
    },

    [`&.${listItemClasses.divider}`]: {
      borderColor: colors.grey[200],
    },
  },
  paper: {
    '&&': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& ul': {
      padding: 0,
    },
  },
}))
