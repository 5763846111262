import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import '../../setupEnv'
import { format } from 'date-fns'

import { NonWorkingTime, WorkingTime } from '../../@types/Shift'
import parseArrayQueryParam from '../../helpers/parseArrayQueryParam'
import { addErrorInterceptors } from '../interceptors'

export const shiftlyHttp = axios.create({
  baseURL: window.env.REACT_APP_SHIFTLY_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(shiftlyHttp)
addErrorInterceptors(shiftlyHttp)

type Params = {
  clinicReferenceId: string
  employeeReferenceId: string[]
  start: string
  end: string
}

export const getNonWorkingTimes = async (params: Params, signal?: AbortSignal): Promise<NonWorkingTime[]> => {
  if (!params.employeeReferenceId.length) {
    // To avoid the fetch w/o the userReferenceIds as this will lead to errors after endpoint contract change
    return []
  }

  const start = format(new Date(params.start), 'yyyy-MM-dd')
  const end = format(new Date(params.end), 'yyyy-MM-dd')

  const { data } = await shiftlyHttp.get('/api/non-working-times', {
    signal,
    params: {
      ...params,
      start,
      end,
      employeeReferenceId: parseArrayQueryParam(params.employeeReferenceId),
    },
  })

  return data
}

export const getWorkingTimes = async (params: Params, signal?: AbortSignal): Promise<WorkingTime[]> => {
  if (!params.employeeReferenceId.length) {
    return []
  }

  const start = format(new Date(params.start), 'yyyy-MM-dd')
  const end = format(new Date(params.end), 'yyyy-MM-dd')

  const { data } = await shiftlyHttp.get('/api/working-times', {
    signal,
    params: {
      ...params,
      start,
      end,
      employeeReferenceId: parseArrayQueryParam(params.employeeReferenceId),
    },
  })

  return data
}
