import { Employee, EmployeeRole } from '@dentalux/common'

import { CalmasterPractitioner, PractitionerType, Role } from '../@types/Practitioner'

export const emptyPractitioner: CalmasterPractitioner = {
  lastName: '',
  firstName: '',
  referenceId: '',
  userName: '',
}

export const emptyPractitioners: CalmasterPractitioner[] = [
  { ...emptyPractitioner, referenceId: '1', practitionerType: PractitionerType.DOCTOR },
  { ...emptyPractitioner, referenceId: '2', practitionerType: PractitionerType.DOCTOR },
  { ...emptyPractitioner, referenceId: '3', practitionerType: PractitionerType.DOCTOR },
  { ...emptyPractitioner, referenceId: '4', practitionerType: PractitionerType.EXPERT },
  { ...emptyPractitioner, referenceId: '5', practitionerType: PractitionerType.EXPERT },
  { ...emptyPractitioner, referenceId: '6', practitionerType: PractitionerType.EXPERT },
]

const ROLES_TO_FILTER = [EmployeeRole.DENTIST, EmployeeRole.HYGIENIST]

export const filteredPractitioners = (practitioners: Employee[] = []) =>
  practitioners.filter((practitioner) =>
    practitioner.positions?.some((position) => ROLES_TO_FILTER.includes(position.role))
  )

/**
 * Resizes the avatars of an array of practitioners.
 * @param {CalmasterPractitioner[]} practitioners - An array of practitioners to resize avatars for.
 * @returns {CalmasterPractitioner[]} An array of practitioners with resized avatars.
 */
export const resizePractitionerAvatars = (practitioners: CalmasterPractitioner[] = []): CalmasterPractitioner[] => {
  const resizeAvatar = ({ imageUrl, ...rest }: CalmasterPractitioner): CalmasterPractitioner => ({
    imageUrl: imageUrl?.includes('ucarecdn') ? `${imageUrl}-/resize/32x/` : imageUrl,
    ...rest,
  })

  return practitioners.map(resizeAvatar)
}
