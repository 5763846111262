import React, { useState, forwardRef, useCallback, ReactNode, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check'
import CloseSharpIcon from '@material-ui/icons/CloseSharp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FileCopyIcon from '@material-ui/icons/FilterNone'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'

import copy from 'copy-to-clipboard'
import { SnackbarContent, SnackbarKey, SnackbarMessage, VariantType } from 'notistack'

import { Field } from '../../@types/ServerError'

import useStyles from './Snackbar.styles'

export interface SnacbarProps {
  message: string | ReactNode
  details?: SnackbarMessage
  variant?: VariantType
  sentryId?: string
  status?: number
  id: SnackbarKey
  onClose?: (id: SnackbarKey) => void
  fields?: Array<Field>
}

const Snackbar = forwardRef<HTMLDivElement, SnacbarProps>(
  ({ message, details, variant = 'info', sentryId, id, status, onClose, fields }, ref) => {
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles({ variant, expanded })
    const { t } = useTranslation()

    const Icon = useMemo(() => {
      switch (variant) {
        case 'error':
          return CancelIcon
        case 'success':
          return CheckIcon
        case 'warning':
          return WarningIcon
        case 'info':
          return InfoIcon
        default:
          return null
      }
    }, [variant])

    const handleExpandClick = useCallback(() => {
      setExpanded((previousExpanded) => !previousExpanded)
    }, [])

    const handleCopyButtonClick = useCallback(() => {
      copy(sentryId ?? '')
    }, [sentryId])

    const handleClose = useCallback(() => onClose?.(id), [id, onClose])

    const showExpandArrow = !!details || !!sentryId
    const isDefault = variant === 'default'

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        {!isDefault && (
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseSharpIcon />
          </IconButton>
        )}
        <Card className={classes.card}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <div className={classes.cardWrapper}>
              {Icon && <Icon className={classes.icon} />}
              <Typography variant="subtitle2">{message}</Typography>
            </div>

            {showExpandArrow && (
              <div className={classes.icons}>
                <IconButton aria-label="Show more" className={classes.expand} onClick={handleExpandClick}>
                  <ExpandMoreIcon />
                </IconButton>
              </div>
            )}
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Paper className={classes.collapse}>
              {status && (
                <Box>
                  <Typography variant="subtitle2">
                    {status} {t('errors.error')}
                  </Typography>
                </Box>
              )}

              {!fields?.length && (
                <Typography variant="subtitle2" className={classes.text}>
                  {details}
                </Typography>
              )}

              {!!fields?.length && (
                <ul>
                  {fields.map(({ field, message }) => (
                    <li key={field}>{`${field} ${message}`}</li>
                  ))}
                </ul>
              )}

              {!!sentryId && (
                <>
                  <Box mt={1}>
                    <Typography className={classes.text} variant="subtitle2">
                      {t('errors.copy_link')}:
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Typography className={classes.text} variant="subtitle2">
                      {sentryId}
                    </Typography>
                    <Button className={classes.copyButton} startIcon={<FileCopyIcon />} onClick={handleCopyButtonClick}>
                      {t('errors.copy')}
                    </Button>
                  </Box>
                </>
              )}
            </Paper>
          </Collapse>
        </Card>
      </SnackbarContent>
    )
  }
)

export default Snackbar
