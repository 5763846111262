import { makeStyles, darken } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      width: 314,
      height: 40,
      backgroundColor: '#555454',
      marginLeft: theme.spacing(2),
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2, 2, 0, 4),
      opacity: 1,
      transition: 'all 0.1s ease-out, width 0.2s',
      '&:hover': {
        height: 60,
      },
      '&$hide': {
        padding: 0,
        height: 0,
        opacity: 0,
        width: 0,
        margin: 0,
      },
    },
    details: {
      flexGrow: 2,
      overflow: 'hidden',
    },
    name: {
      color: theme.palette.common.white,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    actions: {
      display: 'flex',
      width: 'fit-content',
    },
    action: {
      display: 'inline-flex',
      cursor: 'pointer',
      width: 24,
      height: 24,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      '&:hover': {
        '& svg path': {
          fill: darken(theme.palette.common.white, 0.25),
        },
      },
    },
    icon: {},
    hide: {},
  }),
  { name: 'MinimizedPatient' }
)
