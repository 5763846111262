import React, { PropsWithChildren, useContext } from 'react'

import { QueryObserverResult } from '@tanstack/react-query'

import { CalmasterAppointment } from '../@types/Appointment'
import { mockQueryLoadingResult } from '../helpers/reactQuery'
import useAppointments from '../hooks/useAppointments'
import useAppointmentsSubscription from '../subscriptions/useAppointmentsSubscription'

const initialState = mockQueryLoadingResult([])

export const Context = React.createContext<QueryObserverResult<CalmasterAppointment[], any>>(initialState)

const AppointmentsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { appointments, queryKey } = useAppointments({ debounce: 0 })

  useAppointmentsSubscription({
    queryKey,
    query: appointments,
  })

  return <Context.Provider value={appointments}>{children}</Context.Provider>
}

export const useAppointmentsContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useAppointmentsContext must be used within AppointmentsProvider.')
  }

  return context
}

export default AppointmentsProvider
