import React from 'react'

import { useTranslation } from 'react-i18next'

import { ChemistryInfo } from '@dentalux/ui-library-core'

import { Button, Popover, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PersonIcon from '@material-ui/icons/Person'

import { CorePatient } from '../../../../@types/Patient'
import { BlurredText } from '../../../BlurredText'

import useStyles from './Dropdown.styles'

type Props = {
  patients: CorePatient[]
  onPatientDetails?: (patientReferenceId: string) => void
}

const Dropdown: React.FC<Props> = ({ patients, onPatientDetails }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePatientClick = (referenceId: string) => () => onPatientDetails?.(referenceId)

  return (
    <>
      <Button className={classes.menuTrigger} disableFocusRipple disableRipple disableTouchRipple onClick={handleOpen}>
        <span>{t('patients_information')}</span>
        <ExpandMoreIcon className={classes.expandMoreIcon} />
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.menu,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {patients?.map((patient) => (
          <Button
            disableTouchRipple
            className={classes.menuItem}
            key={`calling-patient-match-${patient.referenceId}`}
            value={patient.referenceId}
            onClick={handlePatientClick(patient.referenceId)}
          >
            <PersonIcon className={classes.personalIcon} />
            <ChemistryInfo
              rating={patient.chemistry.category}
              reason={patient.chemistry.reason}
              patientNameComponent={
                <Typography className={classes.name}>
                  <BlurredText>
                    {patient.firstName} {patient.lastName}
                  </BlurredText>
                </Typography>
              }
            />
          </Button>
        ))}
      </Popover>
    </>
  )
}

export default Dropdown
