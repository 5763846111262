import { ServerErrorTranslations } from '../../@types/ServerError'

export default {
  cancellation: {
    post: 'appointment_cancel_error',
  },
  'appointment-bundles': {
    delete: 'appointment_cancel_error',
  },
  appointments: {
    delete: 'appointment_delete_error',
    put: 'appointment_edit.snackbars.error',
  },
  patients: {
    path: 'patient_edit.snackbars.error',
    put: 'patient_edit.snackbars.error',
  },
} as ServerErrorTranslations
