import React, { createContext, useContext, useReducer, ReactNode } from 'react'

import { MessageType } from '../@types/Conversation'

enum actionTypes {
  startCreateConversation = 'startCreateConversation',
  resetCreateConveration = 'resetCreateConveration',
  persistCreateConversationMessage = 'persistCreateConversationMessage',
}

type Action = { type: actionTypes; payload: any }

export type State = {
  firstName: string
  lastName: string
  contactPhoneNumber: string
  type: MessageType
  content: string
}

type Dispatch = (action: Action) => void

const CreateConversationContext = createContext<State | undefined>(undefined)

const CreateConversationDispatch = createContext<Dispatch | undefined>(undefined)

type Props = {
  children: ReactNode
}

const initialState = {
  firstName: '',
  lastName: '',
  contactPhoneNumber: '',
  type: MessageType.SMS,
  content: '',
}

const createConversationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case actionTypes.startCreateConversation:
      return { ...state, ...action.payload }
    case actionTypes.persistCreateConversationMessage:
      return { ...state, content: action.payload }
    case actionTypes.resetCreateConveration:
      return initialState
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const CreateConversationProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(createConversationReducer, { ...initialState })

  return (
    <CreateConversationContext.Provider value={state}>
      <CreateConversationDispatch.Provider value={dispatch}>{children}</CreateConversationDispatch.Provider>
    </CreateConversationContext.Provider>
  )
}

export const useCreateConversationState = () => {
  const context = useContext(CreateConversationContext)

  if (context === undefined) {
    throw new Error('useCreateConversationState must be used within a CreateConversationProvider')
  }

  return context
}

export const useCreateConversationDispatch = () => {
  const context = useContext(CreateConversationDispatch)

  if (context === undefined) {
    throw new Error('useCreateConversationDispatch must be used within a CreateConversationProvider')
  }

  return context
}

export const startCreateConversation = (payload: any): Action => ({
  type: actionTypes.startCreateConversation,
  payload,
})

export const resetCreateConveration = (): Action => ({
  type: actionTypes.resetCreateConveration,
  payload: {},
})

export const persistCreateConversationMessage = (payload: string): Action => ({
  type: actionTypes.persistCreateConversationMessage,
  payload,
})
