import React, { useState, useContext } from 'react'

import moment, { Moment } from 'moment'

import { UseState } from '../@types/Helpers'

const initialState = moment()

const Context = React.createContext<UseState<Moment>>([initialState, () => {}])

const DrawerCalendarStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // `currentActiveStartDate` tells to the DrawerCalendar which month should be displayed
  const [currentActiveStartDate, setCurrentActiveStartDate] = useState<Moment>(initialState)

  return <Context.Provider value={[currentActiveStartDate, setCurrentActiveStartDate]}>{children}</Context.Provider>
}

export const useDrawerCalendarStateContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useDrawerCalendarStateContext must be used within DrawerCalendarStateProvider')
  }

  return context
}

export default DrawerCalendarStateProvider
