import React, { useState, useContext, useEffect, useMemo, PropsWithChildren, Dispatch, SetStateAction } from 'react'

import { useParams } from 'react-router-dom'

import { QueryObserverResult } from '@tanstack/react-query'

import { Clinic } from '../@types/Clinic'
import { STAGING_SOLUTIO_ID } from '../helpers/constants'
import useClinics from '../hooks/useClinics'

export interface CurrentClinicContextProps {
  selectedClinic?: Clinic
  setSelectedClinic: Dispatch<SetStateAction<Clinic | undefined>>
  clinics: Array<Clinic>
  queryDetails?: Omit<QueryObserverResult<Clinic[]>, 'data'>
}

export const Context = React.createContext<CurrentClinicContextProps>({
  selectedClinic: undefined,
  setSelectedClinic: () => {},
  clinics: [],
})

const CurrentClinicProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [selectedClinic, setSelectedClinic] = useState<Clinic | undefined>()
  const { clinicReferenceId } = useParams<{ clinicReferenceId: string }>()

  const [clinics, queryDetails] = useClinics({
    onSuccess: (data) => {
      if (selectedClinic || data.length === 0) return

      if (!clinicReferenceId) {
        const preferredStagingClinic = data.find((clinic) => clinic.referenceId === STAGING_SOLUTIO_ID)

        if (window.env.REACT_APP_ENV !== 'production' && preferredStagingClinic) {
          return setSelectedClinic(preferredStagingClinic)
        }

        setSelectedClinic(data[0])
      }
    },
  })

  const value = useMemo(
    () => ({ selectedClinic, setSelectedClinic, clinics, queryDetails }),
    [selectedClinic, clinics, queryDetails]
  )

  useEffect(() => {
    if (clinics && clinicReferenceId && !selectedClinic) {
      const currentClinic = clinics.find((clinic) => clinic.referenceId === clinicReferenceId)

      setSelectedClinic(currentClinic)
    }
  }, [clinicReferenceId, clinics, selectedClinic])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useCurrentClinicProvider = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useCurrentClinicProvider must be used within a CurrentClinicProvider')
  }

  return context
}

export default CurrentClinicProvider
