export enum AnalyticsAction {
  click = 'click',
  recollect_documents = 'recollect_documents',
}

export enum AnalyticsLabel {
  create_appointment_btn = 'create_appointment_btn',
  search_bar = 'search_bar',
  create_appointment_slot = 'create_appointment_slot',
  patients_tab = 'patients_tab',
  sms_tab = 'sms_tab',
  calls_tab = 'calls_tab',
  day_view = 'day_view',
  week_view = 'week_view',
  month_view = 'month_view',
  patient_waiting_room = 'patient_waiting_room',
  appointment_patient = 'appointment_patient',
  documents_send_sms = 'documents_send_sms',
  documents_code = 'documents_code',
  edit_patient_data = 'edit_patient_data',
  send_sms = 'send_sms',
  call_patient = 'call_patient',
  edit_appointment = 'edit_appointment',
  cancel_appointment = 'cancel_appointment',
  create_appointment = 'create_appointment',
  minimize_window = 'minimize_window',
  maximize_window = 'maximize_window',
  tos = 'tos',
  anamnesis = 'anamnesis',
  covid19 = 'covid19',
  factoring = 'factoring',
  document_recollection = 'document_recollection',
  caller_detection_patient_information = 'caller_detection_patient_information',
  caller_detection_new_appointment = 'caller_detection_new_appointment',
  new_patient = 'new_patient',
  treatment_selected = 'treatment_selected',
  practitioner_selected = 'practitioner_selected',
  new_appointment_saved = 'new_appointment_saved',
  patient_callback = 'patient_callback',
  schedule_open_appointment = 'schedule_open_appointment',
}

export enum AnalyticsCategory {
  calmaster = 'calmaster',
  patient_detail_view = 'patient_detail_view',
}

export type Event = {
  event: string
  label: string
  category: string
  action: AnalyticsAction
}
