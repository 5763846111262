import { makeStyles } from '@material-ui/core'

export default makeStyles(
  {
    menuTrigger: {
      cursor: 'pointer',
      display: 'flex',
      fontWeight: 700,
      fontSize: 10,
      lineHeight: '12px',
      fontFamily: 'Lato',
      padding: 0,
    },

    menu: {
      display: 'flex',
      boxShadow: '0px -0.5px 0.5px rgba(0, 0, 0, 0.1), 0px 0.5px 1px rgba(0, 0, 0, 0.35)',
      borderRadius: 6,
      margin: '8px 0px 0px 6px',
      flexDirection: 'column',
    },

    menuItem: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      cursor: 'pointer',
      justifyContent: 'flex-start',
    },

    personalIcon: {
      height: 10.67,
      fill: '#424242',
    },

    name: {
      display: 'flex',
      fontWeight: 700,
      fontSize: 10,
      lineHeight: '12px',
      fontFamily: 'Lato',
    },

    expandMoreIcon: {
      height: 12,
    },
  },
  { name: 'Dropdown' }
)
