import * as Sentry from '@sentry/react'
import { AxiosInstance } from 'axios'
import truncate from 'lodash/truncate'

import getEnvironmentName from '../helpers/getEnvironmentName'
import globalTranslations from '../i18n/errors/global'
import { addSnackbarMessage } from '../providers/SnackbarProvider'

const getTranslationKey = (translations: any, url?: string, method?: string): string | undefined => {
  if (!translations || !url || !method) return

  const translationKey = Object.keys(translations).find((key) => url.includes(key))

  if (!translationKey) return

  return translations[translationKey][method]
}

const BLACKLIST_STATUSES = [401]

export const addErrorInterceptors = (http: AxiosInstance, translations?: any) =>
  http.interceptors.response.use(
    (response) => response,
    async (error) => {
      const environment = getEnvironmentName()
      const MESSAGE_CHARS_LIMIT = 500

      const traceId = error?.response?.headers?.['x-b3-traceid']
      const status = (error?.response?.status as number) ?? 500
      const isPlainText = error?.response?.headers?.['content-type']?.includes('plain')
      const isHtml = error?.response?.headers?.['content-type']?.includes('html')
      const hasErrorCode = error?.response?.data?.code ?? error?.response?.data?.errorCode
      const isCancelled = error?.message === 'canceled'
      let sentryId

      if (BLACKLIST_STATUSES.includes(status) || isCancelled || hasErrorCode) return Promise.reject(error)

      // Don't show error details if it's an HTML response
      let responseError = isHtml ? '' : error?.response?.data

      // Handle multiple keys in error object responses.
      const errorResponseKeys = ['message', 'error']

      const globalErrorMessage = globalTranslations[status]

      if (!isPlainText && !isHtml && responseError) {
        const errorKeyToUse = Object.keys(responseError).find((key) => errorResponseKeys.includes(key))

        if (errorKeyToUse) {
          responseError = responseError[errorKeyToUse]
        }
      }

      if (environment !== 'development') {
        Sentry.withScope((scope) => {
          const user = {
            ...scope.getUser(),
            ...(traceId && {
              traceId,
            }),
          }

          const context = traceId && {
            tags: {
              traceId,
            },
          }

          scope.setUser(user)
          sentryId = Sentry.captureException(new Error(`${status}, ${responseError}`), context)
        })
      } else {
        console.log('Sentry Error Log has been submitted', status, responseError)
      }

      const message = `${truncate(responseError, { length: MESSAGE_CHARS_LIMIT }) ?? ''}`
      const customMessage = getTranslationKey(
        translations,
        error?.response?.config?.url,
        error?.response?.config?.method
      )

      const fields = error?.response?.data?.fields

      addSnackbarMessage({
        variant: 'error',
        fields,
        message,
        sentryId,
        customMessage: globalErrorMessage ?? customMessage,
        status,
      })
      return Promise.reject(error)
    }
  )
