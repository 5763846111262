import React, { useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { colors } from '@dentalux/ui-library-core'

import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton } from '@mui/material'
import { SnackbarContent, CustomContentProps, useSnackbar, SnackbarKey } from 'notistack'

import * as S from './UndoNotiVariant.styles'

declare module 'notistack' {
  interface VariantOverrides {
    // adds `undoNotiVariantProps` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    undoNotiVariant: {
      onUndo: (id: SnackbarKey) => void
    }
  }
}

interface UndoNotiVariantProps extends CustomContentProps {
  onUndo: (id: SnackbarKey) => void
}

export const UndoNotiVariant = React.forwardRef<HTMLDivElement, UndoNotiVariantProps>(
  ({ id, message, onUndo, ...other }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    const handleOutsideMouseDown = () => {
      handleDismiss()
    }

    const handleNotiVariantWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // stop propagating event up in order to avoid triggering document click handler
      e.stopPropagation()
    }

    useEffect(() => {
      // as per requirements we need to close undo noti when clicking on other elements
      document.addEventListener('mousedown', handleOutsideMouseDown)

      return () => {
        document.removeEventListener('mousedown', handleOutsideMouseDown)
      }
    })

    return (
      <div onMouseDown={handleNotiVariantWrapperClick}>
        <SnackbarContent ref={ref} role="alert" {...other}>
          <S.UndoNotiVariant>
            {message}
            <div>
              <Button
                variant="borderless"
                onClick={() => {
                  onUndo(id)
                }}
                sx={{
                  color: colors.aqua[75],
                }}
              >
                {t('undo')}
              </Button>
              <IconButton onClick={() => handleDismiss()}>
                <CloseIcon
                  sx={{
                    color: colors.grey[50],
                  }}
                />
              </IconButton>
            </div>
          </S.UndoNotiVariant>
        </SnackbarContent>
      </div>
    )
  }
)
