import { CalmasterPractitioner } from '../@types/Practitioner'
import { eventColors } from '../theme/colors'

import sortPractitionersByType from './sortPractitionersByType'

const getPractitionersColors = (practitioners: CalmasterPractitioner[]) => {
  // Sorting the practitioners by type so practitioners with the same type has
  // lower chances to have same colors.
  const practitionersSorted = sortPractitionersByType(practitioners)

  return practitionersSorted.reduce((colors, practitioner, index) => {
    return {
      ...colors,
      [practitioner.referenceId]: eventColors[index % eventColors.length],
    }
  }, {})
}

export default getPractitionersColors
