export enum ClinicType {
  DENTIST = 'DENTIST',
  GP = 'GP',
  GYNAECOLOGIST = 'GYNAECOLOGIST',
  UROLOGIST = 'UROLOGIST',
  UNKNOWN = 'UNKNOWN',
}

export interface Clinic {
  referenceId: string
  name: string
  featureOperatorEnabled: boolean
  featureTwoWayMessagingEnabled: boolean
  featureAnaEnabled: boolean
  featureTodoEnabled: boolean
  type?: ClinicType
}
