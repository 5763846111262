import { CalmasterAppointment } from './Appointment'
import { Colors } from './Colors'
import { CalmasterPractitioner } from './Practitioner'

export enum VIEW_TYPE {
  day = 'day',
  week = 'week',
}

export interface AppointmentEventResource {
  appointment: CalmasterAppointment
  color: { dark: string; light: string }
}

export interface MonthEventResource {
  colors: Colors
  subEvents: CalendarEvent<AppointmentEventResource | WorkExclusionEventResource>[]
}

export interface WorkExclusionEventResource {
  practitioner?: CalmasterPractitioner
}

export enum CalendarEventType {
  WorkExclusion = 'WorkExclusion',
  Appointment = 'Appointment',
  FreeSlot = 'FreeSlot',
  Booking = 'Booking',
  Month = 'Month',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CalendarEvent<T = any> {
  id?: string
  resourceId?: string
  resource?: T
  start: Date
  end: Date
  type: CalendarEventType
  style?: {
    zIndex: number
    xOffset: number // in percentage
    width: number // in percentage
    height: number // in percentage
  }
}

export type AppointmentEvent = CalendarEvent<AppointmentEventResource>

export type WorkExclusionEvent = CalendarEvent<WorkExclusionEventResource>
