import { useMemo } from 'react'

import { ClinicType } from '../@types/Clinic'
import { useCurrentClinicProvider as useCurrentClinic } from '../providers/CurrentClinicProvider'

export interface ClinicTypesFilter {
  canRender: boolean
  clinicTypes: ClinicType[] | undefined
}

/**
 * @param {ClinicType[]} [clinicTypes] - `ClinicType` array to check if the selected clinic type is included in the list
 */
const useRenderForCurrentClinic = (clinicTypes?: ClinicType[]): ClinicTypesFilter => {
  const { selectedClinic } = useCurrentClinic()

  const canRender = useMemo(() => {
    if (!clinicTypes) return true
    return selectedClinic?.type ? clinicTypes.includes(selectedClinic?.type) : false
  }, [selectedClinic, clinicTypes])

  return {
    canRender,
    clinicTypes,
  }
}

export default useRenderForCurrentClinic
