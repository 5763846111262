import { makeStyles } from '@material-ui/core'
import { darken } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    zIndex: 10,
    width: 'calc(100% - 364px)',
    border: 0,
    top: 64,

    '&$fullWidth': {
      width: '100%',
    },

    [theme.breakpoints.down('lg')]: {
      zIndex: 1199,
    },
  },

  calendarTypeSelect: {
    width: 84,
    height: 32,
    marginRight: theme.spacing(1.5),

    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },

  statisticSwitch: {
    marginRight: 16,
    marginLeft: 12,
  },

  viewTypeSelect: {
    width: 100,
    paddingTop: 0,
    paddingBottom: 0,

    '& $shortcut': {
      display: 'none',
    },
  },

  shortcut: {},

  viewTypeSelectMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 0 100%',
  },

  appName: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '24px',
    marginRight: 20,
  },

  clinicDropdown: {
    marginRight: 16,
    height: 32,
    flexDirection: 'row',
  },

  rightItems: {
    display: 'flex',
    alignItems: 'center',
  },

  patientSearch: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  },

  button: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#4F4F4F',
    borderRadius: 6,
  },
  buttonSave: {
    backgroundColor: '#24A1A1',
    color: '#fff',
    marginLeft: 15,
    padding: theme.spacing(1, 2, 1, 1.35),
    fontSize: 13,
    width: 'fit-content',
    fontWeight: 500,
    wordBreak: 'keep-all',
    '&:hover': {
      backgroundColor: darken('#24A1A1', 0.2),
    },
  },

  practitioners: {},

  back: {
    marginRight: 8,
    padding: 0,
  },
  search: {
    width: '100%',
  },
  backToCalendarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerWrapper: {
    height: 64,
    padding: theme.spacing(0, 2),
    background: '#fff',
    width: '100%',
    display: 'grid',
    gridTemplateAreas: '"first second third five fourth"',
    gridTemplateColumns: 'max-content max-content auto max-content max-content',

    [theme.breakpoints.down('xs')]: {
      marginTop: 64,
    },
  },
  headerSectionOne: {
    gridArea: 'first',
    display: 'flex',
    alignItems: 'center',
    '&$searchFocused $gridAreaInnerContent': {
      display: 'none',
    },
  },
  headerSectionTwo: {
    gridArea: 'second',
    display: 'flex',
    alignItems: 'center',
    '& > div:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  headerSectionThree: {
    gridArea: 'third',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '&$searchFocused': {
      '& $patientSearch': {
        width: '100%',
      },

      '& $canceledAppointmentsToggleWrap': {
        display: 'none',
      },
    },
  },
  headerSectionFour: {
    gridArea: 'fourth',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 10,
  },
  headerSectionFive: {
    gridArea: 'five',
    display: 'flex',
    alignItems: 'center',
  },
  shiftContent: {},
  searchFocused: {},
  gridAreaInnerContent: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  appointmentCreateIcon: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  user: {
    marginLeft: theme.spacing(1),
  },
  canceledAppointmentsToggleWrap: {
    marginRight: theme.spacing(2),
  },
  canceledAppointmentsToggleLabelWrap: {
    margin: '0 8px',
  },
  canceledAppointmentsToggleLabel: {
    fontSize: 14,
    textAlign: 'right',
    marginRight: '16px',
  },
  buttonWrapper: {
    marginRight: '16px',
  },
  fullWidth: {},
}))
