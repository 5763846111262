import React, { lazy, useCallback } from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'

import moment, { unitOfTime, Moment } from 'moment'

import { useCalendarSettingsContext } from '../../providers/CalendarSettingsProvider'
import { useDrawerCalendarStateContext } from '../../providers/DrawerCalendarStateProvider'
import SuspendedComponent from '../SuspendedComponent'

import AppBarDesktop, { Props as AppBarProps } from './components/Desktop'

const AppBarMobile = lazy(() => import('./AppBar.mobile'))

const AppBar = ({ onToggleDrawer, shiftContent }: AppBarProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { currentDate, setCurrentDate, calendarViewType } = useCalendarSettingsContext()
  const [, setCurrentActiveStartDate] = useDrawerCalendarStateContext()

  const handleDateChange = useCallback(
    (date: Moment) => {
      const newDate = date.startOf(calendarViewType as unitOfTime.StartOf)

      setCurrentDate(moment(newDate))
      setCurrentActiveStartDate(newDate.startOf('month'))
    },
    [setCurrentDate, setCurrentActiveStartDate, calendarViewType]
  )

  if (isMobile) {
    return (
      <SuspendedComponent>
        <AppBarMobile
          viewType={calendarViewType}
          currentDate={currentDate}
          onDateChange={handleDateChange}
          onToggleDrawer={onToggleDrawer}
        />
      </SuspendedComponent>
    )
  }

  return <AppBarDesktop onToggleDrawer={onToggleDrawer} shiftContent={shiftContent} />
}

export default AppBar
