// request to be used as mocks
import axios from 'axios'

export const mockedBaseURL = window.env.REACT_APP_CALMASTER_ENDPOINT

export const http = axios.create({
  baseURL: mockedBaseURL,
  withCredentials: true,
})

export const get401 = async () => {
  const { data } = await http.get(`/invalid-token`)

  return data
}
