import { useMemo } from 'react'

import { IconButton } from '@mui/material'

import { ReactComponent as ObfuscationInvisibleIcon } from '../../../../assets/icons/obfuscation-invisible.svg'
import { ReactComponent as ObfuscationVisibleIcon } from '../../../../assets/icons/obfuscation-visible.svg'
import { useObfuscationProvider } from '../../../../providers/ObfuscationProvider'

export const ObfuscationButton = () => {
  const { isObfuscated, toggleObfuscation } = useObfuscationProvider()

  const Icon = useMemo(() => (isObfuscated ? ObfuscationInvisibleIcon : ObfuscationVisibleIcon), [isObfuscated])

  const dataTestId = `obfuscation-${isObfuscated ? 'on' : 'off'}`

  return (
    <IconButton onClick={toggleObfuscation} data-testid={dataTestId}>
      <Icon />
    </IconButton>
  )
}
