import React, { useContext, useState, useEffect } from 'react'

import { Colors } from '../@types/Colors'
import { CalmasterPractitioner } from '../@types/Practitioner'
import getPractitionersColors from '../helpers/getPractitionersColors'

import { usePractitionersContext } from './PractitionersProvider'

export const Context = React.createContext<Colors>({})

const ColorsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [colors, setColors] = useState<Colors>({})
  const { data: practitioners } = usePractitionersContext()

  useEffect(() => {
    if (practitioners?.length > 0) {
      // TODO: Update types
      const newColors = getPractitionersColors((practitioners as unknown as CalmasterPractitioner[]) || [])

      setColors((oldColors) => ({ ...oldColors, ...newColors }))
    }
  }, [practitioners])

  return <Context.Provider value={colors}>{children}</Context.Provider>
}

export const useColorsContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useColorsContext must be used within a ColorsProvider.')
  }

  return context
}

export default ColorsProvider
