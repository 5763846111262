enum AppointmentCancellation {
  NO_SHOW = 'NO_SHOW',
  CANCELED_ON_TIME = 'CANCELED_ON_TIME',
  CANCELED_SHORT_TERM = 'CANCELED_SHORT_TERM',
  CANCELED = 'CANCELED',
  CANCELED_MOVED = 'CANCELED_MOVED',
  CANCELED_BY_PRACTICE = 'CANCELED_BY_PRACTICE',
}

export default AppointmentCancellation
