import { colors } from '@dentalux/ui-library-core'

import { styled } from '@mui/material/styles'

export const UndoNotiVariant = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.grey[800]};
  padding: 8px 14px;
  border-radius: 4px;
  color: ${colors.grey[50]};
  min-width: 344px;
`
