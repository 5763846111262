import React, { forwardRef, useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { ChemistryInfo } from '@dentalux/ui-library-core'

import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PhoneIcon from '@material-ui/icons/Phone'

import clsx from 'clsx'
import { SnackbarContent, useSnackbar } from 'notistack'

import { CallTask, CallTaskStates } from '../../../../@types/CallTask'
import { getPatientFullname } from '../../../../helpers/getPatientName'
import usePrevious from '../../../../hooks/usePrevious'
import { useFeatureFlags } from '../../../../providers/FeatureFlags'
import { BlurredText } from '../../../BlurredText'
import Dropdown from '../Dropdown/Dropdown'

import useStyles from './CallNotification.styles'

const CallNotification = forwardRef<
  HTMLDivElement,
  {
    id: string | number
    callTask: CallTask
    onPatientDetails?: (patientReferenceId: string) => void
    onNewAppointment?: (event: React.ChangeEvent<unknown> | React.MouseEvent) => void
  }
>(({ callTask, id, onPatientDetails, onNewAppointment }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const featureFlags = useFeatureFlags()
  const incomingCallsEnabled = featureFlags.incomingCalls

  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(`${id}_${callTask.currentState}`)
  }, [closeSnackbar, id, callTask.currentState])

  const prevReferenceId = usePrevious(callTask.currentState)
  const phoneNumber = callTask.caller?.phoneNumber
  const patients = callTask?.caller?.patients
  const patientName = patients?.length ? getPatientFullname(patients[0]) : undefined
  const patientRating = patients?.[0]?.chemistry
  const isRinging = callTask.currentState === CallTaskStates.Open_Ringing
  const isOngoing = callTask.currentState === CallTaskStates.Open_InProgress

  const phone = (
    <div className={classes.phoneContainer}>
      {callTask?.caller ? (
        <>
          <Typography className={classes.name}>
            {patientName ? (
              <ChemistryInfo
                rating={patientRating?.category}
                reason={patientRating?.reason}
                patientNameComponent={<BlurredText>{patientName}</BlurredText>}
              />
            ) : (
              phoneNumber
            )}
          </Typography>
          {!!patientName && <Typography className={classes.phone}>{phoneNumber}</Typography>}
        </>
      ) : (
        <div>
          <Typography className={classes.name}>{t('anonymous_caller')}</Typography>
        </div>
      )}
    </div>
  )

  const action = useMemo(() => {
    if (!patients?.length)
      return (
        <div
          className={clsx({
            [classes.pointer]: incomingCallsEnabled,
          })}
          onClick={onNewAppointment}
        >
          {`+ ${t('add_new_appointment_to_patient_title')}`}
        </div>
      )
    if (patients?.length === 1 || !incomingCallsEnabled)
      return (
        <span
          className={clsx(classes.patientInformation, {
            [classes.pointer]: incomingCallsEnabled,
          })}
          onClick={() => onPatientDetails?.(patients[0].referenceId)}
        >
          {t('patients_information')}
        </span>
      )

    return <Dropdown patients={callTask?.caller?.patients ?? []} onPatientDetails={onPatientDetails} />
  }, [
    callTask?.caller?.patients,
    callTask?.currentState,
    classes.ongoingBorder,
    classes.pointer,
    classes.ringingBorder,
    incomingCallsEnabled,
    isOngoing,
    isRinging,
    onNewAppointment,
    onPatientDetails,
    patients?.length,
    t,
  ])

  if (isOngoing && prevReferenceId === CallTaskStates.Open_Ringing) handleDismiss()

  return (
    <SnackbarContent ref={ref} className={classes.notification}>
      <IconButton className={classes.closeIcon} onClick={handleDismiss}>
        <CloseIcon />
      </IconButton>
      <div className={classes.iconAndTextContainer}>
        <div
          className={clsx(classes.iconContainer, {
            [classes.incomingBackground]: isRinging,
            [classes.ongoingBackground]: isOngoing,
          })}
        >
          <PhoneIcon
            className={clsx(classes.phoneIcon, {
              [classes.ringingAnimation]: isRinging,
            })}
          />
        </div>
        <div className={classes.textContainer}>
          <Typography
            className={clsx(classes.callingStatusText, {
              [classes.incomingCallColor]: isRinging,
              [classes.ongoingCallColor]: isOngoing,
            })}
          >
            {t(isRinging ? 'incoming_call' : 'ongoing_call')}
          </Typography>
          {phone}
        </div>
      </div>
      <div className={classes.actionContainer}>
        <div className={classes.action}>{action}</div>
      </div>
    </SnackbarContent>
  )
})

export default CallNotification
