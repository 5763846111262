import { colors } from '@dentalux/ui-library-core'

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appointmentButtonWrapper: {
    position: 'fixed',
    bottom: '12px',
    right: '64px',
    left: 'auto',
    zIndex: 1199,

    '&:hover': {
      zIndex: 1201,
    },
  },
  appointmentButton: {
    '&&&': {
      backgroundColor: colors.aqua[800],
      color: theme.palette.common.white,
      borderRadius: '16px',
      fontSize: '14px',
      padding: '16px 20px',
      textTransform: 'none',

      '&:hover': {
        backgroundColor: colors.aqua[900],
      },
    },
  },
}))
