const MIN_STRING_LENGTH_REQUIREMENT = 4
const MIN_NUMBER_LENGTH_REQUIREMENT = 1

const isValueNumber = (value: string): boolean => {
  if (value === '') return false // catch empty string casting to 0
  return !Number.isNaN(Number(value))
}

const getStringLengthRequirement = (value: string): number => {
  return isValueNumber(value) ? MIN_NUMBER_LENGTH_REQUIREMENT : MIN_STRING_LENGTH_REQUIREMENT
}

const doesSearchWordMeetLengthRequirement = (value: string) => value.length >= getStringLengthRequirement(value)

export {
  doesSearchWordMeetLengthRequirement,
  getStringLengthRequirement,
  MIN_STRING_LENGTH_REQUIREMENT,
  MIN_NUMBER_LENGTH_REQUIREMENT,
}
