import React, { useEffect, useState } from 'react'

import { CalendarEvent } from '../@types/CalendarEvent'

import { useCalendarSettingsContext } from './CalendarSettingsProvider'
import { usePractitionersContext } from './PractitionersProvider'

interface ContextProps {
  maxEventWidth: number
  maxEventHeight: number
  getEventDimensions: (event: CalendarEvent) => { width: number; height: number }
}

export const initialState: ContextProps = {
  maxEventHeight: 0,
  maxEventWidth: 0,
  getEventDimensions: () => ({ width: 0, height: 0 }),
}

export const Context = React.createContext<ContextProps>(initialState)

interface Props {
  children: React.ReactNode
}

export const useCalendarDimensionsContext = () => {
  const context = React.useContext(Context)

  if (context === undefined) {
    throw new Error('useCalendarDimensionsContext must be used within a CalendarDimensionsProvider')
  }

  return context
}

const minimumEventHeight = 40

const CalendarDimensionsProvider: React.FC<Props> = ({ children }) => {
  const { calendarViewType } = useCalendarSettingsContext()
  const [calendarDimensions, setCalendarDimensions] = useState({
    maxEventWidth: 0,
    maxEventHeight: 0,
  })

  const practitioners = usePractitionersContext()

  const getEventDimensions = (event: CalendarEvent) => {
    const height = event.style?.height
      ? (calendarDimensions.maxEventHeight * event.style?.height) / 100
      : minimumEventHeight

    return {
      width: event.style?.width ? (calendarDimensions.maxEventWidth * event.style?.width) / 100 : 0,
      height: height < minimumEventHeight ? minimumEventHeight : height,
    }
  }

  useEffect(() => {
    if (practitioners?.status === 'success') {
      const rect = window.document.querySelector('.rbc-day-slot.rbc-time-column')?.getBoundingClientRect()

      if (rect) {
        setCalendarDimensions({
          maxEventWidth: Math.floor(rect.width),
          maxEventHeight: Math.floor(rect.height),
        })
      }
    }
  }, [practitioners, calendarViewType])

  const value = {
    ...calendarDimensions,
    getEventDimensions,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default CalendarDimensionsProvider
