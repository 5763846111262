import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { useServerSentEvent } from '@dentalux/ui-library-core'

import { useQuery as useReactQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { startOfDay, subDays } from 'date-fns'

import { CallTask, CallTaskStates } from '../@types/CallTask'
import { ParamTypes } from '../@types/RouteParams'
import { ServerSentResourceType } from '../@types/ServerSentEvent'
import { insertIf, replaceElement } from '../helpers/array'
import prioritizeCalls from '../helpers/callManagement/prioritizeCalls'
import { getPatientFullname } from '../helpers/getPatientName'
import { minutesToMs } from '../helpers/time'
import withQueryParams from '../helpers/withQueryParams'
import { useCurrentClinicProvider } from '../providers/CurrentClinicProvider'
import api from '../services/api'
import { notify } from '../services/notifications'

type CallTaskWithActions = {
  tasks: CallTask[]
  isLoading: boolean
  onLoad: () => void
}

type CountWithActions = {
  count: number
  refetch: () => void
}

type State = {
  open: CallTaskWithActions & CountWithActions
  solved: CallTaskWithActions
}

type CallTasksProviderProps = { children: ReactNode }

const CallTasksContext = createContext<State | undefined>(undefined)

export const CallTasksProvider: React.FC<CallTasksProviderProps> = ({ children }) => {
  const { t } = useTranslation()
  const { selectedClinic } = useCurrentClinicProvider()
  const { sideNav } = useParams<ParamTypes>()

  const clinicReferenceId = useMemo(() => selectedClinic?.referenceId ?? '', [selectedClinic])
  const callsEnabled = !!selectedClinic?.featureOperatorEnabled
  const isCallsPageOpen = sideNav === 'calls'

  const [loadOpenCalls, setLoadOpenCalls] = useState(isCallsPageOpen && callsEnabled)
  const [loadSolvedCalls, setLoadSolvedCalls] = useState(false)
  const [openCalls, setOpenCalls] = useState<CallTask[]>([])
  const [solvedCalls, setSolvedCalls] = useState<CallTask[]>([])

  const hasClinic = Boolean(clinicReferenceId?.length)
  const enabled = callsEnabled && hasClinic
  const areOpenCallsEnabled = hasClinic && loadOpenCalls
  const areSolvedCallsEnabled = hasClinic && loadSolvedCalls
  /**
   * There is a case where we could receive update events for calls
   * older than 2 days. To handle that we will check and ignore,
   * update events for call tasks that have received their
   * "latestIncomingCallAt" for more than two days
   */
  const notOlderThanTimeStamp = useMemo(() => subDays(startOfDay(new Date()), 2), [])

  const handleLocalVariableSave = (data: CallTask[], saveCallback: (value: CallTask[]) => void) => {
    saveCallback(data ?? [])
  }

  const handleAddCallTaskNotification = useCallback(
    (updatedCallTask: CallTask) => {
      const exists = openCalls.find((callTask: CallTask) => callTask.referenceId === updatedCallTask.referenceId)

      //===
      // Notification for new missed call
      //===
      if (!exists) return notify(t('new_missed_calls_singular', { count: 1 }))

      const patients = updatedCallTask?.caller?.patients
      const icon = '/ringing.png'
      const anonymousCaller = !updatedCallTask?.caller && t('anonymous')
      const patientName = patients?.length && getPatientFullname(patients[0])
      const callerPhoneNumber = updatedCallTask?.caller?.phoneNumber
      const callerId = anonymousCaller || patientName || callerPhoneNumber

      updatedCallTask.currentState === CallTaskStates.Open_Ringing &&
        notify(`${t('incoming_call')} ${callerId}`, false, icon)
    },
    [openCalls, t]
  )

  const { data: openCallCount, ...countQueryDetails } = useReactQuery({
    queryKey: ['open-call-tasks-count', selectedClinic?.referenceId],
    queryFn: withQueryParams(api.operator.getOpenCallCount),
    enabled,
  })

  const { data: openCallTasks, ...openCallTasksDetails } = useReactQuery<CallTask[], AxiosError, CallTask[]>({
    queryKey: ['open-call-tasks', selectedClinic?.referenceId, notOlderThanTimeStamp],
    queryFn: withQueryParams(api.operator.getOpenCallTasks),
    enabled: areOpenCallsEnabled,
    refetchInterval: minutesToMs(30),
    keepPreviousData: true,
    onSuccess: (data) => handleLocalVariableSave(data, setOpenCalls),
  })

  const { data: solvedCallTasks, ...solvedCallTasksDetails } = useReactQuery<CallTask[], AxiosError, CallTask[]>({
    queryKey: ['solved-call-tasks', selectedClinic?.referenceId, notOlderThanTimeStamp],
    queryFn: withQueryParams(api.operator.getSolvedCallTasks),
    enabled: areSolvedCallsEnabled,
    refetchInterval: minutesToMs(30),
    keepPreviousData: true,
    onSuccess: (data) => handleLocalVariableSave(data, setSolvedCalls),
  })

  const refetchOpenCalls = useCallback(
    (updatedCallTask: CallTask) => {
      handleAddCallTaskNotification(updatedCallTask)

      setOpenCalls((previousCalls) => {
        const callToUpdate = previousCalls.find(
          (callTask: CallTask) => callTask.referenceId === updatedCallTask.referenceId
        )

        return [
          ...replaceElement(updatedCallTask, previousCalls, 'referenceId'),
          ...insertIf(!callToUpdate, [updatedCallTask]),
        ]
      })

      countQueryDetails.refetch()
    },
    [countQueryDetails, handleAddCallTaskNotification, setOpenCalls]
  )

  const refetchSolvedCalls = (updatedCallTask: CallTask) => {
    const shouldUpdateOpenCalls = openCalls.some(({ referenceId }) => referenceId === updatedCallTask.referenceId)

    if (shouldUpdateOpenCalls) {
      setOpenCalls((previousCalls) =>
        previousCalls.filter(({ referenceId }) => referenceId !== updatedCallTask.referenceId)
      )
    }

    countQueryDetails.refetch()
    solvedCallTasksDetails.refetch()
  }

  const deleteOpenCalls = (updatedCallTask?: CallTask) => {
    if (!updatedCallTask) return

    // Refetch call list if is enabled, otherwise update only local state
    if (areOpenCallsEnabled) {
      openCallTasksDetails.refetch()
    } else {
      setOpenCalls((previousCalls) =>
        previousCalls.filter(({ referenceId }) => referenceId !== updatedCallTask.referenceId)
      )
    }

    countQueryDetails.refetch()
  }

  const refetchCalls = (updatedCallTask?: CallTask) => {
    if (!updatedCallTask) return

    const solvedStates = [CallTaskStates.Done, CallTaskStates.Ignored]
    const isSolved = solvedStates.includes(updatedCallTask.currentState)
    const method = isSolved ? refetchSolvedCalls : refetchOpenCalls

    method(updatedCallTask)
  }

  useServerSentEvent(ServerSentResourceType.CALL_TASK, 'CREATED', refetchCalls)
  useServerSentEvent(ServerSentResourceType.CALL_TASK, 'UPDATED', refetchCalls)
  useServerSentEvent(ServerSentResourceType.CALL_TASK, 'DELETED', deleteOpenCalls)

  useEffect(() => {
    // reset default state after clinic change
    setOpenCalls([])
    setSolvedCalls([])
    setLoadSolvedCalls(false)
    setLoadOpenCalls(isCallsPageOpen && callsEnabled)
  }, [clinicReferenceId])

  const calls = useMemo(
    () => ({
      open: {
        tasks: prioritizeCalls(openCalls || []),
        isLoading: openCallTasksDetails.isInitialLoading,
        onLoad: () => setLoadOpenCalls(true),
        count: openCallCount || 0,
        refetch: countQueryDetails.refetch,
      },
      solved: {
        tasks: solvedCalls,
        isLoading: solvedCallTasksDetails.isInitialLoading,
        onLoad: () => setLoadSolvedCalls(true),
      },
    }),
    [
      countQueryDetails.refetch,
      openCallCount,
      openCallTasksDetails.isInitialLoading,
      openCalls,
      solvedCallTasksDetails.isInitialLoading,
      solvedCalls,
    ]
  )

  return <CallTasksContext.Provider value={calls}>{children}</CallTasksContext.Provider>
}

export const useCallTasks = (): State => {
  const context = useContext(CallTasksContext)

  if (context === undefined) {
    throw new Error('useCallTasks must be used within a CallTasksProvider')
  }

  return context
}
