import React from 'react'

import { makeStyles } from '@material-ui/core'

import clsx from 'clsx'

import { sideNavWidth } from './SideNav/SideNav.styles'
const subheaderHeight = 64

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: subheaderHeight,
      borderTop: '4px solid #fafafa',
      padding: '0',
      marginLeft: sideNavWidth,
      background: theme.palette.common.white,
      position: 'relative',

      [theme.breakpoints.down(1500)]: {
        marginTop: 128,
        /**
         * For this component, shiftContent is available when the side nav is closed
         */
        '&$shiftContent': {
          marginTop: 64,
        },
      },

      [theme.breakpoints.down(1330)]: {
        /**
         * Below 1330 we need to overwrite the margin set in the prev media query
         * because from 1330 up when the side nav is closed we have enough space to
         * show the content in one row
         */
        '&$shiftContent': {
          marginTop: 128,
        },
      },

      [theme.breakpoints.down('xs')]: {
        borderTop: 0,
        marginTop: 68,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
      },
    },

    shiftContent: {
      paddingLeft: 0,
      marginLeft: 0,

      '& $menuToggleBtn': {
        transform: 'rotate(180deg)',
        zIndex: 1200,
      },
    },

    menuToggleBtn: {
      width: 24,
      height: 24,
      border: '1px solid #D0D4D8',
      position: 'absolute',
      left: 0,
      zIndex: 20,
      marginLeft: -10,
      backgroundColor: theme.palette.common.white,
      transform: 'rotate(0deg)',

      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: '100ms',
      }),

      '& .MuiSvgIcon-root': {
        fontSize: 12,
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,

        '& $menuToggleIcon': {
          fill: theme.palette.common.white,
        },
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    menuToggleIcon: {
      fontSize: 12,
    },
  }),
  { name: 'AppContent' }
)

interface Props {
  children: React.ReactNode
  shiftContent: boolean
}

const AppContent = ({ children, shiftContent }: Props) => {
  const classes = useStyles()

  return (
    <section className={clsx(classes.root, { [classes.shiftContent]: shiftContent })} id="calendar">
      {children}
    </section>
  )
}

export default AppContent
