import React, { useState } from 'react'

const initialState = ''

type UseState<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export const Context = React.createContext<UseState<string>>([initialState, () => {}])

const SearchTermProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const state = useState(initialState)

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export const useSearchTermState = () => {
  const context = React.useContext(Context)

  if (context === undefined) {
    throw new Error('useSearchTermState must be used within a SearchTermProvider.')
  }

  return context
}

export default SearchTermProvider
