import { colors } from '@dentalux/ui-library-core'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { VariantType } from 'notistack'

interface StyleProps {
  variant: VariantType
  expanded: boolean
}

export default makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: '400px !important',
    },
  },
  card: {
    color: '#fff',
    width: '400px',
  },
  cardWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  actionRoot: ({ variant }) => ({
    minHeight: '56px',
    alignItems: 'center',
    padding: '8px 16px',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    ...(variant === 'default' && {
      backgroundColor: `${colors.grey[800]}`,
    }),
    ...(variant === 'info' && {
      backgroundColor: '#2196f3',
    }),
    ...(variant === 'error' && {
      backgroundColor: '#d32f2f',
    }),
    ...(variant === 'success' && {
      backgroundColor: '#43a047',
    }),
    ...(variant === 'warning' && {
      backgroundColor: '#ff9800',
    }),
  }),
  icon: {
    marginRight: '12px',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: ({ expanded }) => ({
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '& svg': {
      fill: '#fff',
    },
    ...(expanded && {
      transform: 'rotate(180deg)',
    }),
  }),
  collapse: {
    padding: 16,
    borderTopLeftRadius: 0,
    borderTopRight: 0,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  closeButton: {
    position: 'absolute',
    right: '-6px',
    top: '-6px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '1px solid #212121',
    background: '#fff',
    padding: 0,
    '&:hover': {
      background: '#fff',
    },
    '& svg': {
      width: '12px',
      height: '12px',
    },
  },
  copyButton: {
    fontWeight: 400,
    border: '1px solid #616161',
    textTransform: 'none',
    background: '#FAFAFA',
    padding: '4px 12px',
    fontSize: '10px',
    '& svg': {
      width: '14px',
    },
  },
  text: {
    fontWeight: 400,
  },
}))
