import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import { PractitionerKpi } from '../../@types/PractitionerKpi'
import parseArrayQueryParam from '../../helpers/parseArrayQueryParam'
import '../../setupEnv'
import { addErrorInterceptors } from '../interceptors'

export const insightHttp = axios.create({
  baseURL: window.env.REACT_APP_INSIGHT_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(insightHttp)
addErrorInterceptors(insightHttp)

export type GetAnalyticsInput = {
  practitionerReferenceIds: string[]
  periodStart: string
  periodEnd: string
  timeGroup: string
}

export const getAnalytics = async (params: GetAnalyticsInput): Promise<PractitionerKpi[]> => {
  const { data } = await insightHttp.get('/api/kpi/timeseries/practitioner', {
    params: {
      ...params,
      practitionerReferenceIds: parseArrayQueryParam(params.practitionerReferenceIds),
    },
  })

  return data
}
