import React, { useEffect, useMemo } from 'react'

import { View as BigCalendarViewType } from 'react-big-calendar'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'
import moment, { Moment } from 'moment'
import { useSnackbar } from 'notistack'

import { VIEW_TYPE } from '../../../../@types/CalendarEvent'
import { Clinic } from '../../../../@types/Clinic'
import { ParamTypes } from '../../../../@types/RouteParams'
import { setLocalStorage } from '../../../../helpers/setLocalStorage'
import { useAnalyticsContext } from '../../../../providers/AnalyticsProvider'
import { useAnalyticsToggleContext } from '../../../../providers/AnalyticsToggleProvider'
import { useAuthContext } from '../../../../providers/AuthProvider'
import { useCalendarSettingsContext } from '../../../../providers/CalendarSettingsProvider'
import { useCancelledAppointmentsToggle } from '../../../../providers/CancelledAppointmentsToggleProvider'
import { useColorsContext } from '../../../../providers/ColorsProvider'
import { useCurrentClinicProvider } from '../../../../providers/CurrentClinicProvider'
import { useDrawerCalendarStateContext } from '../../../../providers/DrawerCalendarStateProvider'
import { useFeatureFlags } from '../../../../providers/FeatureFlags'
import { uiLibraryTheme } from '../../../../theme/uiTheme'

import AppBarDesktopStatic from './Desktop'

export type Props = {
  shiftContent?: boolean
  onToggleDrawer: () => void
}

const AppBarDesktop = (props: Props) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { view, date, sideNav, clinicReferenceId } = useParams<ParamTypes>()

  const [, setCurrentActiveStartDate] = useDrawerCalendarStateContext()
  const { selectedClinic, setSelectedClinic } = useCurrentClinicProvider()
  const colors = useColorsContext()

  const analytics = useAnalyticsContext()
  const featureFlags = useFeatureFlags()

  const { closeSnackbar } = useSnackbar()

  const { currentDate, setCurrentDate, calendarViewType, setCalendarViewType } = useCalendarSettingsContext()
  const { showAnalytics, setShowAnalytics } = useAnalyticsToggleContext()
  const { showCancelledAppointments, setShowCancelledAppointments } = useCancelledAppointmentsToggle()
  const { user } = useAuthContext()

  const clinicId = useMemo(
    () => clinicReferenceId ?? selectedClinic?.referenceId ?? '',
    [clinicReferenceId, selectedClinic?.referenceId]
  )

  const formattedDate = currentDate.format('YYYY-MM-DD')

  const handleClinicChange = (clinic: Clinic) => {
    setSelectedClinic(clinic)

    if (selectedClinic?.referenceId !== clinic?.referenceId) {
      /**
       * Close all snackbars when the clinic is changed
       */
      closeSnackbar()
    }
  }

  const handleCalendarViewTypeChange = (event: React.ChangeEvent<{ value: BigCalendarViewType }>) => {
    setCalendarViewType(event.target.value as VIEW_TYPE)
    history.push(
      `/calendar/${event.target.value}/${date ?? formattedDate}/${sideNav || 'patients'}/${selectedClinic?.referenceId}`
    )

    setShowAnalytics(false)
  }

  const handleToggleShowAnalytics = (_: unknown, checked: boolean) => setShowAnalytics(checked)

  const handleToggleCanceledAppointments = (_: unknown, checked: boolean) => setShowCancelledAppointments(checked)

  const handleDateChange = (date: Moment) => {
    const updatedDate = date.format('YYYY-MM-DD')
    const sideNavUrl = sideNav || 'patients'
    history.push(`/calendar/${view ?? 'day'}/${updatedDate}/${sideNavUrl}/${clinicId}`)

    setCurrentDate(moment(date))
    setCurrentActiveStartDate(date.startOf('month'))
  }

  const handleToday = () => {
    history.push(`/calendar/${view ?? 'day'}/${moment().format('YYYY-MM-DD')}/${sideNav || 'patients'}/${clinicId}`)

    setCurrentDate(moment())
    setCurrentActiveStartDate(moment().startOf('month'))
  }

  useEffect(() => {
    const dateFromUrl = date && moment(date).isValid() && moment.utc(date, 'YYYY-MM-DD')
    const dateToUse = dateFromUrl || formattedDate

    const calendarView = view ?? localStorage.getItem('view') ?? 'day'

    setCalendarViewType(calendarView as VIEW_TYPE)

    const calendarDate = dateToUse
    const calendarSideNav = sideNav ?? 'patients'

    setLocalStorage('calendarParams', {
      view: calendarView,
      date: calendarDate,
      sideNav: calendarSideNav,
      clinicReferenceId: clinicId,
    })

    const newPath = clinicId && `/calendar/${calendarView}/${calendarDate}/${calendarSideNav}/${clinicId}`

    if (pathname === '/' || pathname === '/calendar' || pathname === '/undefined') history.push(newPath)
  }, [currentDate, view, date, sideNav, pathname, clinicId])

  return (
    // TODO: define theme to global/app scope when Calmaster is moved to material v5
    <ThemeProvider theme={uiLibraryTheme}>
      <AppBarDesktopStatic
        {...props}
        selectedClinic={selectedClinic}
        calendarViewType={calendarViewType as BigCalendarViewType}
        currentDate={currentDate}
        colors={colors}
        showAnalytics={showAnalytics}
        analytics={analytics}
        showCanceledAppointments={showCancelledAppointments}
        featureFlags={featureFlags}
        user={user}
        onClinicChange={handleClinicChange}
        onCalendarViewTypeChange={handleCalendarViewTypeChange}
        onToggleShowAnalytics={handleToggleShowAnalytics}
        onToggleCanceledAppointments={handleToggleCanceledAppointments}
        onDateChange={handleDateChange}
        onToday={handleToday}
      />
    </ThemeProvider>
  )
}

export default AppBarDesktop
