import { PropsWithChildren } from 'react'

import { ObfuscatedText } from '@dentalux/ui-library-core'

import { useObfuscationProvider } from '../../providers/ObfuscationProvider'

export const BlurredText = ({ children }: PropsWithChildren) => {
  const { isObfuscated } = useObfuscationProvider()

  return <ObfuscatedText isVisible={!isObfuscated}>{children}</ObfuscatedText>
}
