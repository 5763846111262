import { ComponentType, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { EmployeeRole, EmployeesFilter } from '@dentalux/common'
import { EmployeeSelect, EmployeeLabels } from '@dentalux/ui-library-core'

import { ButtonProps } from '@mui/material'

interface PractitionerDropdownProps {
  context: EmployeesFilter
  Button?: ComponentType<ButtonProps>
  title?: string
  showWorkingEmployees?: boolean
}

const PractitionerDropdown = ({ context, ...props }: PractitionerDropdownProps) => {
  const { t } = useTranslation()

  const labels: EmployeeLabels = useMemo(
    () => ({
      none: t('none'),
      roles: {
        categories: Object.keys(EmployeeRole).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: t(`all_${curr.toLowerCase()}s`),
          }),
          {}
        ),
        heading: 'Heading',
      },
      search: {
        placeholder: t('search'),
      },
      toggleAll: t('all_employees'),
      toggleDeactivatedEmployees: t('all_deactivated_employees'),
      toggleWorkingEmployees: t('all_working_employees'),
    }),
    [t]
  )

  return <EmployeeSelect context={context} width={300} showDeactivatedEmployees labels={labels} {...props} />
}

export default PractitionerDropdown
