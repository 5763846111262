import { makeStyles } from '@material-ui/core'

import PersonIcon from '../../../../assets/icons/patient-icon.svg'

export default makeStyles(
  (theme) => ({
    root: {
      '& .MuiInputLabel-root': {
        position: 'relative',
      },
    },

    notification: {
      width: 346,
      minHeight: 116,
      borderRadius: 24,
      position: 'relative',
      background: 'rgba(30, 30, 30, 0.9)',
      marginTop: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
    },

    name: {
      fontWeight: 700,
      color: '#fff',
      fontSize: 17,
      lineHeight: '24px',
      fontFamily: 'Lato',
    },

    phone: {
      fontWeight: 400,
      color: '#fff',
      fontSize: 15,
      fontFamily: 'Lato',
    },

    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },

    selectInput: {
      '&:after': {
        borderBottomWidth: 1,
      },
    },

    phoneNumber: {
      fontWeight: 400,
      fontSize: 20,
    },

    '@keyframes icon': {
      '0%': { transform: 'translate3d(0em, 0, 0)' },
      '2%': { transform: 'translate3d(0.05em, 0, 0)' },
      '4%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '6%': { transform: 'translate3d(0.05em, 0, 0)' },
      '8%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '10%': { transform: 'translate3d(0.05em, 0, 0)' },
      '12%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '14%': { transform: 'translate3d(0.05em, 0, 0)' },
      '16%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '18%': { transform: 'translate3d(0.05em, 0, 0)' },
      '20%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '22%': { transform: 'translate3d(0.05em, 0, 0)' },
      '24%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '26%': { transform: 'translate3d(0.05em, 0, 0)' },
      '28%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '30%': { transform: 'translate3d(0.05em, 0, 0)' },
      '32%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '34%': { transform: 'translate3d(0.05em, 0, 0)' },
      '36%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '38%': { transform: 'translate3d(0.05em, 0, 0)' },
      '40%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '42%': { transform: 'translate3d(0.05em, 0, 0)' },
      '44%': { transform: 'translate3d(-0.05em, 0, 0)' },
      '46%': { transform: 'translate3d(0em, 0, 0)' },
    },

    '@keyframes pulse': {
      '0%': {
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
      '33%': {
        transform: 'translate3d(0, 0, 0) scale(1.1)',
      },
      '66%': {
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
      '100%': {
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },

    phoneIcon: {
      width: 20,
      height: 20,
      marginTop: 14.64,
      marginLeft: 13.33,
      color: '#fff',
      fill: '#fff',
    },

    ringingAnimation: {
      animation: '$icon 1.5s infinite',
    },

    iconAndTextContainer: {
      display: 'flex',
    },
    iconContainer: {
      minWidth: 48,
      height: 48,
      borderRadius: '100%',
      animation: '$pulse 1.5s infinite',
      alignSelf: 'baseline',
    },

    textContainer: {
      marginLeft: 16,
      fontFamily: 'Lato',
    },

    incomingBackground: {
      backgroundColor: '#FF8661',
    },

    ongoingBackground: {
      backgroundColor: '#4EAD66',
    },

    closeIcon: {
      width: 10,
      height: 10,
      position: 'absolute',
      right: 10,
      top: 10,
      color: '#fff',
    },

    callingStatusText: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: '12px',
      fontFamily: 'Lato',
    },

    incomingCallColor: {
      color: '#FF8661',
    },

    ongoingCallColor: {
      color: '#4EAD66',
    },

    incomingCalls: {
      fontSize: 20,
      display: 'flex',
      marginLeft: 5,
    },

    formControl: {
      minWidth: 120,
    },

    phoneContainer: {
      display: 'contents',
    },

    actionContainer: {
      width: 'inherit',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    action: {
      width: 'max-content',
      fontWeight: 700,
      fontSize: 10,
      lineHeight: '12px',
      fontFamily: 'Lato',
      display: 'flex',
      textTransform: 'uppercase',
      background: '#FFFFFF',
      color: '#212121',
      flexDirection: 'row',
      padding: '4px 8px',
      justifyContent: 'end',
      borderRadius: '100px',
    },

    ringingBorder: {
      border: '2px solid #FF8661',
    },

    ongoingBorder: {
      border: '2px solid #4EAD66',
    },

    pointer: {
      cursor: 'pointer',
    },

    patientInformation: {
      display: 'flex',
    },

    patientsDropdown: {
      fontWeight: 500,
      fontSize: 14,
    },

    iconComponent: {
      top: 'unset',
      transform: 'unset',
    },

    expandMoreIcon: {
      width: 18,
      color: '#001429',
    },

    icon: {
      width: 24,
      fontSize: 24,
      marginRight: 16,
      color: '#505762',
      fill: '#505762',
      alignSelf: 'flex-start',
    },

    menuItemGutters: {
      paddingLeft: 25,
    },

    menuItemElement: {
      fontSize: 14,
      background: `url(${PersonIcon}) no-repeat`,
      backgroundPosition: 'bottom 54% left 8px',
      backgroundSize: '12px',
      '&$menuItemSelected, &$menuItemSelected:focus': {
        backgroundColor: 'transparent',
      },
      '&$menuItemSelected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },

    menuItemSelected: {},

    dropDownStyle: {
      background: '#fff',
      border: '1px solid #E0E0E0',
      boxSizing: 'border-box',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
      borderRadius: 6,
      maxHeight: 200,
    },
  }),
  { name: 'CallNotification' }
)
