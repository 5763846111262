import React, { PropsWithChildren, useEffect } from 'react'

import { useCredentials } from '@dentalux/security'

import { PostHogProvider, usePostHog } from 'posthog-js/react'

import { User } from '../@types/Auth'
import getEnvironmentName from '../helpers/getEnvironmentName'

const AddPosthogIdentity = ({ enabled, children }: PropsWithChildren<{ enabled: boolean }>) => {
  const { authentication: user }: { authentication: User } = useCredentials()
  const posthog = usePostHog()
  useEffect(() => {
    if (!enabled) return

    if (user?.referenceId && user?.email) {
      const { referenceId, email } = user
      posthog?.identify(referenceId, {}, { email, referenceId })
    }
  }, [user, posthog, enabled])

  return <>{children}</>
}

const PosthogProvider = ({ children }: PropsWithChildren<unknown>) => {
  const environment = getEnvironmentName()
  const enabled = environment === 'production'
  const posthogKey = process.env.REACT_APP_POSTHOG_API_KEY
  const posthogHost = process.env.REACT_APP_POSTHOG_API_HOST

  if (!enabled) return <>{children}</>

  return (
    <PostHogProvider
      apiKey={posthogKey}
      options={{
        api_host: posthogHost,
        persistence: 'localStorage',
        name: 'calmaster',
      }}
    >
      <AddPosthogIdentity enabled={enabled}>{children}</AddPosthogIdentity>
    </PostHogProvider>
  )
}

export default PosthogProvider
