import { addHours, addMinutes, formatISO, startOfDay } from 'date-fns'

export const TIME_FORMAT = 'HH:mm'

const SECOND = 1000

const MINUTE = 60 * SECOND

export const MINUTES_PER_HOUR = 60

export const secondsToMs = (seconds: number) => seconds * SECOND

export const minutesToMs = (minutes: number) => minutes * MINUTE

export const hoursToMinutes = (hours: number) => hours * MINUTES_PER_HOUR

/**
 * @param {time} required Date
 * @return number of minutes as string
 */
export const toTimeString = (time?: Date) => {
  if (!time) return '00:00'

  const hours = time.getHours().toString().padStart(2, '0')
  const minutes = time.getMinutes().toString().padStart(2, '0')

  return `${hours}:${minutes}`
}

/**
 * @param {hour} required string in format HH:mm
 * @return Date object or null
 */
export const timeStringToDate = (hour?: string | null): string | null => {
  if (!hour) return null

  const [hours, minutes] = String(hour).split(':')

  if (hours === undefined || minutes === undefined) return null

  return formatISO(addMinutes(addHours(startOfDay(new Date()), Number(hours)), Number(minutes)))
}
