import React from 'react'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import clsx from 'clsx'

import { ReactComponent as BarChartIcon } from '../assets/icons/bar-chart-icon.svg'
import { ReactComponent as PieChartIcon } from '../assets/icons/pie-chart-icon.svg'
import { formatMoney, formatPercentage } from '../services/format'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 70,

    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 44,
    },
  },
  analyticsItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:first-child': {
      marginRight: 20,
    },
  },
  analyticsIcon: {
    marginRight: 8,
    width: 12,
    height: 12,

    [theme.breakpoints.up('xl')]: {
      width: 15,
      height: 15,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[800],
    fontSize: 12,
    lineHeight: 1.3,
  },
  tooltipArrow: {
    color: theme.palette.grey[800],
  },
  value: {
    fontSize: 14,

    [theme.breakpoints.up('xl')]: {
      fontSize: 16,
    },
  },
}))

interface AnalyticsData {
  occupancyRate: number
  revenue: number
}

interface Props extends AnalyticsData {
  classes?: {
    root?: string
    analyticsItem?: string
    analyticsIcon?: string
    analyticsValue?: string
  }
  loading?: boolean
  occupancyTooltipTitle?: string
  revenueTooltipTitle?: string
}

const Loading = () => <Skeleton width={80} data-testid="loading-skeleton" variant="text" />

const Analytics: React.FC<Props> = ({
  occupancyRate = 0,
  revenue = 0,
  loading = false,
  occupancyTooltipTitle = 'total_week_occupancy',
  revenueTooltipTitle = 'total_week_revenue',
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={clsx(classes.root, props.classes?.root)}>
      <Tooltip
        classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
        title={t(occupancyTooltipTitle) || ''}
        arrow
      >
        <div className={clsx(classes.analyticsItem, props.classes?.analyticsItem)}>
          <PieChartIcon className={clsx(classes.analyticsIcon, props.classes?.analyticsIcon)} />
          {loading ? (
            <Loading />
          ) : (
            <Typography className={clsx(classes.value, props.classes?.analyticsValue)}>
              {formatPercentage(occupancyRate)}
            </Typography>
          )}
        </div>
      </Tooltip>

      <Tooltip
        classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
        title={t(revenueTooltipTitle) || ''}
        arrow
      >
        <div className={clsx(classes.analyticsItem, props.classes?.analyticsItem)}>
          <BarChartIcon className={clsx(classes.analyticsIcon, props.classes?.analyticsIcon)} />
          {loading ? (
            <Loading />
          ) : (
            <Typography className={clsx(classes.value, props.classes?.analyticsValue)}>
              {formatMoney(revenue)}
            </Typography>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export default Analytics
