import React, { lazy, memo, useState } from 'react'

import '../../../../setupEnv'

import { View as BigCalendarViewType } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { MenuItem } from '@dentalux/ui-library-core'

import {
  AppBar as MaterialAppBar,
  Typography,
  Box,
  FormControl,
  styled,
  Tooltip,
  FormControlLabel,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Switch } from '@mui/material'
import { QueryObserverResult } from '@tanstack/react-query'
import clsx from 'clsx'
import { Moment } from 'moment'

import { User } from '../../../../@types/Auth'
import { VIEW_TYPE } from '../../../../@types/CalendarEvent'
import { Clinic } from '../../../../@types/Clinic'
import { Colors } from '../../../../@types/Colors'
import { PractitionerKpi } from '../../../../@types/PractitionerKpi'
import { ParamTypes } from '../../../../@types/RouteParams'
import AnalyticsSwitchIcon from '../../../../assets/icons/updated/AnalyticsSwitchIcon'
import AnalyticsSwitchActiveIcon from '../../../../assets/icons/updated/AnalyticsSwitchIconActive'
import { avgOccupancyForAllDates, sumRevenueForAllDates } from '../../../../helpers/practitionersKpi'
import { useEmployeesFiltersContext } from '../../../../providers/EmployeesFiltersProvider'
import { useFeatureFlags } from '../../../../providers/FeatureFlags'
import {
  usePatientDetailsModalState,
  usePatientDetailsModalDispatch,
  clearActivePatient,
} from '../../../../providers/PatientDetailsModalProvider'
import { useSideNavigationContext } from '../../../../providers/SideNavigationProvider'
import { sendAnalyticsEvent } from '../../../../services/analytics'
import Analytics from '../../../Analytics'
import PractitionerDropdown from '../../../PractitionerDropdown'
import RedesignedFormControl from '../../../Styled/RedesignedFormControl'
import RedesignedSelect from '../../../Styled/RedesignedSelect'
import SuspendedComponent from '../../../SuspendedComponent'
import AppMenu from '../AppMenu/AppMenu'
import CalendarControl from '../CalendarControl'
import ClinicDropdown from '../ClinicDropdown'

import useStyles from './Desktop.styles'

const PatientDetailsModal = lazy(() => import('../../../../pages/PatientDetailsModal'))

const StyledAppBar = styled(MaterialAppBar)(({ theme }) => ({
  color: theme.palette.grey[900],
  border: 0,
  boxShadow: 'none',
  background: 'white',
  justifyContent: 'center',
}))

export interface Props {
  analytics: QueryObserverResult<PractitionerKpi[]>
  calendarViewType: BigCalendarViewType
  colors: Colors
  currentDate: Moment
  featureFlags: Record<string, boolean>
  selectedClinic: Clinic | undefined
  shiftContent?: boolean
  showAnalytics: boolean
  showCanceledAppointments: boolean
  user: User | undefined
  onCalendarViewTypeChange: (event: React.ChangeEvent<{ value: BigCalendarViewType }>) => void
  onClinicChange: (clinic: Clinic) => void
  onDateChange: (date: Moment) => void
  onToday: (date: Moment) => void
  onToggleDrawer: () => void
  onToggleShowAnalytics: (event: React.ChangeEvent, checked: boolean) => void
  onToggleCanceledAppointments: (event: React.ChangeEvent, checked: boolean) => void
}

const AppBar = ({
  analytics,
  calendarViewType,
  currentDate,
  featureFlags,
  selectedClinic,
  shiftContent,
  showAnalytics,
  showCanceledAppointments,
  onCalendarViewTypeChange,
  onClinicChange,
  onDateChange,
  onToday,
  onToggleShowAnalytics,
  onToggleCanceledAppointments,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { activePatientReferenceId } = usePatientDetailsModalState()
  const patientDetailsModalDispatch = usePatientDetailsModalDispatch()
  const { showAnalytics: canShowAnalytics, enableAppMenu, viewPatientSearch } = useFeatureFlags()
  const { open: showSidenav } = useSideNavigationContext()
  const filteredEmployeesContext = useEmployeesFiltersContext()

  const occupancyRate = avgOccupancyForAllDates(analytics?.data || [])
  const revenue = sumRevenueForAllDates(analytics?.data || [])
  const [isSearchFocused] = useState(false)

  const canShowPatientSearch = viewPatientSearch
  const canShowAppMenu = enableAppMenu
  const { view } = useParams<ParamTypes>()
  const viewType = !view ? calendarViewType : view in VIEW_TYPE ? view : 'day'
  const showAnalyticsToggle = view === 'week' && canShowAnalytics

  const handleCloseModal = () => {
    patientDetailsModalDispatch(clearActivePatient())
  }

  const handleCalendarViewChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    sendAnalyticsEvent(`${event.target.value}_view`)
    onCalendarViewTypeChange(event as React.ChangeEvent<{ value: VIEW_TYPE }>)
  }

  return (
    <StyledAppBar
      data-testid="app-bar"
      className={clsx(classes.root, { [classes.fullWidth]: !showSidenav })}
      position="fixed"
    >
      <div
        className={clsx(classes.headerWrapper, {
          [classes.shiftContent]: shiftContent,
          [classes.searchFocused]: isSearchFocused,
        })}
      >
        <div className={clsx(classes.headerSectionOne, { [classes.searchFocused]: isSearchFocused })}>
          <div className={classes.buttonWrapper}>
            <RedesignedFormControl>
              <RedesignedSelect
                onChange={handleCalendarViewChange}
                value={viewType}
                data-testid="viewType"
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem className={classes.viewTypeSelectMenuItem} value="day" size="s">
                  <Typography>{t('day')}</Typography>
                </MenuItem>

                <MenuItem className={classes.viewTypeSelectMenuItem} value="week" size="s">
                  <Typography>{t('week')}</Typography>
                </MenuItem>
              </RedesignedSelect>
            </RedesignedFormControl>
          </div>
          <span className={classes.gridAreaInnerContent}>
            <FormControl>
              <CalendarControl
                onNext={onDateChange}
                onPrevious={onDateChange}
                onToday={onToday}
                onChange={onDateChange}
                currentDate={currentDate}
                viewType={viewType}
              />
            </FormControl>
          </span>
        </div>
        <div className={clsx(classes.headerSectionTwo, { [classes.searchFocused]: isSearchFocused })}>
          <Box mr={2}>
            <PractitionerDropdown context={filteredEmployeesContext} showWorkingEmployees />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="center">
            {showAnalytics && showAnalyticsToggle && (
              <Analytics
                loading={analytics?.status === 'loading'}
                occupancyRate={occupancyRate}
                revenue={revenue}
                occupancyTooltipTitle={t('total_week_occupancy')}
                revenueTooltipTitle={t('total_week_revenue')}
              />
            )}

            <FormControl>
              {showAnalyticsToggle && (
                <Switch
                  checked={showAnalytics}
                  onChange={onToggleShowAnalytics}
                  className={classes.statisticSwitch}
                  checkedIcon={<AnalyticsSwitchActiveIcon />}
                  icon={<AnalyticsSwitchIcon />}
                />
              )}
            </FormControl>
          </Box>
        </div>
        <div className={clsx(classes.headerSectionThree, { [classes.searchFocused]: isSearchFocused })}>
          <div className={clsx(classes.canceledAppointmentsToggleWrap, { [classes.shiftContent]: shiftContent })}>
            <FormControlLabel
              value="start"
              control={
                <Tooltip title={t('show_cancelled_appointmetns') || ''}>
                  <Switch color="primary" onChange={onToggleCanceledAppointments} checked={showCanceledAppointments} />
                </Tooltip>
              }
              label={t('show_cancelled_appointmetns')}
              labelPlacement="start"
              classes={{
                root: classes.canceledAppointmentsToggleLabelWrap,
                label: classes.canceledAppointmentsToggleLabel,
              }}
            />
          </div>
          <RedesignedFormControl>
            {featureFlags.switchClinics && (
              <ClinicDropdown
                isPatientSearchEnabled={canShowPatientSearch}
                onChange={onClinicChange}
                value={selectedClinic}
              />
            )}
          </RedesignedFormControl>
        </div>
        <div className={clsx(classes.headerSectionFour, { [classes.searchFocused]: isSearchFocused })}>
          {canShowAppMenu && <AppMenu classes={{ button: classes.user }} />}
          {!!activePatientReferenceId && (
            <SuspendedComponent>
              <PatientDetailsModal patientReferenceId={activePatientReferenceId} onClose={handleCloseModal} />
            </SuspendedComponent>
          )}
        </div>
      </div>
    </StyledAppBar>
  )
}

export default memo(AppBar)
