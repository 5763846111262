import { initReactI18next } from 'react-i18next'

import { Backend } from '@dentalux/i18next-plugins'

import i18n from 'i18next'

import deTranslations from '../i18n/de.json'
import enTranslations from '../i18n/en.json'

const shouldUsePreview = ['development', 'preview'].includes(process.env.REACT_APP_ENV || '')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const init = (): any => {
  return i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      backend: {
        applicationName: 'calmaster-frontend',
        fallbackResource: {
          en: enTranslations,
          de: deTranslations,
        },
        contentfulConfig: {
          usePreview: shouldUsePreview,
        },
      },
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const testInit = (resources: any): Promise<any> => {
  return i18n.use(initReactI18next).init({
    resources: {
      en: { translation: resources.en },
      de: { translation: resources.de },
    },
    lng: 'en',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  })
}

export default i18n
