import React, { useContext, useState, useCallback, useMemo, PropsWithChildren, useEffect } from 'react'

import { useParams } from 'react-router'

import moment, { Moment } from 'moment'

import { VIEW_TYPE } from '../@types/CalendarEvent'

import { useLanguageContext } from './LanguageProvider'

export interface ContextProps {
  calendarViewType: string
  setCalendarViewType: (value: VIEW_TYPE) => void
  currentDate: Moment
  setCurrentDate: (date: Moment) => void
  today: () => void
  previous: () => void
  next: () => void
}

export const initialState: ContextProps = {
  currentDate: moment(),
  previous: () => {},
  next: () => {},
  today: () => {},
  setCurrentDate: () => {},
  calendarViewType: localStorage.getItem('view') ?? 'day',
  setCalendarViewType: () => {},
}

export const Context = React.createContext<ContextProps>(initialState)

export const useCalendarSettingsContext = () => useContext(Context)

// This component is responsbily by handling and providing state related
// to the MainCalendar and DrawerCalendar
const CalendarSettingsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { date } = useParams<{ date: string }>()
  const { currentLanguage } = useLanguageContext()
  const momentInstance = useMemo(() => (moment(date).isValid() ? moment(date) : moment()), [date])
  const [currentDate, setCurrentDate] = useState<Moment>(momentInstance)

  const [calendarViewType, setCalendarViewType] = useState<VIEW_TYPE>(
    (localStorage.getItem('view') as VIEW_TYPE) ?? 'day'
  )

  const next = useCallback(() => {
    setCurrentDate(moment(currentDate).add(1, 'day'))
  }, [currentDate, setCurrentDate])

  const previous = useCallback(() => {
    setCurrentDate(moment(currentDate).subtract(1, 'day'))
  }, [currentDate, setCurrentDate])

  const today = useCallback(() => {
    setCurrentDate(moment())
  }, [setCurrentDate])

  const handleChangeCalendarView = (view: VIEW_TYPE) => {
    if (!view) return
    setCalendarViewType(view)
    localStorage.setItem('view', view)
  }

  useEffect(() => {
    setCurrentDate((previousDate) => moment(previousDate).locale(currentLanguage))
  }, [currentLanguage])

  const value = useMemo(
    () => ({
      today,
      calendarViewType,
      setCalendarViewType: handleChangeCalendarView,
      next,
      previous,
      currentDate,
      setCurrentDate,
    }),
    [today, calendarViewType, next, previous, currentDate, setCurrentDate, handleChangeCalendarView]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default CalendarSettingsProvider
