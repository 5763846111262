import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import { PaginatedResponse } from '../../@types/Pagination'
import { PatientDetails, PatientDetailsInternal } from '../../@types/PatientDetailsResult'
import '../../setupEnv'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_PATIENT_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http)

export type PatientSearchInput = {
  query: string
  page?: number
  pageSize?: number
}

export const getPatient = async (params: PatientSearchInput): Promise<PaginatedResponse<PatientDetails>> => {
  const { data } = await http.get('/api/patient/search', { params })

  return data
}

export const getPatientById = async (referenceId: string): Promise<PatientDetails> => {
  const { data } = await http.get(`/api/core/patients/${referenceId}`)

  return data
}

export const getPatientDetails = async (referenceId: string): Promise<PatientDetails> => {
  const { data } = await http.get(`/api/patient/${referenceId}`)

  return data
}

export const getPatientDetailsInternal = async (referenceId: string): Promise<PatientDetailsInternal> => {
  const { data } = await http.get(`/api/internal/patients/${referenceId}`)

  return data
}

export const putPatientDetailsInternal = async ({ referenceId, ...body }: PatientDetailsInternal) => {
  const { data } = await http.put(`/api/internal/patients/${referenceId}`, body)

  return data
}
