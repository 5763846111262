import { useServerSentEvent } from '@dentalux/ui-library-core'

import { QueryKey, useQueryClient } from '@tanstack/react-query'

import { ServerSentResourceType } from '../@types/ServerSentEvent'
import { Status, Task, TasksSize } from '../@types/TodoListTask'

type UseTasksSubscriptionProps = {
  queryKey: QueryKey
  disabled: boolean
}

type UpdaterProps = TasksSize | undefined

const useTasksSubscription = ({ queryKey, disabled }: UseTasksSubscriptionProps): void => {
  const client = useQueryClient()

  const handleCreate = (task: Task) => {
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (!previousData) return
      return {
        size: previousData.size + 1,
      }
    })
  }

  const handleUpdate = (task: Task) => {
    if (task.status === Status.DONE || task.status === Status.IGNORED) {
      client.setQueryData(queryKey, (previousData: UpdaterProps) => {
        try {
          if (!previousData) return
          return {
            size: previousData.size - 1,
          }
        } catch {
          return
        }
      })
    }
  }

  useServerSentEvent(ServerSentResourceType.TODO_LIST_TASK, 'CREATED', handleCreate, disabled)
  useServerSentEvent(ServerSentResourceType.TODO_LIST_TASK, 'UPDATED', handleUpdate, disabled)
}

export default useTasksSubscription
