const getEnvironmentName = (): 'staging' | 'production' | 'development' => {
  if (window.location.host.startsWith('localhost')) {
    return 'development'
  } else if (window.location.host.startsWith('staging')) {
    return 'staging'
  }

  return 'production'
}

export default getEnvironmentName
