import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import { PatientOpt } from '../../@types/PatientOpt'
import parseArrayQueryParam from '../../helpers/parseArrayQueryParam'
import errors from '../../i18n/errors/ana'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_OPTAR_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http, errors)

type OptInsRequestProps = {
  personReferenceIds: string[]
  optTypes?: string[]
  from?: string
  to?: string
}

export const getPatientOptIns = async ({
  personReferenceIds,
  optTypes,
  ...parameters
}: OptInsRequestProps): Promise<PatientOpt[]> => {
  const { data } = await http.get('/api/opts/patients/latest', {
    params: {
      patientReferenceIds: parseArrayQueryParam(personReferenceIds),
      optTypes: parseArrayQueryParam(optTypes),
      ...parameters,
    },
  })

  return data
}
