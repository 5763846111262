import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import '../../setupEnv'

import { IgnoreAction, CallTask } from '../../@types/CallTask'
import { OutgoingCall, Phone, VoicemailAction } from '../../@types/Phone'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_OPERATOR_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http)

export const getOpenCallTasks = async (clinicReferenceId: string, timeStampAfter: Date): Promise<CallTask[]> => {
  const { data } = await http.get('/api/v2/call-tasks', {
    params: { clinicReferenceId, size: 1000, page: 0, open: true, timeStampAfter },
  })

  return data?.content || []
}

export const getSolvedCallTasks = async (clinicReferenceId: string, timeStampAfter: Date): Promise<CallTask[]> => {
  const { data } = await http.get('/api/v2/call-tasks', {
    params: { clinicReferenceId, size: 1000, page: 0, open: false, timeStampAfter },
  })

  return data?.content || []
}

export const getPhones = async (clinicReferenceIds?: string): Promise<Phone[]> => {
  const { data } = await http.get('/api/phones', {
    params: { clinicReferenceIds, unassigned: false },
  })

  return data?.content || []
}

export const postCallTaskAction = async (action: IgnoreAction): Promise<Record<string, unknown>> => {
  const { data } = await http.post(`/api/call-tasks/${action?.taskReferenceId}/actions`, {
    comment: action?.data?.comment,
    type: action?.data?.type,
  })

  return data
}

export const postOutgoingCall = async ({
  phone,
  phoneNumber,
  patientReferenceId,
}: OutgoingCall): Promise<Record<string, unknown>> => {
  const { data } = await http.post('/api/outgoing-calls', {
    phoneNumber,
    phoneReferenceId: phone?.referenceId,
    patientReferenceId,
  })

  return data
}

export const postListenVoicemail = async (action: VoicemailAction): Promise<Record<string, unknown>> => {
  const { data } = await http.post(`/api/call-tasks/${action.taskReferenceId}/play-answering-machine`, {
    phoneReferenceId: action.phone?.referenceId,
  })

  return data
}

export const getOpenCallCount = async (clinicReferenceId: string): Promise<number> => {
  const { data } = await http.get('/api/call-tasks/open/count', {
    params: { clinicReferenceId },
  })

  return data?.count || 0
}
