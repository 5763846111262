import { useCredentials, useLogout } from '@dentalux/security'

import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Clinic } from '../@types/Clinic'
import ServerError from '../@types/ServerError'
import api from '../services/api'

type UseClinicsReturn = [Clinic[], Omit<QueryObserverResult<Clinic[]>, 'data'>]

const useClinics = (queryOptions: UseQueryOptions<Clinic[], AxiosError<ServerError>> = {}): UseClinicsReturn => {
  const { authentication } = useCredentials()
  const logout = useLogout()

  const {
    data: clinics,
    ...queryDetails
  }: QueryObserverResult<Clinic[], AxiosError<ServerError>> = useQuery({
    queryKey: ['clinics'],
    queryFn: api.calmaster.getClinics,
    enabled: Boolean(authentication),
    ...queryOptions,
    onError(err) {
      if (err.response?.status === 401) {
        logout()
      }
    },
  })

  const sortedClinics = clinics?.sort((clinicOne, clinicTwo) => clinicOne.name.localeCompare(clinicTwo.name, 'de'))

  return [sortedClinics || [], queryDetails]
}

export default useClinics
