import { colors as uiColors } from '@dentalux/ui-library-core'

import { makeStyles } from '@material-ui/core'

import colors from '../../../../theme/colors'

export default makeStyles(() => ({
  button: {
    '&&': {
      fontSize: '0px',

      '& .MuiButton-startIcon': {
        marginRight: 0,
        color: uiColors.aqua[800],
      },
    },
  },

  buttonLabel: {
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    color: colors.dark,
  },

  menuPaper: {
    marginTop: 40,
  },

  startIcon: {
    margin: 0,
  },

  endIcon: {
    '& > *:first-child': {
      fontSize: 16,
    },
  },
}))
