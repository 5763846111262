import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react'

import { AppointmentPatient } from '@dentalux/common'

import { CalmasterAppointment } from '../@types/Appointment'

import { useFeatureFlags } from './FeatureFlags'

type State = {
  open: boolean
  selectedAppointment: CalmasterAppointment | null | undefined
  toggleSideNavigation: () => void
  setSelectedAppointment: Dispatch<SetStateAction<CalmasterAppointment | null>>
  selectedPatient: AppointmentPatient | null
  setSelectedPatient: Dispatch<SetStateAction<AppointmentPatient | null>>
}

type Props = {
  children: React.ReactNode
}

const initialValues: State = {
  open: false,
  selectedAppointment: null,
  setSelectedAppointment: () => {},
  toggleSideNavigation: () => {},
  selectedPatient: null,
  setSelectedPatient: () => {},
}

const SideNavigationStateContext = createContext<State>(initialValues)

export const SideNavigationProvider: React.FC<Props> = ({ children }) => {
  const featureFlags = useFeatureFlags()

  const [open, setIsOpen] = useState(!!featureFlags.viewWaitingRoom)
  const [selectedAppointment, setSelectedAppointment] = useState<CalmasterAppointment | null>(null)
  const [selectedPatient, setSelectedPatient] = useState<AppointmentPatient | null>(null)

  const toggleSideNavigation = useCallback(() => setIsOpen((prevState) => !prevState), [setIsOpen])

  const state = useMemo(
    () => ({
      open,
      toggleSideNavigation,
      selectedAppointment,
      setSelectedAppointment,
      selectedPatient,
      setSelectedPatient,
    }),
    [open, selectedAppointment, toggleSideNavigation, selectedPatient]
  )

  return <SideNavigationStateContext.Provider value={state}>{children}</SideNavigationStateContext.Provider>
}

export const useSideNavigationContext = (): State => {
  const context = useContext(SideNavigationStateContext)

  if (context === undefined) {
    throw new Error('useSideNavigationState must be used within a SideNavigationProvider')
  }

  return context
}
