import { useHistory, useParams } from 'react-router'

import { CalmasterPatient } from '../../@types/Patient'
import { ParamTypes } from '../../@types/RouteParams'
import logoUrl from '../../assets/d21-logo.svg'
import { ReactComponent as HamburgerIcon } from '../../assets/icons/menu-hamburger.svg'
import { useFeatureFlags } from '../../providers/FeatureFlags'
import { setActivePatient, usePatientDetailsModalDispatch } from '../../providers/PatientDetailsModalProvider'
import { useSideNavigationContext } from '../../providers/SideNavigationProvider'

import { AppGridMenu } from './components/AppGridMenu'
import { ObfuscationButton } from './components/ObfuscationButton'
import { PatientSearchInput } from './components/PatientSearchInput'
import { UserDropdown } from './components/UserDropdown'
import { useStyles, HamburgerIconButton, HamburgerIconWrapper } from './MainHeader.styles'

export const MainHeader = () => {
  const classes = useStyles()
  const routeParams = useParams<ParamTypes>()
  const history = useHistory()
  const patientDetailsModalDispatch = usePatientDetailsModalDispatch()
  const { viewPatientSearch: canShowPatientSearch, viewWaitingRoom } = useFeatureFlags()
  const { toggleSideNavigation } = useSideNavigationContext()

  const handlePatientSelect = (value: CalmasterPatient | string | null) => {
    localStorage.setItem('calendarParams', JSON.stringify(routeParams))

    const { view, date, sideNav, clinicReferenceId } = routeParams

    if (typeof value === 'string') {
      history.push({
        pathname: `/calendar/${view ?? 'day'}/${date}/${sideNav ?? 'patients'}/${clinicReferenceId}/search`,
        search: `?query=${value}`,
      })

      return
    }

    if (value) patientDetailsModalDispatch(setActivePatient(value.referenceId))
  }

  const handleToggleSideNav = () => {
    window.requestAnimationFrame(() => {
      toggleSideNavigation()
    })
  }

  return (
    <header className={classes.root}>
      <div className={classes.logoWrapper}>
        {viewWaitingRoom && (
          <HamburgerIconButton onClick={handleToggleSideNav}>
            <HamburgerIconWrapper size="lg">
              <HamburgerIcon />
            </HamburgerIconWrapper>
          </HamburgerIconButton>
        )}

        <img src={logoUrl} alt="Dental 21" className={classes.logo} />
        <span className={classes.title}>Calmaster</span>
      </div>

      <div className={classes.inputWrapper}>
        {canShowPatientSearch && <PatientSearchInput onSelect={handlePatientSelect} />}
      </div>

      <div className={classes.actionsWrapper}>
        <ObfuscationButton />
        <AppGridMenu />
        <UserDropdown />
      </div>
    </header>
  )
}
