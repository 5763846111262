import { GlobalServerErrorTranslations } from '../../@types/ServerError'

export default {
  400: 'errors.validation',
  422: 'errors.validation',
  404: 'errors.not_found',
  409: 'errors.conflict',
  403: 'errors.forbidden',
  500: 'errors.sever_error',
  501: 'errors.sever_error',
  502: 'errors.sever_error',
  503: 'errors.sever_error',
} as GlobalServerErrorTranslations
