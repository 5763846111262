import numeral from 'numeral'
import 'numeral/locales/de'

export const DEFAULT_MONEY_FORMAT = '0,0.00 $'

export const DEFAULT_PERCENTAGE_FORMAT = '0.00%'

numeral.locale('de')

export const formatMoney = (value: number, options?: { format: string }) => {
  const format = options?.format || DEFAULT_MONEY_FORMAT

  return numeral(value).format(format)
}

export const formatPercentage = (value: number) => {
  return numeral(value).format(DEFAULT_PERCENTAGE_FORMAT)
}
