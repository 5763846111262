import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import '../../setupEnv'

import { CalmasterAppointment } from '../../@types/Appointment'
import AppointmentBundle from '../../@types/AppointmentBundle'
import AppointmentCancellation from '../../@types/AppointmentCancellation'
import { AppointmentHistory } from '../../@types/AppointmentHistory'
import { AppointmentHistoryParams } from '../../@types/AppointmentHistoryParams'
import AppointmentState from '../../@types/AppointmentState'
import { Clinic } from '../../@types/Clinic'
import { CommunicationHistory } from '../../@types/CommunicationHistory'
import { CommunicationHistoryParams } from '../../@types/CommunicationHistoryParams'
import { CostPlan } from '../../@types/CostPlan'
import { CostPlanWhereInput } from '../../@types/CostPlanParams'
import { MedicalRecord } from '../../@types/MedicalRecord'
import { PaginatedResponse } from '../../@types/Pagination'
import { CalmasterPatient, CalmasterPatientDetails } from '../../@types/Patient'
import { PatientAccountStatus } from '../../@types/PatientDetailsResult'
import { CalmasterPractitioner } from '../../@types/Practitioner'
import { Room } from '../../@types/Room'
import Template from '../../@types/SMSTemplate'
import SortDirection from '../../@types/SortDirection'
import TreatmentBundle from '../../@types/TreatmentBundle'
import parseArrayQueryParam from '../../helpers/parseArrayQueryParam'
import errors from '../../i18n/errors/calmaster'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_CALMASTER_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http, errors)

export type GetAppointmentsInput = {
  size?: number
  page?: number
  sortBy?: string
  sortDirection?: SortDirection
  startAfter?: string
  startBefore?: string
  endAfter?: string
  endBefore?: string
  clinicReferenceIds?: string[]
  practitionerReferenceIds?: string[]
  patientReferenceIds?: string[]
}

export const getAppointments = async (
  params: GetAppointmentsInput,
  signal: AbortSignal
): Promise<CalmasterAppointment[]> => {
  const { data } = await http.get('/api/appointments', {
    signal,
    params: {
      size: 1000,
      page: 0,
      sortDirection: 'ASC',
      ...params,
      clinicReferenceIds: parseArrayQueryParam(params?.clinicReferenceIds),
      practitionerReferenceIds: parseArrayQueryParam(params?.practitionerReferenceIds),
      ...(params?.patientReferenceIds && {
        patientReferenceIds: parseArrayQueryParam(params?.patientReferenceIds),
      }),
    },
  })

  return data.content
}

export const getCostPlans = async (params: CostPlanWhereInput): Promise<PaginatedResponse<CostPlan>> => {
  const { data } = await http.get('/api/cost-plans', {
    params: {
      ...params,
      size: 1000,
      page: 0,
      clinicReferenceIds: parseArrayQueryParam(params?.clinicReferenceIds),
      patientReferenceIds: parseArrayQueryParam(params?.patientReferenceIds),
    },
  })

  return data
}

export const getAppointment = async (appointmentRefId: string): Promise<CalmasterAppointment> => {
  const { data } = await http.get(`/api/appointments/${appointmentRefId}`)

  return data
}

export type PatchAppointmentInput = {
  id: string
  appointmentState: AppointmentState
  smsMessage?: string
  sendSms: boolean
}

export type CancelAppointmentInput = {
  id: string
  cancellationType?: AppointmentCancellation
  transferToOpenAppointment: boolean
  reason?: string
}

export type PatientInputEdit = {
  id?: string
  data: CalmasterPatient
}

export type PatientInputPatch = {
  id?: string
  data?: {
    primaryEmail?: string
    primaryPhone?: string
  }
}

export type MedicalRecordsInput = {
  clinicReferenceIds: string
  patientReferenceIds: string
}

type GetTreatmentBundlesParams = {
  searchTerm?: string
  clinicReferenceIds?: string[]
  enabled?: boolean
}

export const getTreatmentBundles = async (params: GetTreatmentBundlesParams): Promise<TreatmentBundle[]> => {
  const { data } = await http.get('/api/treatment-bundles', {
    params: {
      ...params,
      clinicReferenceIds: params.clinicReferenceIds && parseArrayQueryParam(params.clinicReferenceIds),
    },
  })

  return data
}

export const patchAppointment = async ({ id, ...body }: PatchAppointmentInput): Promise<CalmasterAppointment> => {
  const { data } = await http.patch(`/api/appointments/${id}`, body)

  return data
}

export const getPatient = async (referenceId: string): Promise<CalmasterPatientDetails> => {
  const { data } = await http.get(`/api/patients/${referenceId}`)

  return data
}

export const getAppointmentBundle = async (referenceId: string): Promise<AppointmentBundle> => {
  const { data } = await http.get(`/api/appointment-bundles/${referenceId}`)

  return data
}

export const getSmsTemplates = async (): Promise<Template[]> => {
  const { data } = await http.get('/api/sms/templates')

  return data
}

export const getClinics = async (): Promise<Clinic[]> => {
  const { data } = await http.get('/api/clinics')

  return data
}

export type GetPractitionersQueryParams = {
  clinicReferenceId: string
  treatmentReferenceId?: string
}

export const getPractitioners = async (params: GetPractitionersQueryParams): Promise<CalmasterPractitioner[]> => {
  const { data } = await http.get('/api/practitioners', { params })

  return data
}

export const getMedicalRecords = async (params: MedicalRecordsInput): Promise<MedicalRecord[]> => {
  const { data } = await http.get('/api/medical-records', { params })

  return data.content
}

type PatientCreationForm = {
  firstName: string
  lastName: string
  genderType: string
  birthDay: string
  phoneNumber: string
  email?: string
  insuranceType?: string
}

export type PostAppointmentBundlesBody = {
  clinicReferenceId: string
  practitionerReferenceId: string
  treatmentBundleReferenceId: string
  patientReferenceId?: string
  patient?: PatientCreationForm
  start: string
  title?: string
  comment?: string
}

export type PutAppointmentBundleParams = {
  appointmentBundleReferenceId: string
  clinicReferenceId: string
  practitionerReferenceId: string
  treatmentBundleReferenceId: string
  patientReferenceId?: string
  patient?: PatientCreationForm
  start: string
  title?: string
  comment?: string
}

export const getAppointmentHistory = async (
  params: AppointmentHistoryParams
): Promise<PaginatedResponse<AppointmentHistory>> => {
  const { data } = await http.get('/api/appointments/history', {
    params,
  })

  return data
}

export const getCommunicationHistory = async (
  params: CommunicationHistoryParams
): Promise<PaginatedResponse<CommunicationHistory>> => {
  const { data } = await http.get('/api/communications/history', {
    params,
  })

  return data
}

type RoomParams = {
  clinicReferenceId: string
}

export const getRooms = async (params: RoomParams): Promise<Room[]> => {
  const { data } = await http.get('/api/rooms', {
    params,
  })

  return data
}

export type HealthAssistantStatus = {
  referenceId: string
  externalId: string
  clinicReferenceId: string
  patientAccountStatus: PatientAccountStatus
}

type HealthAssistantEmailsInput = { patientReferenceId: string }

export const postHealthAssistantEmail = async ({ patientReferenceId }: HealthAssistantEmailsInput) => {
  return await http.post(`/api/patients/${patientReferenceId}/patient-account-emails`)
}
