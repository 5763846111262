export const STAGING_SOLUTIO_ID = '091b3f7d-c56b-4972-b148-183eb761815f'

export const CLINIC_STARTING_HOUR = 7

export const CLINIC_ENDING_HOUR = 21

export const HELPDESK_EXTERNAL_URL = 'https://dentalux.atlassian.net/servicedesk/customer/portal/3/group/5/create/14'

export const MIN_TREATMENT_DURATION = 10 // in minutes

export const INITIAL_EMPLOYEES_STORAGE_KEY = 'initialEmployees'

export const INITIAL_EMPLOYEES_SEARCH_STORAGE_KEY = 'initialEmployeesSearch'

export const SHOW_WORKING_EMPLOYEES_STORAGE_KEY = 'showWorkingEmployees'

export const SHOW_INACTIVE_EMPLOYEES_STORAGE_KEY = 'showInactiveEmployees'

export const INITIAL_OPEN_APPOINTMENTS_EMPLOYEES_STORAGE_KEY = 'initialOpenAppointmentsEmployees'

export const INITIAL_OPEN_APPOINTMENTS_EMPLOYEES_SEARCH_STORAGE_KEY = 'initialOpenAppointmentsEmployeesSearch'

export const SHOW_OPEN_APPOINTMENTS_WORKING_EMPLOYEES_STORAGE_KEY = 'showOpenAppointmentsWorkingEmployees'

export const SHOW_OPEN_APPOINTMENTS_INACTIVE_EMPLOYEES_STORAGE_KEY = 'showOpenAppointmentsInactiveEmployees'

export const INITIAL_WAITING_LIST_EMPLOYEES_STORAGE_KEY = 'initialWaitingListEmployees'

export const INITIAL_WAITING_LIST_EMPLOYEES_SEARCH_STORAGE_KEY = 'initialWaitingListSearchEmployees'

export const SHOW_WAITING_LIST_WORKING_EMPLOYEES_STORAGE_KEY = 'initialWaitingListWorkingEmployees'

export const SHOW_WAITING_LIST_INACTIVE_EMPLOYEES_STORAGE_KEY = 'initialWaitingListInactiveEmployees'
