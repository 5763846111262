import * as H from 'history'

import { Event, AnalyticsAction } from '../@types/Analytics'

const dataLayer = window.dataLayer || []

const EVENT_NAME = 'CalmasterTrackEvent'

const queueEvent = (event: Event) => {
  if (!window?.env?.REACT_APP_GTM_ID) return
  dataLayer.push(event)
}

export const sendAnalyticsEvent = (
  label: string,
  category = 'calmaster',
  action: AnalyticsAction = AnalyticsAction.click
): void => {
  queueEvent({
    event: EVENT_NAME,
    label,
    category,
    action,
  })
}

export const sendGTMEvent = (event: Record<string, any>) => {
  if (!window?.env?.REACT_APP_GTM_ID) return
  window.dataLayer?.push(event)
}

export const sendPageView = (location: H.Location) => {
  sendGTMEvent({
    event: 'pageview',
    page: {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    },
  })
}

const analyticsService = {
  sendGTMEvent,
  sendPageView,
}

export default analyticsService
