import { parseISO } from 'date-fns'
import { TFunction } from 'i18next'

import { CalmasterAppointment } from '../../../../@types/Appointment'
import AppointmentCancellation from '../../../../@types/AppointmentCancellation'
import {
  AppointmentState,
  AppointmentStatusParams,
  AppointmentStatusSelectForm,
  ControlSteps,
  Step,
} from '../../../../@types/AppointmentState'
import { TagColor } from '../../../../@types/Tag'
import { insertIf } from '../../../../helpers/array'
import { timeStringToDate, toTimeString } from '../../../../helpers/time'
import { TagProps } from '../../../dental-ui/Tag'

const colorByStatus: Partial<Record<AppointmentState, TagProps['color']>> = {
  [AppointmentState.NOT_STATE]: undefined,
  [AppointmentState.WAITING_IN_CLINIC]: TagColor.YELLOW,
  [AppointmentState.WAITING_OUTSIDE_PAGER]: TagColor.YELLOW,
  [AppointmentState.WAITING_OUTSIDE_SMS]: TagColor.YELLOW,
  [AppointmentState.NOTIFIED]: TagColor.DARK_BLUE,
  [AppointmentState.IN_TREATMENT]: TagColor.GREEN,
  [AppointmentState.TREATED]: TagColor.LIGHT_GREEN,
}

export const getTagColorForStatus = (status: AppointmentState): TagProps['color'] => {
  return colorByStatus[status] || TagColor.DEFAULT
}

const waitingPrefix = 'appointment.status.waiting'

export const labelByStatus = (t: TFunction) => ({
  [AppointmentState.NOT_STATE]: t('none'),
  [AppointmentState.WAITING_IN_CLINIC]: `${t(waitingPrefix)} - ${t('appointment.status.inside')}`,
  [AppointmentState.WAITING_OUTSIDE_PAGER]: `${t(waitingPrefix)} - ${t('appointment.pager')}`,
  [AppointmentState.WAITING_OUTSIDE_SMS]: `${t(waitingPrefix)} - ${t('appointment.sms')}`,
  [AppointmentState.NOTIFIED]: t('appointment.status.notified'),
  [AppointmentState.IN_TREATMENT]: t('appointment.status.in_treatment'),
  [AppointmentState.TREATED]: t('appointment.status.treated'),
})

const mapStatusKey = (name: AppointmentState): keyof Omit<AppointmentStatusParams, 'cancellationType'> | null => {
  switch (name) {
    case AppointmentState.TREATED:
      return 'stateTreatmentEnd'
    case AppointmentState.IN_TREATMENT:
      return 'stateTreatmentStart'
    case AppointmentState.IN_TREATMENT_ROOM:
      return 'stateInTreatmentRoomStart'
    case AppointmentState.WAITING:
      return 'stateWaitingStart'
    case AppointmentState.IN_CLINIC:
      return 'stateInClinicStart'
    default:
      return null
  }
}

export const toPatchRequestData = (data: AppointmentStatusSelectForm): Partial<AppointmentStatusParams> => {
  const { statusSelect, cancellationReason, cancellationType, checkedStatus, controlStepsChecked, roomReferenceId } =
    data

  const result = (statusSelect ?? []).reduce((accumulator, { name, time }) => {
    const keyToUse = name as AppointmentState
    const key = mapStatusKey(keyToUse)
    const isCancellationKey = keyToUse === AppointmentState.CANCELLED

    return {
      ...accumulator,
      ...(isCancellationKey && {
        cancellationType:
          checkedStatus === AppointmentState.CANCELLED ? (cancellationType as AppointmentCancellation) : null,
        cancellationReason:
          checkedStatus === AppointmentState.CANCELLED && cancellationReason ? cancellationReason : '',
      }),
      ...(key && {
        [key]: time ? timeStringToDate(time) : null,
      }),
    }
  }, {} as AppointmentStatusParams)

  return {
    ...result,
    roomReferenceId,
    controlled: controlStepsChecked?.includes(ControlSteps.ONE),
    controlledAccounting: controlStepsChecked?.includes(ControlSteps.TWO),
  }
}

export const toCancellationType = (cancellationType?: AppointmentCancellation | null) => {
  if (!cancellationType) return null

  const cancelledByPatientOptions = [
    AppointmentCancellation.CANCELED,
    AppointmentCancellation.CANCELED_ON_TIME,
    AppointmentCancellation.CANCELED_SHORT_TERM,
    AppointmentCancellation.CANCELED_MOVED,
  ]

  if (cancelledByPatientOptions.includes(cancellationType)) return AppointmentCancellation.CANCELED

  return cancellationType
}

export const toControlSteps = (control: boolean, controlAccounting: boolean) => [
  ...insertIf(control, [ControlSteps.ONE]),
  ...insertIf(controlAccounting, [ControlSteps.TWO]),
]

export const toFormData = (steps: Step[], appointment: CalmasterAppointment) => {
  return steps.map(({ name, position }, _, array) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = appointment[mapStatusKey(name)!] as string | null | undefined // we do not count control values
    const time = (data && toTimeString(parseISO(data))) ?? ''

    return {
      time,
      name,
    }
  })
}

export const getCheckedStatus = (steps: Step[], appointment: CalmasterAppointment) => {
  if (appointment.cancellation) return AppointmentState.CANCELLED
  if (appointment.controlled || appointment.controlledAccounting) return AppointmentState.CONTROLLED

  const getHighestCheckedStep = steps.reduce(
    (acc: { position: number; step: AppointmentState | undefined }, current) => {
      if (current.position === undefined) return acc
      const position = current.position as number
      const data = appointment[mapStatusKey(current.name)!]

      return data && position > acc.position ? { position, step: current.name } : acc
    },
    { position: -1, step: undefined }
  )

  return getHighestCheckedStep.step
}

export const getAppointemntStatus = ({
  stateTreatmentEnd,
  stateInTreatmentRoomStart,
  stateTreatmentStart,
  stateWaitingStart,
  stateInClinicStart,
  cancellation,
}: Partial<CalmasterAppointment>) => {
  if (cancellation) return AppointmentState.CANCELLED
  if (stateTreatmentEnd) return AppointmentState.TREATED
  if (stateTreatmentStart) return AppointmentState.IN_TREATMENT
  if (stateInTreatmentRoomStart) return AppointmentState.IN_TREATMENT_ROOM
  if (stateWaitingStart) return AppointmentState.WAITING
  if (stateInClinicStart) return AppointmentState.IN_CLINIC

  return AppointmentState.OPEN
}
