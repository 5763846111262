import React, { Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from 'react'

import { QueryKey, UseQueryResult } from '@tanstack/react-query'
import { isToday } from 'date-fns'

import { CalmasterAppointment } from '../@types/Appointment'
import { toDate } from '../helpers/date'
import useAppointments from '../hooks/useAppointments'
import useWaitingRoomAppointmentsParams from '../hooks/useWaitingRoomAppointmentsParams'
import useAppointmentsSubscription from '../subscriptions/useAppointmentsSubscription'

import { useCurrentClinicProvider as useCurrentClinic } from './CurrentClinicProvider'

type UpcomingAppointmentsProviderData = {
  appointments: CalmasterAppointment[]
  queryResults: Omit<UseQueryResult<CalmasterAppointment[]>, 'data'> | null
  selectedDay: Date
  setSelectedDay: Dispatch<SetStateAction<Date>>
}

export const Context = React.createContext<UpcomingAppointmentsProviderData>({
  appointments: [],
  queryResults: null,
  selectedDay: new Date(),
  setSelectedDay: () => {},
})

export const UpcomingAppointmentsKeyProvider = React.createContext<QueryKey>([''])

const UpcomingAppointmentsProvider = ({ children }: PropsWithChildren) => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const { selectedClinic } = useCurrentClinic()

  const { startBefore, ...params } = useWaitingRoomAppointmentsParams(selectedDay)
  const hasClinic = params?.clinicReferenceIds?.length
  const hasPractitioners = params?.practitionerReferenceIds?.length
  const disabled = !hasClinic || !hasPractitioners

  const queryKey = useMemo(() => ['upcoming-appointments', params], [params]) as QueryKey

  const { appointments: results } = useAppointments({
    disabled,
    debounce: 0,
    params,
    key: queryKey,
  })

  const { data: appointments = [], ...queryResults } = results

  const { startAfter, endBefore } = params

  const key: QueryKey = useMemo(
    () => [
      'upcoming-appointments',
      {
        startAfter,
        endBefore,
        clinicReferenceIds: [selectedClinic?.referenceId],
        practitionerReferenceIds: params?.practitionerReferenceIds,
      },
    ],
    [selectedClinic, endBefore, startAfter, params?.practitionerReferenceIds]
  )

  const handleConditionToAddAppointment = (appointment: CalmasterAppointment) =>
    isToday(toDate(appointment.appointmentStart))

  useAppointmentsSubscription({
    query: results,
    queryKey: key,
    conditionalCheck: handleConditionToAddAppointment,
  })

  const value = useMemo(
    () => ({
      selectedDay,
      appointments,
      setSelectedDay,
      queryResults,
    }),
    [appointments, queryResults, selectedDay, setSelectedDay]
  )

  return (
    <Context.Provider value={value}>
      <UpcomingAppointmentsKeyProvider.Provider value={key}>{children}</UpcomingAppointmentsKeyProvider.Provider>
    </Context.Provider>
  )
}

export const useUpcomingAppointmentsContext = () => {
  const context = React.useContext(Context)

  if (context === undefined)
    throw new Error('useFollowingAppointments must be used within a FollowingAppointmentsProvider')

  return context
}

export const useUpcomingAppointmentsKey = () => {
  const context = React.useContext(UpcomingAppointmentsKeyProvider)

  if (context === undefined)
    throw new Error('useFollowingAppointmentsKey must be used within a UpcomingAppointmentsKeyProvider')

  return context
}

export default UpcomingAppointmentsProvider
