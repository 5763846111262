import { Appointment, PaginatedResponse, SortingDirection } from '@dentalux/common'
import { addAuthInterceptors } from '@dentalux/security'

import axios, { AxiosResponse } from 'axios'

import { AppointmentInput, CalmasterAppointment, CoreAppointment, OpenAppointment } from '../../@types/Appointment'
import { AppointmentChangelog } from '../../@types/AppointmentHistory'
import { AppointmentHistoryParams } from '../../@types/AppointmentHistoryParams'
import { AppointmentStatusParams, AppointmentStatusSelectForm } from '../../@types/AppointmentState'
import { TimeSlot } from '../../@types/TimeSlot'
import { Treatment } from '../../@types/Treatment'
import { toPatchRequestData } from '../../components/AppointmentCard/components/StatusSelect/StatusSelect.helpers'
import '../../setupEnv'
import { addErrorInterceptors } from '../interceptors'

import { CancelAppointmentInput } from './calmaster'

export type GetOpenAppointmentsInput = {
  size?: number
  page?: number
  sortBy?: string
  sortDirection?: SortingDirection
  clinicReferenceId: string
  employeeReferenceIds?: string[]
  patientReferenceId?: string
}

export const http = axios.create({
  baseURL: window.env.REACT_APP_CORE_APP_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http)

type FetchAppointmentsParams = {
  patientReferenceId: string
  clinicReferenceId: string
  startAfter: string
  startBefore: string
  cancelled: boolean
}

export const fetchAppointments = async (params: FetchAppointmentsParams): Promise<Appointment[]> => {
  const { patientReferenceId, ...rest } = params

  const endpoint = `/api/v1/internal/patients/${patientReferenceId}/appointments`

  const queryParams = {
    ...rest,
    patientReferenceId,
    deleted: false,
  }

  const response: AxiosResponse<Appointment[] | { content: Appointment[] }> = await http.get(endpoint, {
    params: queryParams,
  })

  return Array.isArray(response.data) ? response.data : response.data.content
}

export const getOpenAppointments = async (
  params: GetOpenAppointmentsInput
): Promise<PaginatedResponse<OpenAppointment>> => {
  const { data } = await http.get<PaginatedResponse<OpenAppointment>>('/api/v1/internal/open-appointments', {
    params,
  })

  return data
}

export const patchAppointmentStatus = async ({
  referenceId,
  ...params
}: AppointmentStatusSelectForm & { referenceId: string }) => {
  const parsedData = toPatchRequestData(params)
  const { data } = await http.patch<AppointmentStatusParams, AxiosResponse<CalmasterAppointment>>(
    `/api/v1/internal/appointments/${referenceId}/status`,
    parsedData
  )

  return data
}

export const cancelAppointment = async (id = '', params: Omit<CancelAppointmentInput, 'id'>): Promise<Appointment> => {
  const { data } = await http.patch(`/api/v1/internal/appointments/${id}`, params)

  return data
}

export const deleteAppointment = async (id = ''): Promise<Appointment> => {
  const { data } = await http.delete(`/api/v1/internal/appointments/${id}`)

  return data
}

export const putAppointment = async ({ id, ...body }: AppointmentInput): Promise<CalmasterAppointment> => {
  const { data } = await http.put(`/api/v1/internal/appointments/${id}`, body.data)

  return data
}

export const putOpenAppointment = async ({ id, ...body }: AppointmentInput): Promise<OpenAppointment> => {
  const { data } = await http.put(`/api/v1/internal/open-appointments/${id}`, body.data)

  return data
}

export const deleteOpenAppointment = async (id = ''): Promise<Appointment> => {
  const { data } = await http.delete(`/api/v1/internal/open-appointments/${id}`)

  return data
}

export const postAppointment = async ({ ...body }: AppointmentInput): Promise<CalmasterAppointment> => {
  const { data } = await http.post('/api/v1/internal/appointments/', body.data)

  return data
}

export const postOpenAppointment = async ({ data: formData }: AppointmentInput): Promise<OpenAppointment> => {
  const { data } = await http.post('/api/v1/internal/open-appointments/', formData)

  return data
}

export const getAppointmentChangelog = async (
  params: AppointmentHistoryParams
): Promise<PaginatedResponse<AppointmentChangelog>> => {
  const { data } = await http.get('/api/v1/internal/appointment-changelogs', {
    params,
  })

  return data
}

export type TreatmentInput = {
  clinicReferenceId: string
  employeeReferenceId?: string
}

export const getTreatments = async (params: TreatmentInput): Promise<Treatment[]> => {
  const { data } = await http.get('/api/v1/internal/bookable-treatments', { params })

  return data
}

export type FreeSlotParams = {
  clinicReferenceId?: string | null
  employeeReferenceIds?: string
  patientType?: string
  start: Date
  end: Date
  treatmentReferenceId?: string | null
  treatmentBundleReferenceId?: string | null
  durationOverrideInMin?: number
  showBusySlots?: boolean
}

export const getFreeSlots = async (params: FreeSlotParams, signal: AbortSignal): Promise<TimeSlot[]> => {
  const { data } = await http.get(`/api/v1/internal/time-slots`, {
    signal,
    params,
  })

  return data
}
