import * as Sentry from '@sentry/react'
import { formatISO, isAfter, isBefore, isEqual, parseISO } from 'date-fns'
import format from 'date-fns/format'
import isSameDay from 'date-fns/fp/isSameDay'
import * as R from 'remeda'

import getEnvironmentName from './getEnvironmentName'

const BLACKLISTED_TIME_STAMP_VALUES = ['-999999999-01-01T00:00:00Z[UTC]']

export const DATETIMEFORMAT = "yyyy-MM-dd'T'HH:mm:ss"

export const SHORTDATETIMEFORMAT = 'dd.MM.yyyy, HH:mm'

const isToday = isSameDay(new Date())

export const formatDefault = (date: string | Date | number) => {
  const parsedDate = date instanceof Date ? date : new Date(date)

  return format(parsedDate, 'dd.MM.yyyy')
}

export const addUTCTimezone = (date: string) => `${date}Z`

export const toDate = (date: string) => new Date(date)

export const removeTimezone = (dateString: string) => dateString.substring(0, 19)

export const removeTimezoneByZ = (dateString: string) => dateString.split('Z')[0]

export const stringToDateTimeString = (dateString: string) => R.pipe(dateString, toDate, formatISO)

export const stringToDate = (dateString: string) => parseISO(dateString)

export const formatDateTime = (dateString: string | undefined) =>
  dateString ? format(stringToDate(dateString), SHORTDATETIMEFORMAT) : format(new Date(), SHORTDATETIMEFORMAT)

export const isBetween = (date: Date, start: Date, end: Date) =>
  (isAfter(date, start) || isEqual(date, start)) && (isBefore(date, end) || isEqual(date, start))

export const formatISOShort = (date?: Date | string) => {
  if (!date) return
  if (typeof date === 'string') return date

  return format(date, 'yyyy-MM-dd')
}

export const validateTimeStamps = (date?: string) => {
  if (!date || BLACKLISTED_TIME_STAMP_VALUES.includes(date)) return undefined
  return date
}

export const currentISODateTime = () => formatISO(new Date())

export const convertTimeStampWithoutZone = (date: string) => {
  const dateWithoutUTCZone = new Date(addUTCTimezone(removeTimezoneByZ(date))) // remove Java UTC tag (ex. 2023-03-14T16:13:55Z[UTC]) and add JS parsable time zone

  try {
    return format(dateWithoutUTCZone, DATETIMEFORMAT)
  } catch (timeConversionError) {
    const environment = getEnvironmentName()

    if (environment !== 'development') {
      if (validateTimeStamps(date) !== undefined) {
        Sentry.addBreadcrumb({
          category: 'time error',
          message: 'Can not process timestamp',
          level: 'info',
          data: {
            originalDate: date,
            convertedTimeStamp: dateWithoutUTCZone,
          },
        })
        Sentry.captureException(timeConversionError)
      }
    }
  }

  return ''
}

const dateHelpers = {
  isBetween,
  isToday,
  formatDefault,
  removeTimezone,
  DATETIMEFORMAT,
  formatISOShort,
  convertTimeStampWithoutZone,
}

export default dateHelpers
