import React, { FormEvent, HTMLAttributes, SyntheticEvent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { usePrevious } from '@dentalux/common'
import { SearchInput } from '@dentalux/ui-library-core'

import { AutocompleteRenderOptionState } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import clsx from 'clsx'

import { AnalyticsLabel } from '../../../../@types/Analytics'
import { CalmasterPatient } from '../../../../@types/Patient'
import { PatientDetails } from '../../../../@types/PatientDetailsResult'
import { sendAnalyticsEvent } from '../../../../services/analytics'

import { formatBirthday } from './PatientSearchInput.helpers'
import { useSearchUser } from './PatientSearchInput.hooks'
import { useStyles } from './PatientSearchInput.styles'
interface PatientSearchInputParams {
  onSelect: (value: CalmasterPatient | string | null) => void
}

export const PatientSearchInput = ({ onSelect }: PatientSearchInputParams) => {
  const [isTyping, setIsTyping] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [query, setQuery] = useState('')

  const { data: options, isFetching } = useSearchUser({ query, isSelected })

  const previousFetching = usePrevious(isFetching)
  const { t } = useTranslation()
  const classes = useStyles()

  const handleInputChange = (event: SyntheticEvent, value: string) => {
    setQuery(value)
    setIsTyping(true)
    setIsSelected(false)
  }

  const handleFocus = () => {
    sendAnalyticsEvent(AnalyticsLabel.search_bar)
  }

  const handleClose = () => {
    setIsTyping(false)
    setIsSelected(false)
    setQuery('')
  }

  const handleBlur = () => {
    setIsTyping(false)
    if (!isSelected) setQuery('')
  }

  const handleChange = (_: React.ChangeEvent<unknown>, value: CalmasterPatient | string | null) => {
    setIsTyping(false)
    setIsSelected(true)
    onSelect(value)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onSelect(query)
    setIsSelected(true)
    setIsTyping(false)
  }

  const getOptionLabel = (option: CalmasterPatient | string) => {
    if (typeof option === 'string') return String(option)

    return `${option.firstName} ${option.lastName} ${formatBirthday(option.birthDay)} ${option.addressText ?? ''}`
  }

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: PatientDetails,
    { inputValue }: AutocompleteRenderOptionState
  ) => {
    const stringToMatch = `${option.firstName} ${option.lastName}`
    const matches = match(stringToMatch, inputValue, { insideWords: true })
    const parts = parse(stringToMatch, matches)
    const { birthDay, addressText } = option

    return (
      <MenuItem {...props} className={classes.menuItem} divider disableRipple>
        <span className={classes.patientName}>
          {parts.map((part, index) => (
            <span
              key={`${part}${index}`}
              className={clsx({
                [classes.patientNameHighlighted]: part.highlight,
              })}
            >
              {part.text}
            </span>
          ))}
        </span>
        <span className={classes.patientDetails}>{formatBirthday(birthDay)}</span>
        {addressText && <span className={classes.patientDetails}>{addressText}</span>}
      </MenuItem>
    )
  }

  const open = !!query.length && !isTyping && !isFetching && !isSelected

  useEffect(() => {
    if (previousFetching && !isFetching && isTyping) setIsTyping(false)
  }, [isFetching])

  return (
    <form onSubmit={handleSubmit}>
      <SearchInput
        variant="dark"
        options={options}
        open={open}
        onClose={handleClose}
        fullWidth
        placeholder={t('search_for_patient')}
        value={query}
        freeSolo
        blurOnSelect
        handleHomeEndKeys
        onInputChange={handleInputChange}
        getOptionLabel={getOptionLabel}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        noOptionsText={t('appointment_create_noresults')}
        data-testid="patientSearch"
        renderOption={renderOption}
        componentsProps={{
          paper: {
            className: classes.paper,
          },
        }}
      />
    </form>
  )
}
