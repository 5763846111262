export enum MessageType {
  /**
   * Groundwork for when we have more than one message type
   */
  SMS = 'SMS',
}

export enum MessageDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum MessageState {
  RECEIVED = 'RECEIVED',
  DELIVERED = 'DELIVERED',
  SENT = 'SENT',
}

export enum ConversationQueryName {
  archived = 'archived',
  search = 'search',
  active = 'active',
}

export type ConversationQueryOptions = {
  isArchived?: boolean
  searchTerm?: string
  contactPhones?: string
}

export interface NewConversationMessage {
  content: string
  conversationReferenceId: string
  type: MessageType
}

export type ConversationMessagesResult = {
  messages: ConversationMessage[]
  messageCreated?: ConversationMessage
}

export type ConversationPatient = {
  firstName: string | null
  lastName: string | null
  referenceId: string
}

export type ConversationContact = {
  patient: ConversationPatient | null
  phoneNumber: string
}

export type ConversationMessage = {
  /**
   * conversationReferenceId and clinicReferenceId
   * are optional because they are only retrurned when
   * requeting messages for a conversation
   */
  conversationReferenceId?: string
  clinicReferenceId?: string
  content: string
  direction: MessageDirection | string
  referenceId: string
  state: MessageState | string
  timeStamp: string
  type: MessageType | string
}

export interface Conversation {
  clinicReferenceId: string
  contact: ConversationContact
  hasBeenRead: boolean
  isArchived: boolean
  messageCount: number
  referenceId: string
  timeStamp: string
  latestMessages: ConversationMessage[]
  /**
   * There was a need to persist new messages
   * before the conversation is created. And in order
   * to avoid passing additional props down the component tree, this property
   * was introduced and to be used only
   * when a new conversation is being created.
   */
  createConversationMessage?: string
}

export type ConversationUpdate = {
  referenceId: string
  hasBeenRead?: boolean
  isArchived?: boolean
}

export interface CreateConversationRequest extends Partial<NewConversationMessage> {
  messageHeader?: {
    clinicReferenceId?: string
    contactPhoneNumber?: string
  }
  createdByHuman?: boolean
}

export type ConversationSubscription = Record<
  'conversationCreated' | 'conversationUpdated' | 'conversationDeleted',
  Conversation
>

export type MessagesSubscription = Record<'messageCreated', ConversationMessage>
