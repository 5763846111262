import { ConversationPatient } from '../@types/Conversation'
import { Customer } from '../@types/Customer'
import { CorePatient, CalmasterPatient } from '../@types/Patient'
import i18n from '../services/i18n'

const getPatientName = (
  patient: CorePatient | Customer | CalmasterPatient | undefined,
  fallback: string | undefined = i18n.t('unknown_patient') || ''
): string => {
  if (!patient) return fallback

  if (patient.firstName && patient.lastName) {
    return `${patient.lastName}, ${patient.firstName}`
  }

  if (!patient.firstName) {
    return patient.lastName || fallback
  }

  return patient.firstName
}

export const getPatientFullname = (
  patient: Partial<CorePatient> | ConversationPatient | null | undefined = {}
): string => {
  return [patient?.firstName, patient?.lastName].filter(Boolean).join(' ')
}

export default getPatientName
