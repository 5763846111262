import { useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { OutgoingCall } from '../@types/Phone'
import ServerError from '../@types/ServerError'
import { postOutgoingCall } from '../services/api/operator'

const usePostOutgoingCall = (options?: UseMutationOptions<OutgoingCall, AxiosError<ServerError>, OutgoingCall>) => {
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError<ServerError>, OutgoingCall>(postOutgoingCall, {
    onSuccess: () => {
      queryClient.refetchQueries(['callTasks'])
    },
    ...options,
  })
}

export default usePostOutgoingCall
