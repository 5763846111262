import React from 'react'

import { MenuItem, MenuItemProps } from '@material-ui/core'
import { Skeleton, SkeletonProps } from '@material-ui/lab'

type MenuSkeletonProps = {
  amount?: number
  menuItemProps?: Omit<MenuItemProps, 'button'>
  skeletonProps?: SkeletonProps
}

const MenuSkeleton = React.forwardRef(({ amount = 4, menuItemProps, skeletonProps }: MenuSkeletonProps, ref) => {
  return (
    <>
      {Array.from({ length: amount }).map((_, index) => (
        <MenuItem key={index} disabled {...menuItemProps} innerRef={ref}>
          <Skeleton width={150} {...skeletonProps} />
        </MenuItem>
      ))}
    </>
  )
})

export default MenuSkeleton
