import { createTheme } from '@material-ui/core'

import colors from '../theme/colors'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1500,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },

    text: {
      primary: colors.dark,
    },

    grey: {
      900: colors.dark,
      800: colors.darkGrey,
      700: colors.darkLight,
      300: colors.lightGrey,
      200: colors.lightLighter,
      100: colors.background,
    },
  },

  typography: {
    fontFamily: 'Rubik, sans-serif',
    fontWeightBold: 500,
    subtitle1: {
      fontWeight: 500,
      color: colors.dark,
    },
    h5: {
      fontSize: '1.25rem',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        color: colors.dark,
        backgroundColor: colors.white,
        boxShadow: 'none',
        borderRadius: 6,
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
        borderColor: colors.lightLighter,
        boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
      },
    },
  },
})

export default theme
