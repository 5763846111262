import '../setupEnv'
import { useServerSentEvent } from '@dentalux/ui-library-core'

import { QueryKey, useQueryClient, UseQueryResult } from '@tanstack/react-query'

import { CalmasterAppointment } from '../@types/Appointment'
import { ServerSentResourceType } from '../@types/ServerSentEvent'

type UseAppointmentsSubscriptionProps = {
  query: UseQueryResult<CalmasterAppointment[], unknown>
  queryKey: QueryKey
  conditionalCheck?: (appointment: CalmasterAppointment) => boolean
}

type UpdaterProps = CalmasterAppointment[] | undefined

const useAppointmentsSubscription = ({ query, queryKey, conditionalCheck }: UseAppointmentsSubscriptionProps): void => {
  const client = useQueryClient()

  const handleUpdate = (appointment: CalmasterAppointment) => {
    if (!conditionalCheck?.(appointment)) return
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (!previousData) return
      return previousData.map((previousAppointment) =>
        previousAppointment.referenceId === appointment.referenceId ? appointment : previousAppointment
      )
    })
  }

  const handleCreate = (appointment: CalmasterAppointment) => {
    if (!conditionalCheck?.(appointment)) return
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (!previousData) return
      return [...previousData, appointment]
    })
  }

  const handleDelete = (appointment: CalmasterAppointment) => {
    if (!conditionalCheck?.(appointment)) return
    client.setQueryData(queryKey, (previousData: UpdaterProps) => {
      if (!previousData) return
      return previousData.filter(({ referenceId }) => referenceId === appointment.referenceId)
    })
  }

  // Temporary solution as SSE data is not the same as object in array
  const handleReload = () => {
    query.refetch()
  }

  useServerSentEvent(ServerSentResourceType.APPOINTMENT, 'CREATED', handleReload)
  useServerSentEvent(ServerSentResourceType.APPOINTMENT, 'UPDATED', handleReload)
  useServerSentEvent(ServerSentResourceType.APPOINTMENT, 'DELETED', handleReload)
  useServerSentEvent(ServerSentResourceType.PATIENT_CREDITWORTHINESS, 'UPDATED', handleReload)
}

export default useAppointmentsSubscription
