import { useCredentials } from '@dentalux/security'

import { useCurrentClinicProvider } from '../providers/CurrentClinicProvider'
import { viewTodoList } from '../services/featureFlags'

const useQueriesAuth = () => {
  const { selectedClinic } = useCurrentClinicProvider()
  const { authentication } = useCredentials()
  const skipTodoListQuery = !viewTodoList(selectedClinic, authentication)

  return {
    skipTodoListQuery,
  }
}

export default useQueriesAuth
