import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import '../../setupEnv'
import { CommunicationHistory } from '../../@types/CommunicationHistory'
import { CommunicationHistoryParams } from '../../@types/CommunicationHistoryParams'
import {
  Conversation,
  ConversationMessage,
  ConversationQueryOptions,
  CreateConversationRequest,
  NewConversationMessage,
} from '../../@types/Conversation'
import { PaginatedResponse } from '../../@types/Pagination'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_DISPACCI_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http)

/**
 * @param clinicReferenceIds is possible to be undefined because required params
 * cannot be after optional params
 */
export const getConversations = async (
  clinicReferenceIds?: string,
  options?: ConversationQueryOptions
): Promise<Conversation[]> => {
  const { data } = await http.get('/dispacci/api/conversations', {
    params: {
      ...(options ?? {}),
      clinicReferenceIds,
      hasMessagesCreatedByHuman: true,
    },
  })

  return data?.content || []
}

export const getConversation = async (referenceId?: string): Promise<Conversation> => {
  const { data } = await http.get(`/dispacci/api/conversations/${referenceId}`)

  return data
}

export const createConversation = async (newConversation: any) => {
  return {}
}

export const patchConversation = async (props: {
  referenceId: string
  hasBeenRead?: boolean
  isArchived?: boolean
}): Promise<Record<string, unknown>> => {
  const { data } = await http.patch(`/dispacci/api/conversations/${props.referenceId}`, {
    hasBeenRead: props?.hasBeenRead,
    isArchived: props?.isArchived,
  })

  return data
}

export const getMessages = async (
  clinicReferenceIds: string,
  conversationReferenceId: string
): Promise<ConversationMessage[]> => {
  const { data } = await http.get<PaginatedResponse<ConversationMessage>>('/dispacci/api/messages', {
    params: {
      clinicReferenceIds,
      conversationReferenceId,
    },
  })

  return data?.content || []
}

export const postMessage = async (
  message: NewConversationMessage | CreateConversationRequest
): Promise<Record<string, string | boolean>> => {
  const { data } = await http.post(`/dispacci/api/messages`, message)

  return data
}
