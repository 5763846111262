import moment, { Moment } from 'moment'

const dateFormat = 'YYYY-MM-DDTHH:mm:ss'

const intervals: Record<string, (currentDate: Moment) => { startAfter: string; startBefore: string }> = {
  week: (currentDate: Moment) => ({
    startAfter: moment(currentDate).startOf('isoWeek').format(dateFormat),
    startBefore: moment(currentDate).endOf('isoWeek').format(dateFormat),
  }),
  month: (currentDate: Moment) => ({
    startAfter: moment(currentDate).startOf('month').format(dateFormat),
    startBefore: moment(currentDate).endOf('month').format(dateFormat),
  }),
  day: (currentDate: Moment) => ({
    startAfter: moment(currentDate).startOf('day').format(dateFormat),
    startBefore: moment(currentDate).endOf('day').format(dateFormat),
  }),
}

const getAppointmentsInterval = (calendarViewType: string, currentDate: Moment) => {
  return intervals[calendarViewType] ? intervals[calendarViewType](currentDate) : intervals.day(currentDate)
}

export default getAppointmentsInterval
