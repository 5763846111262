export enum AnamnesisState {
  MISSING = 'MISSING',
  EXPIRED = 'EXPIRED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum AnamnesisStepName {
  ANAMNESIS = 'ANAMNESIS',
  DENTAL_TOS_ACCEPTED = 'DENTAL_TOS_ACCEPTED',
  COVID_QUESTIONNAIRE = 'COVID_QUESTIONNAIRE',
  FACTORING_AGREEMENT = 'FACTORING_AGREEMENT',
}

export type AnamnesisStep = {
  entityReferenceId?: string
  step: AnamnesisStepName
  status: AnamnesisState
  submitDate?: string
}

export enum AnamnesisActionState {
  GENERATE_CODE = 'GENERATE_CODE',
  VIEW_CODE = 'VIEW_CODE',
  NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
}

export enum AnamnesisCodeLength {
  SHORT = 'SHORT',
  LONG = 'LONG',
  SMS = 'SMS',
}

export enum AnamnesisCodeMedium {
  CLINIC = 'CLINIC',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum AnamnesisCodeReason {
  CLINIC_TRIGGERED = 'CLINIC_TRIGGERED',
  OTHER = 'OTHER',
}

export enum AnamnesisCodeCreatorApplication {
  CALMASTER_FRONTEND = 'CALMASTER_FRONTEND',
}

export enum CodeType {
  CLINIC = 'CLINIC',
  WEB = 'WEB',
  SMS = 'SMS',
}

export type Code = {
  code: string
  patientReferenceId: string
  type: CodeType
  validUntil?: string
}

export interface AnamnesisDetails {
  patientReferenceId: string
  stepsCompleted: AnamnesisStep[]
  activeCodes: Code[]
  latestAnamnesisReferenceId?: string
}

export interface PatientReference {
  clinicReferenceId: string
  externalId: string
  referenceId: string
}

export interface AnamnesisExtendedDetails {
  anamnesisPdfKey: string | null
  birthday: string
  created: Date
  patientReference: PatientReference
  personReferenceId: string
  referenceId: string
}

export const ANA_RELATED_STEPS = [AnamnesisStepName.ANAMNESIS, AnamnesisStepName.FACTORING_AGREEMENT]
