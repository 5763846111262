import { FunctionComponent } from 'react'

import { CalmasterAppointment } from './Appointment'

export enum AppointmentState {
  NOT_STATE = 'NO_STATE',
  IN_CLINIC = 'IN_CLINIC',
  WAITING_IN_CLINIC = 'WAITING_IN_CLINIC',
  WAITING_OUTSIDE_SMS = 'WAITING_OUTSIDE_SMS',
  WAITING_OUTSIDE_PAGER = 'WAITING_OUTSIDE_PAGER',
  NOTIFIED = 'NOTIFIED',
  // New states
  OPEN = 'OPEN',
  WAITING = 'WAITING',
  IN_TREATMENT_ROOM = 'IN_TREATMENT_ROOM',
  IN_TREATMENT = 'IN_TREATMENT',
  TREATED = 'TREATED',
  CANCELLED = 'CANCELLED',
  CONTROLLED = 'CONTROLLED',
}

export enum ControlSteps {
  ONE = 'Controlled',
  TWO = 'ControlledByAccounting',
}

export type Step = {
  position: number | undefined
  Icon: FunctionComponent
  label: string
  name: AppointmentState
  hasRoom?: boolean
}

export const isPatientInClinic = (status: AppointmentState) =>
  status === AppointmentState.WAITING ||
  status === AppointmentState.IN_TREATMENT_ROOM ||
  status === AppointmentState.IN_TREATMENT

export default AppointmentState

export type AppointmentStatusParams = Pick<
  CalmasterAppointment,
  | 'stateInClinicStart'
  | 'stateWaitingStart'
  | 'stateInTreatmentRoomStart'
  | 'stateTreatmentStart'
  | 'stateTreatmentEnd'
  | 'cancellationReason'
  | 'controlled'
  | 'controlledAccounting'
  | 'roomReferenceId'
> & {
  cancellationType?: string | null
}

export type AppointmentStatusFormData = Record<keyof AppointmentStatusParams, string | null>

type AppointmentStatusSelect = {
  time: string | null
  name: string
}

export type AppointmentStatusSelectForm = {
  checkedStatus?: AppointmentState | undefined
  statusSelect?: Partial<AppointmentStatusSelect>[] | undefined
  roomReferenceId?: string
  controlStepsChecked?: ControlSteps[] | undefined
  cancellationType?: string | null
  cancellationReason?: string | null
}
