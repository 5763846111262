import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  userImage: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  userButton: {
    borderRadius: 100,
    minWidth: 'unset',
    flexShrink: 0,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
  menu: {
    width: 230,
    boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
    color: theme.palette.grey[300],
    marginTop: 10,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    height: 42,
    color: '#616161',
  },
  menuItemActive: {
    height: 42,
    background: '#24A1A1',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      background: '#24A1A1',
      cursor: 'auto',
    },
  },
  okIcon: {
    width: 16,
    height: 16,
  },
  icon: {
    marginRight: 9,
  },
  translateIcon: {
    fontSize: 16,
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  userName: {
    color: theme.palette.common.black,
    fontSize: 14,
    lineHeight: '16px',
    textTransform: 'none',
    marginBottom: 4,
    width: 140,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  practitionerType: {
    color: '#6979F8',
    backgroundColor: '#E5E7FA',
    height: 20,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    paddingLeft: 9,
    paddingRight: 9,
    width: 'fit-content',
  },
  translationsMenuItem: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      cursor: 'auto',
    },
  },
  translationsMenuItemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  activeLanguageBtn: {},
  dropdownIcon: {
    marginRight: -4,
    fontSize: 16,
  },
  menuIcon: {
    width: 32,
    height: 32,
  },
}))
