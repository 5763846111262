import { useMemo } from 'react'

import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Conversation, ConversationQueryName } from '../@types/Conversation'
import { minutesToMs } from '../helpers/time'
import withQueryParams from '../helpers/withQueryParams'
import { useCurrentClinicProvider } from '../providers/CurrentClinicProvider'
import api from '../services/api'
import useConversationSubscription from '../subscriptions/useConversationsSubscription'

interface UseConversationsProps {
  disabled?: boolean
  isArchived?: boolean
}

export type ConversationsReturn = {
  conversations: UseQueryResult<Conversation[], AxiosError>
  queryKey: QueryKey
}

const useConversations = ({ disabled, isArchived = false }: UseConversationsProps = {}): ConversationsReturn => {
  const { selectedClinic } = useCurrentClinicProvider()
  const size = isArchived ? 10 : 100
  const defaultParams = useMemo(
    () => ({
      isArchived,
      hasMessagesCreatedByHuman: true,
    }),
    [isArchived]
  )

  const queryKey = useMemo(() => {
    const queryName = isArchived ? ConversationQueryName.archived : ConversationQueryName.active

    return [
      queryName,
      selectedClinic?.referenceId,
      {
        ...defaultParams,
        page: 0,
        size,
      },
    ]
  }, [defaultParams, size, isArchived, selectedClinic])

  const service = api.dispacci.getConversations

  const enabled = !!selectedClinic?.referenceId && !disabled

  const conversations = useQuery<Conversation[], AxiosError>({
    queryKey,
    queryFn: withQueryParams(service),
    enabled,
    refetchInterval: minutesToMs(30),
    keepPreviousData: enabled,
  })

  return { conversations, queryKey }
}

export default useConversations
