type Predicate<T> = (x: T) => boolean
type Transformation<T> = (x: T) => T
type MatchContext<T> = {
  on: (pred: Predicate<T>, fn: Transformation<T>) => MatchContext<T>
  otherwise: (fn: Transformation<T>) => T
}

const switched = <T>(x: T): MatchContext<T> => ({
  on: () => switched(x),
  otherwise: () => x,
})

const switchFn = <T>(x: T): MatchContext<T> => ({
  on: (pred, fn) => (pred(x) ? switched(fn(x)) : switchFn(x)),
  otherwise: (fn) => fn(x),
})

export default switchFn
