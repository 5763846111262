export enum TagColor {
  DEFAULT = 'default',
  GREEN = 'green',
  BLUE = 'blue',
  DARK_BLUE = 'dark-blue',
  YELLOW = 'yellow',
  RED = 'red',
  LIGHT_GREEN = 'light-green',
  DEEP_SPACE = 'deep-space',
}
