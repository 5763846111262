import React, { PropsWithChildren, useContext, useMemo } from 'react'

import { withQueryParams } from '@dentalux/common'

import { useQuery } from '@tanstack/react-query'

import { Room } from '../@types/Room'
import api from '../services/api'

import { useCurrentClinicProvider } from './CurrentClinicProvider'

type ContextProps = {
  rooms: Room[]
}

const initialState: ContextProps = {
  rooms: [],
}

export const Context = React.createContext<ContextProps>(initialState)

const RoomProvider = ({ children }: PropsWithChildren<unknown>) => {
  const service = api.calmaster.getRooms
  const { selectedClinic } = useCurrentClinicProvider()

  const clinicReferenceId = useMemo(() => selectedClinic?.referenceId || '', [selectedClinic])
  const queryParams = { clinicReferenceId }

  const query = useQuery({
    queryKey: ['rooms', queryParams],
    queryFn: withQueryParams(service),
    enabled: !!clinicReferenceId,
  })

  return <Context.Provider value={{ rooms: query.data || [] }}>{children}</Context.Provider>
}

export const useRoomContext = () => useContext(Context)

export default RoomProvider
