import React, { useContext } from 'react'

import { QueryObserverResult, useQuery } from '@tanstack/react-query'
import moment from 'moment'

import { PractitionerKpi } from '../@types/PractitionerKpi'
import getAppointmentsInterval from '../helpers/getAppointmentsInterval'
import { mockQueryLoadingResult } from '../helpers/reactQuery'
import withQueryParams from '../helpers/withQueryParams'
import api from '../services/api'

import { useAnalyticsToggleContext } from './AnalyticsToggleProvider'
import { useCalendarSettingsContext } from './CalendarSettingsProvider'
import { useEmployeesFiltersContext } from './EmployeesFiltersProvider'

const initialState = mockQueryLoadingResult([])

export const Context = React.createContext<QueryObserverResult<PractitionerKpi[], any>>(initialState)

const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const calendarSettings = useCalendarSettingsContext()
  const { showAnalytics } = useAnalyticsToggleContext()
  const { filteredSelectedEmployeeReferenceIds } = useEmployeesFiltersContext()
  const intervalFilters = getAppointmentsInterval(calendarSettings.calendarViewType, calendarSettings.currentDate)

  const canFetchAnalytics =
    showAnalytics && calendarSettings.calendarViewType === 'week' && filteredSelectedEmployeeReferenceIds.length > 0

  const analytics = useQuery<PractitionerKpi[]>({
    queryKey: [
      'analytics',
      {
        periodStart: intervalFilters.startAfter,
        periodEnd: moment(intervalFilters.startBefore).add(1, 'd').format(),
        practitionerReferenceIds: filteredSelectedEmployeeReferenceIds,
        timeGroup: 'DAY',
      },
    ],
    queryFn: withQueryParams(api.insight.getAnalytics),
    enabled: canFetchAnalytics,
  })

  return <Context.Provider value={analytics}>{children}</Context.Provider>
}

export const useAnalyticsContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within AnalyticsProvider')
  }

  return context
}

export default AnalyticsProvider
