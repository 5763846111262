import { FormControl } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const RedesignedFormControl = withStyles((theme) => ({
  root: {
    background: 'transparent',
    border: 'none',

    '& .MuiInputBase-root:after, & .MuiInputBase-root:before': {
      display: 'none',
    },

    '& .MuiInput-root': {
      border: 'none',
      background: 'transparent',
    },
    '& .MuiSelect-icon': {
      width: '16px',
      height: '16px',
      top: 'calc(50% - 8px)',
      right: '8px',
    },
  },
}))(FormControl)

export default RedesignedFormControl
