import React from 'react'

import { makeStyles } from '@material-ui/core'
import TabPanel from '@material-ui/lab/TabPanel'

import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    /**
     * We add only 4px on the right
     * as the other 12 will be added
     * from SideNav-scrollable
     * this way the scroll-bar should
     * appear in the space created
     * by the padding
     */
    padding: '0 4px 0 8px',
    height: '100%',
    '&$calls': {
      height: 'calc(100vh - 130px)',
    },
    '&$patients': {
      height: 'calc(100vh - 200px)',
    },
    '&$sms': {
      height: 'calc(100vh - 170px)',
    },
    '&$todos': {
      height: 'calc(100vh - 130px)',
      overflow: 'auto',
    },
  },
  calls: {},
  patients: {},
  sms: {},
  todos: {},
})

type Props = {
  variant?: 'calls' | 'patients' | 'sms' | 'todo-list'
  children?: React.ReactNode
  value: string
}

const StyledTabPanel = ({ variant, value, children }: Props) => {
  const classes = useStyles()

  return (
    <TabPanel
      value={value}
      classes={{
        root: clsx(
          classes.root,
          variant === 'calls' && classes.calls,
          variant === 'patients' && classes.patients,
          variant === 'sms' && classes.sms,
          variant === 'todo-list' && classes.todos
        ),
      }}
    >
      {children}
    </TabPanel>
  )
}

export default StyledTabPanel
