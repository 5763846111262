import React from 'react'

import { createSvgIcon } from '@material-ui/core'

export default createSvgIcon(
  <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99967 0.833496C3.31767 0.833496 0.333008 3.81816 0.333008 7.50016C0.333008 11.1822 3.31767 14.1668 6.99967 14.1668C10.6817 14.1668 13.6663 11.1822 13.6663 7.50016C13.6663 3.81816 10.6817 0.833496 6.99967 0.833496ZM5.66634 11.1095L2.52834 7.9715L3.47101 7.02883L5.66634 9.22416L10.5283 4.36216L11.471 5.30483L5.66634 11.1095Z"
      fill="#fff"
    />
  </svg>,
  'CircleOk'
)
