import * as ana from './ana'
import * as calmaster from './calmaster'
import * as core from './core'
import * as dispacci from './dispacci'
import * as insight from './insight'
import * as operator from './operator'
import * as optar from './optar'
import * as patient from './patient'
import * as patientCore from './patientCore'
import * as scorpio from './scorpio'
import * as shiftly from './shiftly'

const api = {
  ana,
  core,
  insight,
  calmaster,
  patient,
  shiftly,
  optar,
  operator,
  dispacci,
  patientCore,
  scorpio,
}

export default api
