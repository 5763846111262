export enum PractitionerType {
  DOCTOR = 'DOCTOR',
  EXPERT = 'EXPERT',
  ASSISTENT = 'ASSISTENT',
}

export enum Role {
  NONE = 'NONE',
  ASSISTANT = 'ASSISTANT',
  ACCOUNTANT = 'ACCOUNTANT',
  HYGIENIST = 'HYGIENIST',
  DENTIST = 'DENTIST',
  CLINIC_MANAGER = 'CLINIC_MANAGER',
  RECEPTIONIST = 'RECEPTIONIST',
  DENTAL_TECHNICIAN = 'DENTAL_TECHNICIAN',
  DOCTOR = 'DOCTOR',
}

export enum Level {
  CHIEF = 'CHIEF',
  SENIOR = 'SENIOR',
  JUNIOR = 'JUNIOR',
  GRADUATE = 'GRADUATE',
}

export enum EmployeeStatus {
  DISABLED = 'DISABLED',
  INACTIVE = 'INACTIVE',
  WILL_BE_ACTIVE = 'WILL_BE_ACTIVE',
  ACTIVE = 'ACTIVE',
  USED_TO_BE_ACTIVE = 'USED_TO_BE_ACTIVE',
  UNKNOWN = 'UNKNOWN',
}

export type Position = {
  role: Role
  level: Level
}

export interface CorePractitioner {
  referenceId: string
  name: string
  imageUrl?: string
  clinicReferenceId?: string
  practitionerType?: PractitionerType
}

export interface CalmasterPractitioner {
  referenceId: string
  userName: string
  firstName: string
  lastName: string
  name?: string
  email?: string
  practitionerPositions?: Position[]
  practitionerType?: PractitionerType
  imageUrl?: string
  clinicReferenceId?: string
  deleted?: boolean
  status?: EmployeeStatus
  __typename?: 'CalmasterPractitioner'
  threeLetterId?: string
}

export interface MainPractitioner {
  firstName: string
  lastName: string
  imageUrl: string
}
