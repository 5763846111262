import React, { PropsWithChildren, useMemo } from 'react'

import { useCredentials } from '@dentalux/security'

import * as featureFlagsService from '../services/featureFlags'

import { useCurrentClinicProvider } from './CurrentClinicProvider'

const initialState = {
  switchClinics: false,
  viewAppointments: false,
  viewBookings: false,
  viewWaitingRoom: false,
  viewPatientSearch: false,
  showNewCalendar: true,
  incomingCalls: false,
  anonymousEnabled: false,
  enableAnamnesisPractitionerView: false,
  enableAppointmentBundleCreation: false,
  enableAppMenu: false,
  hideCovidQuestionnaire: false,
  enableDetailsField: false,
  showAnalytics: false,
  enableTodoListTab: false,
  viewAnamnesis: false,
  viewHQComponent: false,
  hasHQStatus: false,
  isHQAdmin: false,
  canEditChemistryScore: false,
  enableSmartVoiceLink: false,
}

type State = typeof initialState

export const Context = React.createContext<State>(initialState)

const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const { authentication: user } = useCredentials()
  const { selectedClinic } = useCurrentClinicProvider()

  const value = useMemo(() => {
    if (user) {
      return {
        switchClinics: featureFlagsService.switchClinics(user),
        viewAppointments: featureFlagsService.viewAppointments(user),
        viewBookings: featureFlagsService.viewBookings(user),
        viewWaitingRoom: featureFlagsService.viewWaitingRoom(user),
        viewPatientSearch: featureFlagsService.viewPatientSearch(),
        showNewCalendar: featureFlagsService.showNewCalendar(),
        incomingCalls: featureFlagsService.incomingCalls(),
        hasHQStatus: featureFlagsService.hasHQStatus(user),
        isHQAdmin: featureFlagsService.isHQAdmin(user),
        canEditChemistryScore: featureFlagsService.canEditChemistryScore(user, selectedClinic?.referenceId),
        anonymousEnabled: featureFlagsService.anonymousEnabled(),
        enableAnamnesisPractitionerView: featureFlagsService.enableAnamnesisPractitionerView(),
        enableAppointmentBundleCreation: featureFlagsService.enableAppointmentBundleCreation(),
        enableAppMenu: featureFlagsService.enableAppMenu(),
        enableSmartVoiceLink: featureFlagsService.enableSmartVoiceLink(),
        hideCovidQuestionnaire: featureFlagsService.hideCovidQuestionnaire(),
        enableDetailsField: featureFlagsService.enableDetailsField(),
        showAnalytics: featureFlagsService.showAnalytics(user),
        enableTodoListTab: featureFlagsService.enableTodoListTab(),
        viewAnamnesis: featureFlagsService.viewAnamnesis(user),
        viewHQComponent: featureFlagsService.viewHQComponent(user),
      }
    } else {
      return initialState
    }
  }, [user, selectedClinic?.referenceId])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useFeatureFlags = () => {
  const context = React.useContext(Context)

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider')
  }

  return context
}

export default FeatureFlagsProvider
