import { useQuery } from '@tanstack/react-query'

import { Conversation } from '../@types/Conversation'
import withQueryParams from '../helpers/withQueryParams'
import { useCurrentClinicProvider } from '../providers/CurrentClinicProvider'
import { getConversations } from '../services/api/dispacci'

type UseConversationByPhoneReturn = {
  isFetching: boolean
  conversation: Conversation | undefined
}

const useConversationByPhone = (contactPhones?: string | null): UseConversationByPhoneReturn => {
  const { selectedClinic } = useCurrentClinicProvider()

  const clinicReferenceId = selectedClinic?.referenceId

  const queryKey: any = ['conversation', clinicReferenceId, { contactPhones }]

  const isEnabled = Boolean(clinicReferenceId)

  const { data, isFetching } = useQuery({
    queryKey,
    queryFn: withQueryParams(getConversations),
    enabled: isEnabled,
  })

  return {
    isFetching,
    conversation: data && data.length > 0 ? data[0] : undefined,
  }
}

export default useConversationByPhone
