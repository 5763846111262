import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import 'moment/locale/de'

import i18n from '../services/i18n'

type SupportedLanguage = 'en' | 'de'

type ContextType = {
  currentLanguage: SupportedLanguage
  changeLanguage: (language: SupportedLanguage) => void
}

const localeStorageKey = 'preferredLanguage'

const getInitialLanguage = (): SupportedLanguage => {
  const preferredLanguage = localStorage.getItem(localeStorageKey) as SupportedLanguage
  const browserLanguage = navigator.language?.substring(0, 2)

  if (preferredLanguage) return preferredLanguage

  if (browserLanguage === 'en' || browserLanguage === 'de') {
    return browserLanguage as SupportedLanguage
  }

  return 'de'
}

const initialState: ContextType = {
  currentLanguage: getInitialLanguage(),
  changeLanguage: () => {},
}

const changeLanguage = (language: SupportedLanguage) => {
  moment.locale(language)
  i18n.changeLanguage(language)
  localStorage.setItem(localeStorageKey, language)
}

const Context = React.createContext<ContextType>(initialState)

const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [currentLanguage, setCurrentLanguage] = useState<SupportedLanguage>(initialState.currentLanguage)

  useEffect(() => {
    changeLanguage(currentLanguage)
  }, [currentLanguage])

  const value = {
    currentLanguage,
    changeLanguage: setCurrentLanguage,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useLanguageContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useLanguageContext must be used within LanguageProvider')
  }

  return context
}

export default LanguageProvider
