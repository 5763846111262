import React, { PropsWithChildren, useContext, useState } from 'react'

import { AppointmentForm, AppointmentScheduleRef } from '@dentalux/ui-library-core'

interface ContextProps {
  showBitComponents: boolean
  setShowBitComponents: React.Dispatch<React.SetStateAction<boolean>>
  calendarRef: AppointmentScheduleRef | null
  setCalendarRef: React.Dispatch<React.SetStateAction<AppointmentScheduleRef | null>>
  patientDetailsInitialValues: null | Partial<AppointmentForm>
  setPatientDetailsInitialValues: React.Dispatch<React.SetStateAction<Partial<AppointmentForm> | null>>
}

interface Props {
  children: React.ReactNode
}

const Context = React.createContext<ContextProps>({
  showBitComponents: true,
  setShowBitComponents: () => {},
  calendarRef: null,
  setCalendarRef: () => {},
  patientDetailsInitialValues: {},
  setPatientDetailsInitialValues: () => {},
})

const BitComponentsProvider: React.FC<Props> = ({ children }: PropsWithChildren<Props>) => {
  const [showBitComponents, setShowBitComponents] = useState(window.env.REACT_APP_ENV !== 'production')
  const [calendarRef, setCalendarRef] = useState<AppointmentScheduleRef | null>(null)
  const [patientDetailsInitialValues, setPatientDetailsInitialValues] = useState<null | Partial<AppointmentForm>>(null)

  return (
    <Context.Provider
      value={{
        showBitComponents,
        setShowBitComponents,
        calendarRef,
        setCalendarRef,
        patientDetailsInitialValues,
        setPatientDetailsInitialValues,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useBitComponentsContext = () => useContext(Context)

export default BitComponentsProvider
