import { DATE_FORMATS, TIME_FORMATS } from '@dentalux/common'

import { startOfDay, format, endOfDay } from 'date-fns'

import { useUpcomingEmployeesFiltersContext } from '../providers/UpcomingEmployeesFiltersProvider'

import useAppointmentParams from './useAppointmentParams'

const useWaitingRoomAppointmentsParams = (date = new Date()) => {
  const { filteredSelectedEmployeeReferenceIds } = useUpcomingEmployeesFiltersContext()
  const startAfter = `${format(startOfDay(date), `${DATE_FORMATS.ISO_SHORT}`)}T${format(
    startOfDay(date),
    `${TIME_FORMATS.LONG}`
  )}`

  const endBefore = `${format(endOfDay(date), `${DATE_FORMATS.ISO_SHORT}`)}T${format(
    endOfDay(date),
    `${TIME_FORMATS.LONG}`
  )}`

  const params = useAppointmentParams({ startAfter, endBefore }, 100)

  return {
    ...params,
    practitionerReferenceIds: filteredSelectedEmployeeReferenceIds,
  }
}

export default useWaitingRoomAppointmentsParams
