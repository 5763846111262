import { colors } from '@dentalux/ui-library-core'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import { IconButton, Typography, styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`

export const Controls = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  margin-right: ${({ theme }) => theme.spacing(1)};
  &:before {
    width: 0;
  }
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  position: relative;

  & .MuiSvgIcon-root {
    font-size: 22;
    color: ${colors.grey[800]};
  }
`

export const StyledChevronLeft = styled(ChevronLeft)`
  font-size: 22;
  color: ${colors.grey[800]};
`

export const StyledChevronRight = styled(ChevronRight)`
  font-size: 22;
  color: ${colors.grey[800]};
`

export const SelectedDateTypography = styled(Typography)`
  text-transform: none;
  color: ${colors.grey[800]};
`
