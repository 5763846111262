import React, { useState } from 'react'

import { Typography } from '@material-ui/core'

import clsx from 'clsx'

import { PatientDetails } from '../../../../@types/PatientDetailsResult'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg'
import { ReactComponent as MaximizeIcon } from '../../../../assets/icons/maximize-icon.svg'
import { getPatientFullname } from '../../../../helpers/getPatientName'
import { useDidMount } from '../../../../hooks/useDidMount'

import useStyles from './MinimizedPatient.styles'

type Props = {
  patient: PatientDetails
  onMaximize: (patientReferenceId: string) => void
  onClose: (patientReferenceId: string) => void
}

const MinimizedPatient: React.FC<Props> = ({ patient, onMaximize, onClose }) => {
  const classes = useStyles()

  const [visible, setVisible] = useState<boolean>(false)

  useDidMount(() => {
    setVisible(true)
  })

  const patientName = getPatientFullname(patient)

  const handleMaximize = () => {
    setVisible(false)
    onMaximize(patient.referenceId)
  }

  const handleClose = () => {
    setVisible(false)
    onClose(patient.referenceId)
  }

  return (
    <div className={clsx(classes.root, { [classes.hide]: !visible })}>
      <div className={classes.details}>
        <Typography className={classes.name}>{patientName}</Typography>
      </div>
      <div className={classes.actions}>
        <span
          className={classes.action}
          onClick={handleMaximize}
          data-testid={`minimized_patient_maximize-${patient.referenceId}`}
        >
          <MaximizeIcon />
        </span>
        <span
          className={classes.action}
          onClick={handleClose}
          data-testid={`minimized_patient_close-${patient.referenceId}`}
        >
          <CloseIcon />
        </span>
      </div>
    </div>
  )
}

export default MinimizedPatient
