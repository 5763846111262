import { PaginatedResponse } from './Pagination'

export enum Category {
  APPOINTMENT_HANDLING = 'APPOINTMENT_HANDLING',
  BILLING = 'BILLING',
  CALL_COLLEAGUE = 'CALL_COLLEAGUE',
  CALL_PATIENT = 'CALL_PATIENT',
  COST_PLAN = 'COST_PLAN',
  DOCUMENTATION = 'DOCUMENTATION',
  FOLLOWUP_APPOINTMENT = 'FOLLOWUP_APPOINTMENT',
  INFORMATION = 'INFORMATION',
  INSURANCE = 'INSURANCE',
  MEETING = 'MEETING',
  OTHER = 'OTHER',
  PAIN_PATIENT = 'PAIN_PATIENT',
  PATIENTS_INFORMATION = 'PATIENTS_INFORMATION',
  POST_SENDOUT = 'POST_SENDOUT',
  PROBLEM_SOLVING = 'PROBLEM_SOLVING',
  RECALL = 'RECALL',
  REFER_PATIENT = 'REFER_PATIENT',
  SHIFT_PLANNING = 'SHIFT_PLANNING',
  TREATMENT_INFORMATION = 'TREATMENT_INFORMATION',
}

export enum Status {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  IGNORED = 'IGNORED',
}

export type Task = {
  referenceId?: string
  description: string
  category: Category | string
  createdByReferenceId: string | null
  employeeReferenceId: string
  patientReferenceId?: string
  important: boolean
  dueDateSwitch: 'on' | 'off'
  dueDate: string | Date
  status: Status | string
  latestComment?: string
  comment?: string
}

export type GetTaskFilter = Pick<Task, 'category' | 'important' | 'dueDate'> & {
  clinicReferenceId: string
  category?: Category | string
  important?: boolean
  dueDate?: string | Date
  assignedEmployees?: string[]
  createdByEmployees?: string[]
  statuses?: Status[]
}

export type PaginatedTasks = PaginatedResponse<Task>

export type TasksSize = {
  size: number
}

export type Comment = {
  referenceId: string
  text: string
  taskReferenceId: string
  createdByReferenceId: string | null
  createdAt: string
}

export type PostCommentQueryParams = {
  taskReferenceId?: string
  text: string
}

export type GetCommentsQueryParams = {
  taskReferenceId: string
  canFetchComments: boolean
}
