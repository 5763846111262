import { PractitionerType, CalmasterPractitioner } from '../@types/Practitioner'

const order: string[] = [PractitionerType.DOCTOR, PractitionerType.EXPERT, PractitionerType.ASSISTENT, '']

const sortPractitionersByType = (practitioners: CalmasterPractitioner[]) => {
  return [...practitioners].sort((practitionerA, practitionerB) => {
    const practitionerATypeIndex = order.indexOf(practitionerA.practitionerType || '')
    const practitionerBTypeIndex = order.indexOf(practitionerB.practitionerType || '')

    return practitionerATypeIndex - practitionerBTypeIndex
  })
}

export default sortPractitionersByType
