import React, { PropsWithChildren } from 'react'

import { ServerSentEventsProvider as DentaluxSSEProvider } from '@dentalux/common'
import { useCredentials } from '@dentalux/security'

import { useCurrentClinicProvider } from './CurrentClinicProvider'

const ServerSentEventsProvider = ({ children }: PropsWithChildren) => {
  const { token } = useCredentials()
  const { selectedClinic } = useCurrentClinicProvider()

  return (
    <DentaluxSSEProvider
      baseURL={window.env.REACT_APP_INTERNAL_SSE_ENDPOINT}
      clinicReferenceId={selectedClinic?.referenceId}
      token={token}
    >
      {children}
    </DentaluxSSEProvider>
  )
}

export default ServerSentEventsProvider
