import { ServerErrorTranslations } from '../../@types/ServerError'

export default {
  codes: {
    post: 'anamnesis_sms_send_failure',
  },
  'anamnesis-histories': {
    patch: 'anamnesis_step_invalidation_failure',
  },
  'factoring-agreements': {
    patch: 'anamnesis_step_invalidation_failure',
  },
  'legal-documents': {
    patch: 'anamnesis_step_invalidation_failure',
  },
  'covid-questionnaires': {
    patch: 'anamnesis_step_invalidation_failure',
  },
} as ServerErrorTranslations
