import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { MenuItem } from '@dentalux/ui-library-core'

import { Menu, Tooltip } from '@material-ui/core'
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ChevronUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { Button } from '@mui/material'

import { Clinic } from '../../../../@types/Clinic'
import { ParamTypes } from '../../../../@types/RouteParams'
import { ReactComponent as ClinicIcon } from '../../../../assets/icons/newclinic-icon.svg'
import { setLocalStorage } from '../../../../helpers/setLocalStorage'
import { useCalendarSettingsContext } from '../../../../providers/CalendarSettingsProvider'
import { useCurrentClinicProvider } from '../../../../providers/CurrentClinicProvider'
import MenuSkeleton from '../../../MenuSkeleton'

import useStyles from './ClinicDropdown.styles'

type Props = {
  onChange: (clinic: Clinic) => void
  value?: Clinic
  isPatientSearchEnabled?: boolean
}

const ClinicDropdown = ({ value, onChange, isPatientSearchEnabled }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const { view, date, sideNav } = useParams<ParamTypes>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedClinic, setSelectedClinic] = useState('')
  const { currentDate } = useCalendarSettingsContext()
  const { t } = useTranslation()
  const { clinics, queryDetails } = useCurrentClinicProvider()

  const isClinicsLoading = queryDetails?.isLoading

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (clinic: Clinic) => () => {
    setAnchorEl(null)
    history.push(
      `/calendar/${view ?? 'day'}/${date ?? currentDate.format('YYYY-MM-DD')}/${sideNav ?? 'patients'}/${
        clinic.referenceId
      }`
    )

    setLocalStorage('calendarParams', {
      view: view ?? 'day',
      date: date ?? currentDate.format('YYYY-MM-DD'),
      sideNav: sideNav || 'patients',
      clinicReferenceId: clinic.referenceId,
    })

    setSelectedClinic(clinic.name)
    onChange(clinic)
  }

  useEffect(() => {
    setSelectedClinic(value?.name || '')
  }, [value])

  const isOpen = Boolean(anchorEl)

  if (clinics.length <= 1) return null

  return (
    <>
      <Tooltip title={selectedClinic || ''}>
        <Button
          className={classes.button}
          onClick={handleClick}
          data-testid="clinicDropdown"
          startIcon={<ClinicIcon style={{ width: 14, height: 14, margin: 0 }} />}
          endIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          size="sm"
          variant="bordered"
        >
          {!isPatientSearchEnabled ? t('clinic') : ''}
        </Button>
      </Tooltip>

      <Menu classes={{ paper: classes.menuPaper }} anchorEl={anchorEl} keepMounted open={isOpen} onClose={handleClose}>
        {isClinicsLoading && <MenuSkeleton />}

        {clinics.map((clinic) => (
          <MenuItem
            selected={clinic.referenceId === value?.referenceId}
            onClick={handleChange(clinic)}
            key={clinic.referenceId}
            value={clinic.referenceId}
            data-testid={clinic.referenceId}
            size="s"
          >
            {clinic.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ClinicDropdown
