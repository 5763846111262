import React, { useContext } from 'react'

import { useCredentials } from '@dentalux/security'

import { User } from '../@types/Auth'
interface ContextProps {
  user: User
}

const Context = React.createContext<Partial<ContextProps>>({})

interface Props {
  children: React.ReactNode
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { authentication: user } = useCredentials()

  return <Context.Provider value={{ user }}>{children}</Context.Provider>
}

export const useAuthContext = () => useContext(Context)

export default AuthProvider
