import flatten from 'lodash/flatten'
import moment from 'moment'

import { PractitionerKpi, KpiItem } from '../@types/PractitionerKpi'

export const sum = (items: number[]) => items.reduce((sum, value) => sum + value, 0)

export const avg = (items: number[]) => sum(items) / items.length

export const getRevenue = (kpiItem: KpiItem) => kpiItem.revenue.value

export const getOccupancy = (kpiItem: KpiItem) => kpiItem.occupancy.value

const reduceKpisByDate = (
  date: string,
  getFn: (kpiValue: KpiItem) => number,
  reducer: (values: number[]) => number,
  practitionersKpis: PractitionerKpi[]
) => {
  const formattedDate = moment(date).format('YYYY-MM-DD')
  const kpis = flatten(practitionersKpis.map((practitionerKpi) => practitionerKpi.data))

  const kpisForRequestedDate = kpis.filter((kpiItem) => {
    const kpiDate = kpiItem.name.replace(/\./g, '-').split(' ')[0]

    return formattedDate === kpiDate
  })

  const kpisValues = kpisForRequestedDate.map((value) => getFn(value))

  return reducer(kpisValues)
}

const reduceKpiForAllDates = (
  getFn: (kpiValue: KpiItem) => number,
  reducer: (values: number[]) => number,
  practitionersKpis: PractitionerKpi[]
) => {
  const kpis = flatten(practitionersKpis.map((practitionerKpi) => practitionerKpi.data))
  const kpisValues = kpis.map((value) => getFn(value))

  return reducer(kpisValues)
}

export const sumKpisByDate = (
  date: string,
  getFn: (kpiValue: KpiItem) => number,
  practitionersKpis: PractitionerKpi[]
) => reduceKpisByDate(date, getFn, sum, practitionersKpis)

export const avgKpisByDate = (
  date: string,
  getFn: (kpiValue: KpiItem) => number,
  practitionersKpis: PractitionerKpi[]
) => reduceKpisByDate(date, getFn, avg, practitionersKpis)

export const sumKpiForAllDates = (getFn: (kpiValue: KpiItem) => number, practitionersKpis: PractitionerKpi[]) => {
  return reduceKpiForAllDates(getFn, sum, practitionersKpis)
}

export const avgKpiForAllDates = (getFn: (kpiValue: KpiItem) => number, practitionersKpis: PractitionerKpi[]) => {
  return reduceKpiForAllDates(getFn, avg, practitionersKpis)
}

export const sumRevenueByDate = (date: string, practitionersKpis: PractitionerKpi[]) => {
  return sumKpisByDate(date, getRevenue, practitionersKpis)
}

export const avgOccupancyByDate = (date: string, practitionersKpis: PractitionerKpi[]) => {
  return avgKpisByDate(date, getOccupancy, practitionersKpis)
}

export const sumRevenueForAllDates = (practitionersKpis: PractitionerKpi[]) => {
  return sumKpiForAllDates(getRevenue, practitionersKpis)
}

export const avgOccupancyForAllDates = (practitionersKpis: PractitionerKpi[]) => {
  return avgKpiForAllDates(getOccupancy, practitionersKpis)
}
