import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import { Patient, PatientPutRequestBody } from '../../@types/PatientCore'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_PATIENT_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http)

export type PatientInputEdit = {
  referenceId: string
  data: PatientPutRequestBody
}

export const putPatient = async ({ referenceId, ...body }: PatientInputEdit): Promise<Patient> => {
  const { data } = await http.put(`/api/core/patients/${referenceId}`, body.data)

  return data
}
