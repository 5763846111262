import React, { MouseEvent, useMemo, useState } from 'react'

import { CalendarProps } from 'react-calendar'
import { useTranslation } from 'react-i18next'

import { Language } from '@dentalux/common'
import { DatePicker } from '@dentalux/ui-library-core'

import { Box, Popover } from '@mui/material'
import Button from '@mui/material/Button'
import moment, { Moment, DurationInputArg2 } from 'moment'

import { useLanguageContext } from '../../../../providers/LanguageProvider'
import StyledTooltip from '../../../Styled/Tooltip'

import * as S from './CalendarControl.styles'

export type Props = {
  onNext: (date: Moment) => void
  onPrevious: (date: Moment) => void
  onToday: (date: Moment) => void
  onChange: (date: Moment) => void
  currentDate: Moment
  viewType: string
  calendarProps?: {
    onClickDay?: CalendarProps['onClickDay']
    onPreviousMonthClick?: () => void
    onNextMonthClick?: () => void
    activeStartDate?: Date
  }
}

export const CalendarControl = ({ onNext, onPrevious, onToday, currentDate, viewType, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const { currentLanguage } = useLanguageContext()

  const handlePrevious = () => {
    onPrevious(moment(currentDate).subtract(1, viewType as DurationInputArg2))
  }

  const handleNext = () => {
    onNext(moment(currentDate).add(1, viewType as DurationInputArg2))
  }

  const handleToday = () => {
    onToday(moment())
  }

  const handleOpenPicker = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePicker = () => setAnchorEl(null)

  const handlePickerAccept = (date: Date | null) => {
    if (date) {
      setAnchorEl(null)
      onChange(moment(date))
    }
  }

  const dateToShow = useMemo(() => {
    if (viewType === 'day') return moment(currentDate).format('D MMMM YYYY')

    const week = moment(currentDate).format('w')
    const startOfWeek = moment(currentDate).startOf('isoWeek').format('DD')
    const endOfWeek = moment(currentDate).startOf('isoWeek').add(4, 'days').format('DD')

    return `${t('week')} ${week}: ${startOfWeek} - ${endOfWeek} ${moment(currentDate).format('MMMM YYYY')}`
  }, [viewType, currentDate, t])

  const pickerValue = useMemo(() => currentDate.toDate(), [currentDate])
  // Beginning of 2017 year as min date
  const minPickerDate = useMemo(() => new Date(2017, 0, 1), [])
  const maxPickerDate = useMemo(() => moment(new Date()).add(3, 'years').toDate(), [])

  return (
    <S.Wrapper>
      <S.Controls>
        <StyledTooltip title={t(`previous_${viewType}`) || ''}>
          <S.StyledIconButton data-testid="previous" onClick={handlePrevious} color="transparent" size="md">
            <S.StyledChevronLeft />
          </S.StyledIconButton>
        </StyledTooltip>

        <StyledTooltip title={t(`next_${viewType}`) || ''}>
          <S.StyledIconButton data-testid="next" onClick={handleNext} color="transparent" size="md">
            <S.StyledChevronRight />
          </S.StyledIconButton>
        </StyledTooltip>

        <Box ml={2} mr={2}>
          <Button variant="borderless" size="sm" onClick={handleOpenPicker} data-testid="calendar_date_picker">
            <S.SelectedDateTypography variant="Lato Emphasized 1">{dateToShow}</S.SelectedDateTypography>
          </Button>
        </Box>
      </S.Controls>

      <StyledTooltip title={moment().format('dddd, D MMMM YYYY')}>
        <Button size="sm" data-testid="today" onClick={handleToday} variant="bordered">
          {t('today')}
        </Button>
      </StyledTooltip>

      {anchorEl && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <DatePicker
            isStatic
            open
            value={pickerValue}
            language={currentLanguage as Language}
            onClose={handleClosePicker}
            onAccept={handlePickerAccept}
            views={['year', 'month', 'day']}
            minDate={minPickerDate}
            maxDate={maxPickerDate}
            labels={{
              cancelButtonLabel: t('task_cancel'),
              okButtonLabel: t('task_save'),
            }}
          />
        </Popover>
      )}
    </S.Wrapper>
  )
}
