import React, { useContext, useState, PropsWithChildren } from 'react'

import { useLocalStorage } from '@dentalux/common'

export const Context = React.createContext({
  isObfuscated: false,
  toggleObfuscation: () => {},
})

export const ObfuscationProvider = ({ children }: PropsWithChildren) => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage('isObfuscated')
  const [isObfuscated, setIsObfuscated] = useState(!!localStorageValue)

  const toggleObfuscation = () =>
    setIsObfuscated((prevState) => {
      setLocalStorageValue(!prevState)
      return !prevState
    })

  return (
    <Context.Provider
      value={{
        isObfuscated,
        toggleObfuscation,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useObfuscationProvider = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useObfuscationProvider must be used within a ObfuscationProvider.')
  }

  return context
}
