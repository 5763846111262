import React from 'react'

import { useParams, useHistory } from 'react-router-dom'

import moment from 'moment'

import { ParamTypes } from '../../../../@types/RouteParams'
import { setLocalStorage } from '../../../../helpers/setLocalStorage'
import { useCalendarSettingsContext } from '../../../../providers/CalendarSettingsProvider'
import { useDrawerCalendarStateContext } from '../../../../providers/DrawerCalendarStateProvider'

import { Props, CalendarControl as CalendarControlStatic } from './CalendarControl'

const CalendarControl = (props: Props) => {
  const [currentActiveStartDate, setCurrentActiveStartDate] = useDrawerCalendarStateContext()
  const { setCurrentDate } = useCalendarSettingsContext()
  const history = useHistory()
  const { view, sideNav, clinicReferenceId } = useParams<ParamTypes>()

  const handleNextMonthClick = () => {
    setCurrentActiveStartDate(moment(currentActiveStartDate).add(1, 'month'))
  }

  const handlePreviousMonthClick = () => {
    setCurrentActiveStartDate(moment(currentActiveStartDate).subtract(1, 'month'))
  }

  const handleClickDay = (date: Date) => {
    setCurrentDate(moment(date))
    history.push(
      `/calendar/${view}/${moment(date).format('YYYY-MM-DD')}/${sideNav || 'patients'}/${clinicReferenceId ?? ''}`
    )

    setLocalStorage('calendarParams', {
      view,
      date: moment(date).format('YYYY-MM-DD'),
      sideNav: sideNav || 'patients',
      clinicReferenceId: clinicReferenceId ?? '',
    })
  }

  return (
    <CalendarControlStatic
      {...props}
      calendarProps={{
        onClickDay: handleClickDay,
        onPreviousMonthClick: handlePreviousMonthClick,
        onNextMonthClick: handleNextMonthClick,
        activeStartDate: currentActiveStartDate.toDate(),
      }}
    />
  )
}

export default CalendarControl
