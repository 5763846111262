import { colors } from '@dentalux/ui-library-core'

import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    width: 230,
    borderRadius: 6,
    boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
    color: colors.grey[300],
    marginTop: 10,
    overflow: 'hidden',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    height: 42,
    color: colors.grey[700],
  },
  icon: {
    color: theme.palette.common.white,
  },
}))
