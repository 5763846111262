import { QueryObserverSuccessResult, QueryObserverLoadingResult } from '@tanstack/react-query'

import { PaginatedResponse } from '../@types/Pagination'

export function mockQuerySuccessResult<T>(data: T): QueryObserverSuccessResult<T> {
  return {
    status: 'success',
    data: data,
    error: null,
    isFetching: false,
    failureCount: 0,
  } as QueryObserverSuccessResult<T>
}

export function mockQueryLoadingResult<T, E = Error>(initialData: T): QueryObserverLoadingResult<T, E> {
  return {
    status: 'loading',
    data: initialData,
    error: null,
    isFetching: true,
    failureCount: 0,
    isStale: false,
  } as unknown as QueryObserverLoadingResult<T, E>
}

export function mockPaginatedResult<T>(): PaginatedResponse<T> {
  return {
    content: [],
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      sort: {
        empty: false,
        sorted: false,
        unsorted: false,
      },
      unpaged: false,
    },
    size: 0,
    sort: {
      empty: false,
      sorted: false,
      unsorted: false,
    },
    totalElements: 0,
    totalPages: 0,
  }
}
