import React, { PropsWithChildren, useContext, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { GenderType, InsuranceType } from '@dentalux/common'
import { AppointmentForm, colors, PartialGenericCalendarEvent } from '@dentalux/ui-library-core'

import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, Snackbar } from '@mui/material'
import { add, formatISO } from 'date-fns'

import { MULTIPLE_ROLES_BUNDLE } from '../components/AppointmentModals/common/constants'

import { useBitComponentsContext } from './BitComponentsProvider'

interface ContextProps {
  details: AppointmentForm | null
  toggleDetails: (details?: AppointmentForm | null) => void
}

const Context = React.createContext<Partial<ContextProps>>({
  details: null,
  toggleDetails: () => {},
})

export const AppointmentUndoDialogProvider = ({ children }: PropsWithChildren) => {
  const [details, setDetails] = useState<AppointmentForm | null>(null)

  const { calendarRef } = useBitComponentsContext()
  const { t } = useTranslation()

  const toggleDetails = (formDetails?: AppointmentForm | null) => {
    // TODO: Update Patient type and add "referenceId" key
    const data = !!formDetails?.patient && !(formDetails?.patient as any)?.referenceId ? formDetails : null

    setDetails(data)
  }

  const handleUndoButtonClick = () => {
    toggleDetails()

    if (!details) return

    const updatedData = {
      ...details,
      ...(details?.startTime && {
        startTime: formatISO(details.startTime),
        ...(details?.duration && {
          endTime: formatISO(add(details.startTime, { minutes: Number(details.duration) })),
        }),
      }),
      ...(!details?.employeeReferenceId &&
        !details?.employeeByRole && {
          employeeByRole: MULTIPLE_ROLES_BUNDLE.reduce(
            (acc, role) => ({
              ...acc,
              [role]: [],
            }),
            {}
          ),
        }),
      ...(details?.patient && {
        patient: {
          ...(details?.patient ?? {}),
          birthDate: details?.patient?.dateOfBirth ? formatISO(details?.patient?.dateOfBirth) : '',
          firstName: details?.patient?.firstName ?? '',
          lastName: details?.patient?.lastName ?? '',
          insuranceType: details?.patient?.insuranceType ?? InsuranceType.PRIVATE_INSURANCE,
          email: details?.patient?.email ?? '',
          genderType: (details?.patient?.genderType as GenderType) ?? GenderType.MALE,
          phoneNumber: details?.patient?.phoneNumber ?? '',
        },
      }),
    } as PartialGenericCalendarEvent

    calendarRef?.toggleAppointmentDialog(updatedData, true)
  }

  const handleCloseSnackbar = () => toggleDetails()

  const value = useMemo(
    () => ({
      details,
      toggleDetails,
    }),
    [details]
  )

  return (
    <Context.Provider value={value}>
      {children}

      <Snackbar
        open={!!details}
        message={t('undo_message')}
        action={
          <>
            <Button
              variant="borderless"
              onClick={handleUndoButtonClick}
              sx={{
                color: colors.aqua[75],
              }}
            >
              {t('undo')}
            </Button>
            <IconButton onClick={handleCloseSnackbar}>
              <CloseIcon
                sx={{
                  color: colors.grey[50],
                }}
              />
            </IconButton>
          </>
        }
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      />
    </Context.Provider>
  )
}

export const useAppointmentUndoDialogContext = () => useContext(Context)
