import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

import { ChemistryRating, PatientRating } from '../../@types/Chemistry'
import errors from '../../i18n/errors/ana'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_SCORPIO_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http, errors)

interface GetRatingParams {
  patientReferenceId: string
}

interface PostRatingParams {
  patientReferenceId: string
  practitionerReferenceId: string
  rating: ChemistryRating
  reason: string
}

export const getPatientRating = async ({ patientReferenceId }: GetRatingParams): Promise<PatientRating> => {
  const { data } = await http.get(`/api/v1/ratings/patients/${patientReferenceId}/latest`)

  return data
}

export const postPatientRating = async (body: PostRatingParams): Promise<PatientRating> => {
  const { data } = await http.post('/api/v1/ratings/patients', body)

  return data
}
