import { colors, Icon } from '@dentalux/ui-library-core'

import { makeStyles, Theme } from '@material-ui/core'

import { IconButton, styled } from '@mui/material'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: colors.aqua[800],
    color: theme.palette.common.white,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1200,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '25px',
    fontWeight: 500,
    fontFamily: 'Lato',
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  inputWrapper: {
    maxWidth: '720px',
    width: '100%',
    margin: '0 8px',
  },
}))

export const HamburgerIconButton = styled(IconButton)`
  && {
    margin-right: 16px;
  }
`

export const HamburgerIconWrapper = styled(Icon)`
  && {
    width: 32px;
    height: 32px;
  }
`
