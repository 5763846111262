import { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import deepEqual from 'deep-equal'
import { useDebounce } from 'use-debounce/lib'

import '../setupEnv'

import getAppointmentsInterval from '../helpers/getAppointmentsInterval'
import { useCalendarSettingsContext as useCalendarSettings } from '../providers/CalendarSettingsProvider'
import { useCurrentClinicProvider as useCurrentClinic } from '../providers/CurrentClinicProvider'

type UseAppointmentParamsOptions = {
  startAfter?: string
  startBefore?: string
  endBefore?: string
  patientReferenceIds?: string[]
  practitioners?: string[]
}

type AppointmentsParamsResult = {
  startAfter?: string
  startBefore?: string
  endBefore?: string
  clinicReferenceIds?: string[]
  practitionerReferenceIds?: string[]
  patientReferenceIds?: string[]
}

const defaultOptions: UseAppointmentParamsOptions = { practitioners: [] }

const useAppointmentParams = (
  options: UseAppointmentParamsOptions = defaultOptions,
  debounce = 500
): AppointmentsParamsResult => {
  const { startAfter, startBefore, practitioners = [], endBefore, patientReferenceIds } = options
  const { view } = useParams<{ view: string }>()

  const calendarSettings = useCalendarSettings()
  const { selectedClinic } = useCurrentClinic()

  const clinicReferenceIds = useMemo(
    () => (selectedClinic?.referenceId && !patientReferenceIds ? [selectedClinic?.referenceId] : []),
    [patientReferenceIds, selectedClinic?.referenceId]
  )

  const intervalFilters = useMemo(
    () =>
      options.startAfter && options.startBefore
        ? {
            startAfter,
            startBefore,
          }
        : options.endBefore
        ? {
            startAfter, // set to end of the year
            endBefore,
          }
        : getAppointmentsInterval(view ?? calendarSettings.calendarViewType, calendarSettings.currentDate),
    [
      calendarSettings.calendarViewType,
      calendarSettings.currentDate,
      endBefore,
      options.endBefore,
      options.startAfter,
      options.startBefore,
      startAfter,
      startBefore,
      view,
    ]
  )

  const appointmentsParams: AppointmentsParamsResult = useMemo(
    () => ({
      clinicReferenceIds,
      practitionerReferenceIds: practitioners,
      ...intervalFilters,
    }),
    [clinicReferenceIds, practitioners, intervalFilters]
  )

  const appointmentsParamsWithPatient: AppointmentsParamsResult = useMemo(
    () => ({
      patientReferenceIds,
      ...intervalFilters,
    }),
    [intervalFilters, patientReferenceIds]
  )

  const [appointmentsParamsDebounced] = useDebounce(appointmentsParams, debounce, { equalityFn: deepEqual })
  const [appointmentsParamsWithPatientDebounced] = useDebounce(appointmentsParamsWithPatient, debounce, {
    equalityFn: deepEqual,
  })

  return patientReferenceIds?.length ? appointmentsParamsWithPatientDebounced : appointmentsParamsDebounced
}

export default useAppointmentParams
