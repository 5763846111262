import React, { createContext, useContext, useState } from 'react'

type ContextProps = {
  showCancelledAppointments: boolean
  setShowCancelledAppointments: (value: boolean) => void
}

type ProviderProps = {
  children: React.ReactNode
}

export const CancelledAppointmentsToggleContext = createContext<ContextProps>({
  showCancelledAppointments: false,
  setShowCancelledAppointments: () => false,
})

export const useCancelledAppointmentsToggle = () => useContext(CancelledAppointmentsToggleContext)

const CancelledAppointmentsToggleProvider: React.FC<ProviderProps> = ({ children }) => {
  const [showCancelledAppointments, setShowCancelledAppointments] = useState<boolean>(false)

  return (
    <CancelledAppointmentsToggleContext.Provider value={{ showCancelledAppointments, setShowCancelledAppointments }}>
      {children}
    </CancelledAppointmentsToggleContext.Provider>
  )
}

export default CancelledAppointmentsToggleProvider
