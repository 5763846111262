import { addAuthInterceptors } from '@dentalux/security'

import axios, { AxiosResponse } from 'axios'

import '../../setupEnv'
import { PageableFilter } from '../../@types/Pagination'
import {
  Comment,
  GetCommentsQueryParams,
  GetTaskFilter,
  PaginatedTasks,
  PostCommentQueryParams,
  Task,
  TasksSize,
} from '../../@types/TodoListTask'
import parseArrayQueryParam from '../../helpers/parseArrayQueryParam'
import { addErrorInterceptors } from '../interceptors'

export const http = axios.create({
  baseURL: window.env.REACT_APP_TODO_LIST_ENDPOINT,
  withCredentials: true,
})

addAuthInterceptors(http)
addErrorInterceptors(http)

export type CreateTaskInputParams = {
  clinicReferenceId: string
  employeeReferenceId: string
  patientReferenceId?: string
  description: string
  category: string
  important: boolean
  dueDate?: string | Date
  dueDateSwitch: string
}

export type UpdateTaskInputParams = Partial<CreateTaskInputParams> & {
  referenceId: string
}

export const createTask = async ({
  clinicReferenceId,
  employeeReferenceId,
  patientReferenceId,
  description,
  category,
  important,
  dueDate,
}: CreateTaskInputParams): Promise<unknown> => {
  const { data } = await http.post('/api/tasks', {
    clinicReferenceId,
    employeeReferenceId,
    description,
    category,
    important,
    dueDate,
    ...(patientReferenceId && { patientReferenceId }),
  })

  return data
}

export const getComments = async (params: GetCommentsQueryParams): Promise<Array<Comment>> => {
  const { data } = await http.get<Array<Comment>>('/api/comments', {
    params,
  })

  return data
}

export const updateTask = async (id: string, task: Partial<Task>): Promise<Task> => {
  const { data } = await http.put<Partial<Task>, AxiosResponse<Task>>(`/api/tasks/${id}`, task)

  return data
}

export const createComment = async ({ taskReferenceId, text }: PostCommentQueryParams): Promise<Comment> => {
  const { data } = await http.post<Partial<PostCommentQueryParams>, AxiosResponse<Comment>>('/api/comments/', {
    taskReferenceId,
    text,
  })

  return data
}

type ListTasksParams = GetTaskFilter & PageableFilter

export const listTasks = async (params: ListTasksParams): Promise<PaginatedTasks> => {
  const { data } = await http.get('/api/tasks/', {
    params: {
      ...params,
      assignedEmployees: parseArrayQueryParam(params.assignedEmployees),
      statuses: parseArrayQueryParam(params.statuses),
    },
  })

  return data
}

export const listTasksSize = async (params: ListTasksParams): Promise<TasksSize> => {
  const { data } = await http.get('/api/tasks/size', {
    params: {
      ...params,
      statuses: parseArrayQueryParam(params.statuses),
    },
  })

  return data
}
